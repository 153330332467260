import AppTable from '@wieldy/components/AppTable';
import {Button, Empty, Form, Modal, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import {
  useDeleteCandidate,
  useUpdateCandidate,
} from 'app/services/candidateService';
import {useUpdateSubmission} from 'app/services/submissionService';
import {FormatTableFormValue} from 'app/utils/helpers/FormatTableFormValue';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import ChangeCandidateActions from '../ApproveRejectActions/ChangeCandidateActions';
import ApproveRejectActions from './../ApproveRejectActions';
import {useGetColumns} from './columns';

const CandidatesTable = ({
  candidates,
  status,
  isShowNoteButton,
  handleShowEdit,
}) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [initialState, setInitialState] = useState([]);
  const [candidateId, setCandidateId] = useState(null);
  const isOneCandidate = candidates.length === 1;

  useEffect(() => {
    setInitialState(
      candidates.map((el) => ({
        ...el,
        editMode: false,
      })),
    );
  }, [candidates]);

  const {mutate: onDeleteCandidate} = useDeleteCandidate('submission', id);
  const {mutate} = useUpdateSubmission(id);
  const {mutate: mutateCandidate} = useUpdateCandidate('submission', id);

  const {isClient, isSupplier} = useAccountType();
  const {isResponded, isSent} = useStatus(status);
  const isShowButtons = isResponded || isSent;
  const showBulkAction = isClient ? isResponded : isShowButtons;
  const isShowRespondBtn = candidates.some(
    (el) => el.status_key === 'proposed',
  );
  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    status,
  );

  const handleAdd = useCallback(() => {
    navigate(`/submissions/edit-submission/${id}/${status}`);
  }, [id, navigate, status]);

  const handleReject = useCallback(
    (status, reason) => {
      const key_reason = `${status}_reason`;
      mutate({
        status,
        [key_reason]: reason,
      });
    },
    [mutate],
  );

  const handleRespond = (values) => {
    const candidates = FormatTableFormValue(values, true);
    const payload = {
      candidates,
      status: 'responded',
    };
    mutate(payload, {onSuccess: () => setIsEditMode(false)});
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handlePopup = useCallback((bool) => {
    setIsOpenModal(bool);
  }, []);

  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const handleChangeCandidateStatus = useCallback((id) => {
    setIsChangeStatus(true);
    setCandidateId(id);
  }, []);

  const handleWithdrawCandidate = useCallback(
    (id, status_key) => {
      const isClosed = initialState
        .filter((el) => el.id !== id)
        .every((el) => el.status_key === 'not_engaged');
      const reasonKey = isClient ? 'declined_reason' : 'closed_reason';

      const isInterested = status_key === 'interested';

      const status = isClosed ? (isClient ? 'declined' : 'closed') : undefined;
      const reason = isClosed
        ? isSupplier
          ? 'No Longer Needed'
          : 'Not Interested'
        : undefined;
      isOneCandidate
        ? setIsOpenModal(true)
        : Modal.confirm({
            title: isClient
              ? isInterested
                ? 'Update to Not Interested?'
                : 'Update to Interested?'
              : 'Withdraw this candidate?',
            onOk: () => {
              const payload = {
                status,
                [reasonKey]: reason,
                candidates: [
                  {
                    id,
                    status: isClient
                      ? isInterested
                        ? 'not_interested'
                        : 'interested'
                      : 'not_engaged',
                  },
                ],
              };
              mutate(payload, {
                onSuccess: () => {
                  if (!isOneCandidate && isSupplier) onDeleteCandidate(id);
                },
              });
            },
            okText: isClient ? 'Update' : 'Withdraw',
          });
    },
    [
      initialState,
      isClient,
      isOneCandidate,
      isSupplier,
      mutate,
      onDeleteCandidate,
    ],
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const handleOnEditMode = useCallback((e) => {
    e.preventDefault();
    setIsEditMode(true);
  }, []);

  const columns = useGetColumns(
    isEditMode,
    initialState,
    setInitialState,
    showBulkAction,
    handleWithdrawCandidate,
    handleChangeCandidateStatus,
    isSent,
    isOneCandidate,
  );

  const [form] = Form.useForm();
  const refInitValue = useRef(initialState);

  useEffect(() => {
    if (!isEmpty(refInitValue.current)) {
      form.setFieldsValue(refInitValue.current);
    }
  }, [form]);

  const handleChangeStatus = (value) => {
    const payload = {
      id: candidateId,
      candidate: {
        status: value,
      },
    };

    setIsChangeStatus(false);
    mutateCandidate(payload);
    setCandidateId(null);
  };

  return (
    <Form form={form} onFinish={handleRespond} className='relative'>
      <Typography.Title level={5} className={'mb-5'}>
        Candidates
      </Typography.Title>
      <AppTable
        columns={columns}
        dataSource={candidates}
        pagination={false}
        emptyText={<Empty description='No Candidates' />}
      />
      <ChangeCandidateActions
        actionLabel={'Status'}
        isOpenModal={isChangeStatus}
        handleClose={() => setIsChangeStatus(false)}
        handleSuccess={handleChangeStatus}
        reasonFor={'status'}
        actionType={'status'}
      />
      <ApproveRejectActions
        form={form}
        onReject={handleReject}
        status={status}
        handleAdd={handleAdd}
        isReviewMode={isEditMode}
        handleEditMode={handleOnEditMode}
        isVisibleModal={isOpenModal}
        handleVisibleModal={handlePopup}
        isShowRespondButton={isShowRespondBtn}
      />
      {isShowNoteButton && isSupplier && !isClosed && (
        <Button
          type='primary'
          ghost
          className='note-button absolute bottom-0 right-0'
          onClick={handleShowEdit}>
          Add Note to Client
        </Button>
      )}
    </Form>
  );
};

CandidatesTable.propTypes = {
  candidates: PropTypes.array,
  status: PropTypes.string,
  isShowNoteButton: PropTypes.bool,
  handleShowEdit: PropTypes.func,
};
export default CandidatesTable;
