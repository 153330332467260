export default {
  upon_receipt: 'Upon Receipt',
  net_7: 'Net 7',
  net_10: 'Net 10',
  net_15: 'Net 15',
  net_30: 'Net 30',
  net_45: 'Net 45',
  net_60: 'Net 60',
  net_90: 'Net 90',
  net_120: 'Net 120',
};
