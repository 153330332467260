import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PageQueryProvider from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/resource/FilterResources';
import React, {forwardRef} from 'react';

import ChooseContainer from './ChooseContainer';
import SelectedList from './SelectedList';

const ChooseCandidates = forwardRef((props, ref) => {
  const {currentStep} = useStep();

  return (
    <React.Fragment>
      {currentStep < 2 && (
        <Typography.Paragraph>Select candidates</Typography.Paragraph>
      )}

      {currentStep > 2 && <SelectedList ref={ref} />}

      {currentStep === 2 && (
        <PageQueryProvider
          initialSimpleQueries={{
            include: ['total_count', 'skill_tags'],
            expand: ['supplier'],
            available_only: true,
            limit: 8,
          }}
          filterConfig={filterConfig}>
          <ChooseContainer />
        </PageQueryProvider>
      )}
    </React.Fragment>
  );
});

export default ChooseCandidates;
