import AppCard from '@wieldy/components/AppCard';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useAccountType} from 'app/hooks/useAccountType.js';
import candidateModal from 'app/modals/CandidateModal';
import {useGetCandidates} from 'app/services/candidateService.js';
import {getColumns} from 'app/shared/components/Tables/Candidates/columns';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const Candidates = ({defaultQueryParams, isShow, handleSetTabView}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('alpha');
  const {isClient} = useAccountType();

  const {isLoading, data} = useGetCandidates({
    limit: pageSize,
    include: [...defaultQueryParams.include, 'skill_tags'],
    page: currentPage - 1,
    ...defaultQueryParams,
  });
  const {candidates, total_count} = data || {candidates: [], total_count: 0};
  const mappedCandidates = candidates.map((cand) => new candidateModal(cand));

  useEffect(() => {
    if (!isLoading && handleSetTabView) {
      handleSetTabView('isCandidates', !!candidates.length);
    }
  }, [candidates, isLoading, handleSetTabView]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  if (!isShow) {
    return <></>;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          Candidates <AppTag className={'ml-1'}>{total_count}</AppTag>
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        <AppHeaderPagination
          total={total_count}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          hideStartBorder
          sortByOptions={[
            {label: 'Alphabetically', value: 'alpha'},
            {label: 'Created Date', value: 'date'},
            {label: 'Created By', value: 'owner'},
          ]}
          sortBy={sortBy}
          onSortByChange={setSortBy}
        />
      }>
      <AppTable
        loading={isLoading}
        columns={getColumns(isClient)}
        dataSource={mappedCandidates}
        rowClassName={(record) => {
          if (record.rowClassNames) {
            if (Array.isArray(record.rowClassNames)) {
              return record.rowClassNames.join(' ');
            }
            return record.rowClassNames;
          }
        }}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          total_count,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

Candidates.propTypes = {
  defaultQueryParams: PropTypes.object,
  handleSetTabView: PropTypes.func,
  isShow: PropTypes.bool.isRequired,
};

Candidates.defaultProps = {
  defaultQueryParams: {},
  isShow: false,
};

export default Candidates;
