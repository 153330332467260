export const primaryTabs = [{key: 'about-details', title: 'About'}];

export const viewSupplierQuery = {
  include: [
    'active_clients',
    'completed_engagements',
    'available_resources',
    'inquiries',
    'submissions',
    'engagements',
    'relation_status',
    'bookmarked',
  ],
  expand: ['company_representative', 'account_manager'],
};
export const TABS = {
  RESOURCES: 'resources',
  ENGAGEMENTS: 'engagements',
  INQUIRIES: 'inquiries',
  SUBMISSIONS: 'submissions',
};

export const getQueryParams = (tab, supplier) => {
  switch (tab) {
    case TABS.INQUIRIES:
    case TABS.SUBMISSIONS:
      return {
        supplier,
        expand: ['supplier', 'client', 'requisition'],
        include: ['candidates', 'total_count'],
        sort: ['-created_at'],
      };
    case TABS.ENGAGEMENTS:
      return {
        supplier,
        expand: ['resource'],
        include: ['total_count'],
        sort: ['-start_date'],
      };
    case TABS.RESOURCES:
      return {
        supplier,
        include: ['total_count'],
        sort: ['+available_on'],
      };
  }
};

export const getPrimaryTabs = (
  isResource,
  isInquiry,
  isSubmission,
  isEngagement,
  isNotes,
) => {
  const tabs = [...primaryTabs];
  isResource && tabs.push({key: 'resources', title: 'Resources'});
  isInquiry && tabs.push({key: 'inquiries', title: 'Inquiries'});
  isSubmission && tabs.push({key: 'submissions', title: 'Submissions'});
  isEngagement && tabs.push({key: 'engagements', title: 'Engagements'});
  isNotes && tabs.push({key: 'notes', title: 'Notes'});
  return tabs;
};
