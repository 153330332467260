import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Button} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse';
import {useGetExpenses} from 'app/services/expenseService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment/moment';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {getColumns} from './columns';

const Expenses = () => {
  const {id} = useParams();
  const {isSupplier} = useAccountType();
  const {pageSize, currentPage, onChangePage, onShowSizeChange} = useBrowse({
    initPageSize: 5,
  });
  const navigate = useNavigate();

  const {isLoading, data} = useGetExpenses({
    engagement: id,
    statuses: ['submitted', 'approved', 'rejected'],
    include: ['total_count', 'invoice_author', 'total_amount'],
    expand: ['invoice', 'approved_by', 'submitted_by'],
  });

  const [notSortedExpenses, totalCount] = getItemsData('expenses', data);
  const expenses = notSortedExpenses.sort(
    (a, b) => moment(b.end_date) - moment(a.end_date),
  );

  const onExpenseView = (expense_id) => {
    navigate(`/expenses/${expense_id}/view-expense`);
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppCard
      title='Expenses'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      extra={
        isSupplier && (
          <Button
            className={'text-uppercase'}
            type={'primary'}
            size={'small'}
            onClick={() => navigate('/expenses/add-expense')}>
            Add new expense
          </Button>
        )
      }>
      <AppTable
        className={'column-valign-top'}
        columns={getColumns(onExpenseView)}
        dataSource={expenses}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
        )}
      />
    </AppCard>
  );
};

export default Expenses;
