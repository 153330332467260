import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, DatePicker, Form, Select} from 'antd';
import {WORK_TYPES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import useStep from 'app/hooks/useStep';
import {CompanyUserDropdown} from 'app/shared/components/dropdowns';
import {setEngagementDetails} from 'app/store/features/engagement/engagementSlice';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const RangePicker = DatePicker.RangePicker;
const EngagementDetailsForm = () => {
  const {nextStep} = useStep();
  const dispatch = useDispatch();
  const {isMarketplace} = useAccountType();
  const {isMarketManager, isAdmin} = useRole();
  const isShowButton = isMarketplace && (isMarketManager || isAdmin);

  const {details} = useSelector(({engagement}) => engagement);
  const {user} = useSelector(({user}) => user);

  const [manager, setManager] = React.useState({
    ...details.hiring_manager,
    name: `${details.hiring_manager?.first_name} ${details.hiring_manager?.last_name}`,
  });
  const [accountManager, setAccountManager] = React.useState(
    details.account_manager
      ? {
          ...details.account_manager,
          name: `${details.account_manager?.first_name} ${details.account_manager?.last_name}`,
        }
      : {...user, name: `${user?.first_name} ${user?.last_name}`},
  );
  const [resourceManager, setResourceManager] = React.useState(
    details.resource_manager,
  );

  const handleChangeManager = React.useCallback((_, option) => {
    setManager(option);
  }, []);

  const handleChangeAccountManager = React.useCallback((_, option) => {
    setAccountManager(option);
  }, []);

  const handleChangeHiringManager = React.useCallback((_, option) => {
    setResourceManager(option);
  }, []);

  const initialValues = useMemo(
    () => ({
      type: details.type,
      start_end_date: [moment(details.start_date), moment(details.end_date)],
      hiring_manager: details.hiring_manager?.id,
      account_manager: details.account_manager?.id || user.id,
      resource_manager:
        details.resource_manager?.id || details.resource_manager,
    }),
    [
      details.type,
      details.start_date,
      details.end_date,
      details.hiring_manager?.id,
      details.account_manager?.id,
      details.resource_manager,
      user.id,
    ],
  );

  const handleOnSubmit = (formData) => {
    const {start_end_date, ...rest} = formData;

    const payload = {
      engagement_supplier_id: details.engagement_supplier_id,
      start_date: start_end_date[0],
      end_date: start_end_date[1],
      ...rest,
      hiring_manager: manager,
      account_manager: accountManager,
      resource_manager: resourceManager,
    };
    dispatch(setEngagementDetails(payload));

    if (nextStep) {
      nextStep();
    }
  };

  const getValueSupplier = (users) => {
    if (typeof details.resource_manager === 'string') {
      setResourceManager(
        users.find((item) => item.id === details.resource_manager),
      );
    }
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          initialValues={initialValues}>
          <AppFormBody>
            {isShowButton && (
              <>
                <Form.Item
                  name='hiring_manager'
                  label='Hiring Manager'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Hiring Manager'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    placeholder='Search for hiring manager'
                    variant='client'
                    role='hiring_manager'
                    manager={manager}
                    onChange={handleChangeManager}
                  />
                </Form.Item>

                <Form.Item
                  name='resource_manager'
                  label='Supplier Contact'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Supplier Contact'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    // initialFetch
                    placeholder='Search for supplier contact'
                    variant='supplier_all'
                    role='client_marketplace'
                    getValue={getValueSupplier}
                    filterMembers={(item) => item?.role !== 'resource'}
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    manager={resourceManager}
                    onChange={handleChangeHiringManager}
                  />
                </Form.Item>

                <Form.Item
                  name='account_manager'
                  label='Account Manager'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Account Manager'),
                    },
                  ]}>
                  <CompanyUserDropdown
                    placeholder='Search for account manager'
                    variant='marketplaceAll'
                    manager={accountManager}
                    role={'account_manager'}
                    // initialFetch
                    // getValue={getValueAccount}
                    style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}
                    onChange={handleChangeAccountManager}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label={'Start Date - End Date'}
              name={'start_end_date'}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Start & end date'),
                },
              ]}>
              <RangePicker
                format='MM-DD-YYYY'
                disabledDate={(current) =>
                  current && current < moment().startOf('day')
                }
              />
            </Form.Item>
            <Form.Item
              name='type'
              label='Type'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Type'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('Type')}
                style={{minWidth: '320px', width: 'auto', maxWidth: '100%'}}>
                {Object.entries(WORK_TYPES).map(([key, value]) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </AppSelect>
            </Form.Item>
          </AppFormBody>
          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

EngagementDetailsForm.propTypes = {
  engagement: PropTypes.object,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default EngagementDetailsForm;
