import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetEngagementRates} from 'app/services/engagementsService';
import {useGetRequisition} from 'app/services/requisitionService';
import ClientCard from 'app/shared/client/ClientCard';
import Notes from 'app/shared/components/Notes/Notes';
import {RequisitionCard} from 'app/shared/requisitions';
import {SupplierCard} from 'app/shared/supplier';
import {
  setEngagementCandidate,
  setEngagementDetails,
  setEngagementId,
  setEngagementJobDetails,
  setEngagementRequisition,
} from 'app/store/features/engagement/engagementSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import EngagementRates from './EngagementRates';
import EngagementTasks from './EngagementTasks';
import Expenses from './Expenses';
import SpendingSummary from './SpendingSummary';
import Summary from './Summary';
import JobDetails from './Summary/JobDetails';
import Resource from './Summary/Resource';
import TimeCards from './TimeCards';

const MainContent = ({engagement, notes, initData}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const {id} = useParams();
  const {isLoading, data} = useGetRequisition(engagement.requisition.id, {
    include: [
      'requisition_common_inquiries',
      'requisition_common_submissions',
      'requisition_common_engagements',
      'skill_tags',
    ],
    expand: ['hiring_manager'],
    ...(isClient && {target_supplier: engagement.supplier.id}),
  });
  const requisition = useMemo(() => new RequisitionModal(data), [data]);
  const {isShowNotes, response, total: notesTotal} = notes;

  const {isLoading: ratesLoading, data: ratesData} = useGetEngagementRates({
    engagement: id,
    include: ['total_count'],
    expand: ['created_by', 'updated_by'],
  });
  const [rates, totalRatesCount] = getItemsData('engagement_rates', ratesData);

  const handleEdit = () => {
    const {requisition, resource} = initData;
    dispatch(setEngagementId(id));
    dispatch(
      setEngagementRequisition({
        id: requisition.id,
        job_title: requisition.job_title,
      }),
    );
    dispatch(
      setEngagementCandidate({
        name: getFullName(resource),
        id: resource.id,
      }),
    );
    dispatch(
      setEngagementDetails({
        engagement_supplier_id: engagement.supplier.id,
        start_date: initData.start_date
          ? moment(initData.start_date)
          : undefined,
        end_date: initData.end_date ? moment(initData.end_date) : undefined,
        type: initData.type,
        hiring_manager: initData.hiring_manager,
        account_manager: initData.account_manager,
        resource_manager: engagement.resource_manager,
      }),
    );
    dispatch(
      setEngagementJobDetails({
        job_worksite: initData.job_worksite,
        job_worksite_location: initData.job_worksite_location,
        job_status: initData.job_status,
        job_travel_requirements: initData.job_travel_requirements,
        job_expenses_eligible: initData.job_expenses_eligible,
        job_flsa_eligible: initData.job_flsa_eligible,
        job_overtime_eligible: initData.job_overtime_eligible,
        job_overtime_multiple: initData.job_overtime_multiple,
        job_payment_term: initData.job_payment_term,
        tax_engagement: initData.tax_engagement,
        tax_labor: initData.tax_labor,
        tax_expenses: initData.tax_expenses,
        tax_country: initData.tax_country,
        tax_state: initData.tax_state,
        tax_city: initData.tax_city,
      }),
    );
    navigate('/engagements/manage-engagement');
  };

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col xs={24}>
            <AppCard
              title='Summary'
              headStyle={{borderBottom: 0}}
              bodyStyle={{paddingTop: 8}}>
              <Summary engagement={engagement} />
              <JobDetails engagement={engagement} />
              <Resource resource={engagement.resource} />
              {isMarketplace && isClient && (
                <Button
                  className='mt-2'
                  shape='round'
                  onClick={handleEdit}
                  type='primary'>
                  Manage Engagement
                </Button>
              )}
            </AppCard>
          </Col>
          <Col xs={24} id='rates'>
            <EngagementRates
              engagementStatus={engagement?.status}
              isLoading={ratesLoading}
              rates={rates}
              totalCount={totalRatesCount}
            />
          </Col>
          <Col xs={24} id='tasks'>
            <EngagementTasks
              rates={rates}
              engagementStatus={engagement?.status}
            />
          </Col>
          <Col xs={24} id='time-cards'>
            <TimeCards />
          </Col>
          <Col xs={24} id='expenses'>
            <Expenses />
          </Col>
          <Col xs={24} id='spending-summary'>
            <SpendingSummary engagement={engagement} />
          </Col>
          {isShowNotes && (
            <Col sm={24} id='notes'>
              <Notes
                entity={'engagement_notes'}
                primaryKey={'engagement'}
                primaryId={engagement.id}
                notesResponse={response}
                notesTotal={notesTotal}
              />
            </Col>
          )}
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          <Col xs={24} sm={24} md={12} lg={24} id='client'>
            {isSupplier ? (
              <ClientCard client={engagement.client} />
            ) : (
              <SupplierCard supplier={engagement.supplier} />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} id='requisition'>
            <RequisitionCard isLoading={isLoading} requisition={requisition} />
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  engagement: PropTypes.object,
  initData: PropTypes.object,
  notes: PropTypes.object,
};

export default MainContent;
