export const APP_CURRENCY = 'USD';

export const SUBMISSION_STATUS = {
  engaged: {name: 'Engaged'},
  received: {name: 'Received'},
  review: {name: 'In Review'},
  open_sent: 'Open - Sent',
  open_review: 'Open - In Review',
  open_received: 'Open - Received',
  open_responded: 'Open - Responded',
  close_enaged: 'Closed - Engaged',
  close_withdrawn: 'Closed - Withdrawn',
};

export const YSE_NO_CONSTANTS = {
  yes: 'Yes',
  no: 'No',
};

export const FLSA_CONSTANTS = {
  exempt: 'Exempt',
  'non-exempt': 'Non-Exempt',
};

export const INVOICE_STATUS = {
  submitted_to_client: 'Submitted to Client',
  paid_by_client: 'Paid by Client',
  paid_to_supplier: 'Paid to Supplier',
  prepared: 'Prepared',
  disputed: 'Disputed',
  void: 'Void',
};

export const REQUISITION_STATUS = {
  open_accept: 'Open - Accepting Candidates',
  open_hold: 'Open - On Hold',
  close_expire: 'Closed - Expired',
  close_fill: 'Closed - Filled',
};

export const INQUIRY_STATUS = {
  sent: 'Sent',
  in_review: 'In Review',
  responded: 'Responded',
  closed: 'Closed',
  withdrawn: 'Withdrawn',
  engaged: 'Engaged',
  declined: 'Declined',
};
