import {useAccountType} from 'app/hooks/useAccountType';
import CandidateNotes from 'app/shared/resource/CandidateNotes';
import About from 'app/shared/resource/Summary/About';
import BillingRates from 'app/shared/resource/Summary/BillingRates';
import Overview from 'app/shared/resource/Summary/Overview';
import WorkEnvironment from 'app/shared/resource/Summary/WorkEnvironment';
import PropTypes from 'prop-types';
import React from 'react';

const TabsContent = ({candidate}) => {
  const {isSupplier} = useAccountType();
  return (
    <React.Fragment>
      <Overview resource={candidate} />
      <About resource={candidate} />
      <BillingRates resource={candidate} />
      <WorkEnvironment resource={candidate} />
      <CandidateNotes candidate={candidate} enabled={!isSupplier} />
    </React.Fragment>
  );
};

TabsContent.propTypes = {
  candidate: PropTypes.object,
};

export default TabsContent;
