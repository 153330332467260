import {Button} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {acceptTerms} from 'app/store/features/user/userThunks';
import moment from 'moment';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATE_FORMATS} from '../../../../config/app';
import s from './../styles/terms.module.less';

const Accept = () => {
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(false);
  const {isSupplier} = useAccountType();

  const {user, companyModal, currentAccount, accountType} = useSelector(
    ({user}) => user,
  );
  const onClick = () => {
    if (currentAccount[accountType]) {
      dispatch(
        acceptTerms({
          type: accountType,
          id: currentAccount[accountType].id,
        }),
      );
    }
  };
  return (
    <div id='accept' className={s.accept}>
      <div className={s.accept__container}>
        <Button type='button' onClick={onClick} disabled={!accept}>
          Accept
        </Button>
        <label htmlFor='acceptance'>
          <input
            className='mr-2'
            type='checkbox'
            id='acceptance'
            name='acceptance'
            checked={accept}
            onChange={(e) => setAccept(e.target.checked)}
          />
          I, {user.first_name} {user.last_name}, {user.title}, on behalf of{' '}
          {companyModal.name}, acknowledge that I have reviewed, consent and
          agree, to the <Link to='/'>Sourcer Terms of Service</Link>, including
          without limitation the{' '}
          {isSupplier ? (
            <Link to='/supplier-agreement'>Supplier Agreement</Link>
          ) : (
            <Link to='/customer-agreement'>Customer Agreement</Link>
          )}{' '}
          and{' '}
          <Link to='/standard-engagement-terms'>Standard Engagement Terms</Link>
          , effective as of {moment().format(DATE_FORMATS.full)}.
        </label>
      </div>
    </div>
  );
};

export default Accept;
