import AppTags from '@wieldy/components/AppTags';
import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const CompensationCandidateCriteria = ({requisition}) => {
  return (
    <div className='mb-5'>
      <Descriptions
        title='Compensation'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-5'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Maximum Billing Rate'>
          {requisition?.billingRateMax}
        </Descriptions.Item>
        <Descriptions.Item label='Billing Rate Period'>
          {requisition.billingRatePeriod}
        </Descriptions.Item>
        <Descriptions.Item label='Expenses Eligible'>
          {requisition.expensesEligible}
        </Descriptions.Item>
        <Descriptions.Item label='FLSA Eligible' span={1}>
          {requisition.flsaEligible}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        title='Candidate Criteria'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-5'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Location(s)'>
          <AppTags items={requisition.candidateCountries} />
        </Descriptions.Item>
        <Descriptions.Item label='Visa Status'>
          <AppTags items={requisition.candidateVisaStatus} />
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Experience'>
          {requisition.candidateMinimumExperience}
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Education' span={1}>
          {requisition.candidateMinEducation}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Based In' span={1}>
          <AppTags items={requisition.supplierCountries} />
        </Descriptions.Item>
      </Descriptions>
      {!!requisition?.candidateRequiredChecks?.length && (
        <Typography.Title level={4} className='mb-5'>
          Required Background Checks
        </Typography.Title>
      )}
      {requisition.candidateRequiredChecks.map((background) => (
        <Typography.Paragraph key={background}>
          {background}
        </Typography.Paragraph>
      ))}
    </div>
  );
};

CompensationCandidateCriteria.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(CompensationCandidateCriteria);
