import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCardCarousel from '@wieldy/components/AppCardCarousel';
import AppCarouselTpl from '@wieldy/components/AppCardCarousel/AppCarouselTpl';
import {Badge, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {
  RiExchangeDollarLine,
  RiMapPinLine,
  RiProfileLine,
} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

const CandidateProfile = ({candidate}) => {
  const navigate = useNavigate();
  return (
    <AppCarouselTpl
      profileProps={{
        isCompany: false,
        useInitialsOnEmptyAvatar: true,
        initials: candidate?.initials,
        avatarUrl:
          candidate?.avatar ||
          candidate?.user?.avatar_url ||
          candidate?._orignalProps?.user?.avatarUrl ||
          candidate?.resource?.avatar_url,
        titleProps: {
          onClick: () =>
            navigate(`/candidates/${candidate?.id}/view-candidate`),
        },
        title: candidate?.name,
        items: [
          {
            icon: <RiProfileLine className={'text-color'} fontSize={18} />,
            content: candidate?.title,
          },
          {
            icon: <RiMapPinLine className={'text-color'} fontSize={18} />,
            content: candidate?.location,
          },
        ],
      }}
      items={[
        {
          icon: <Badge status='success' style={{height: 8, width: 8}} />,
          title: 'AVAILABLE ON',
          content: candidate?.available_on,
        },
        {
          icon: <RiExchangeDollarLine fontSize={16} />,
          title: 'BILLING RATE',
          content: candidate?.billingRateLabel,
        },
      ]}
    />
  );
};
CandidateProfile.propTypes = {
  candidate: PropTypes.object,
};

const CandidatesCarousel = ({candidates}) => {
  return (
    <>
      <Typography.Title level={5} className={'title mb-3'} type={'secondary'}>
        {`Candidates (${candidates.length})`}
      </Typography.Title>

      {candidates.length > 1 ? (
        <AppCardCarousel
          renderRow={(candidate) => <CandidateProfile candidate={candidate} />}
          data={candidates}
        />
      ) : (
        <AppBorderBox className='mb-8'>
          <CandidateProfile candidate={candidates[0]} />
        </AppBorderBox>
      )}
    </>
  );
};

CandidatesCarousel.propTypes = {
  candidates: PropTypes.array,
};

export default CandidatesCarousel;
