import axios from '../config/axios';

const path = '/invoices';
export const invoiceAPI = {
  getInvoices: async (params) => await axios.get(path, {params}),
  getInvoice: async (id, params) => await axios.get(`${path}/${id}`, {params}),
  postInvoice: async (params) => await axios.post(path, params),
  updateInvoice: async (id, params) =>
    await axios.patch(`${path}/${id}`, params),
  deleteInvoice: async (id, params = {}) =>
    await axios.delete(`${path}/${id}`, params),
  sentInvoice: async (id, payload) =>
    await axios.post(`${path}/${id}/send`, payload),
  downloadInvoice: async (id, headers, params) =>
    await axios.get(`${path}/${id}/download`, {
      headers,
      responseType: 'blob',
      params,
    }),
};
