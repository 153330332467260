export const ALL_USER_TYPES = {
  owner: 'Owner',
  admin: 'Administrator',
  manager: 'Account Manager',
  hiring_manager: 'Hiring Manager',
  resource_manager: 'Resource Manager',
  resource: 'Resource',
};

export const CLIENT_USER_TYPES = {
  owner: 'Owner',
  admin: 'Administrator',
  hiring_manager: 'Hiring Manager',
};

export const SUPPLIER_USER_TYPES = {
  owner: 'Owner',
  admin: 'Administrator',
  resource_manager: 'Resource Manager',
  resource: 'Resource',
};

export const MANAGER_USER_TYPES = {
  admin: 'Administrator',
  manager: 'Account Manager',
};
