import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {notification} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useCreateInviteUser as useClientCreateInviteUser} from 'app/services/clientService';
import {useCreateSupplierInviteUser} from 'app/services/supplierService';
import {InviteUserForm} from 'app/shared/company';
import ClientHelper from 'app/utils/ClientHelper';
import SupplierHelper from 'app/utils/SupplierHelper';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const InviteNewUser = () => {
  const {isSupplier} = useAccountType();
  const navigate = useNavigate();
  const mutation = (
    isSupplier ? useCreateSupplierInviteUser : useClientCreateInviteUser
  )();

  const handleOnSubmit = (formData) => {
    mutation.mutate(formData, {
      onSuccess: () => {
        navigate(-1);
      },
      onError: (error) => {
        notification.error({message: error?.message});
      },
    });
  };

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          title='Invite New User'
          category='Administration / Users'
          backHeader
        />
      }>
      <InviteUserForm
        onSubmit={handleOnSubmit}
        onCancel={() => navigate(-1)}
        userTypes={(isSupplier ? SupplierHelper : ClientHelper)
          .getUserTypes()
          .filter((item) => item.value !== 'owner')}
        loading={mutation.isLoading}
        role={isSupplier ? 'supplier' : 'client'}
      />
    </AppPageContainer>
  );
};

export default InviteNewUser;
