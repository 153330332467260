import './index.style.less';

import {Alert, Button, Col, Form, Typography} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {RiCloseLine} from 'react-icons/ri';

import AppCard from '../AppCard';
import AppGridContainer from '../AppGridContainer';
import AppSlideDown from '../AppSlideDown';

const AppFilterWrapper = ({
  title,
  children,
  onApply,
  onReset,
  open,
  onClose,
  initialValues = {},
  ...rest
}) => {
  const [error, setError] = React.useState();
  const [form] = Form.useForm();
  const [isEnable, setIsEnable] = useState(false);

  React.useEffect(() => {
    form.setFieldsValue(initialValues);
    setIsEnable(Object.values(initialValues).some((filter) => !!filter));
  }, [form, initialValues]);

  const onResetForm = () => {
    if (onReset) {
      onReset();
    }
    const fields = form.getFieldsValue();
    if (typeof fields === 'object') {
      const resetFields = {};
      for (let key in fields) {
        resetFields[key] = undefined;
      }
      form.setFieldsValue(resetFields);
    }
  };

  const onApplyFilter = (formData) => {
    const hasFilled = Object.values(formData).some(
      (value) => value !== undefined,
    );
    if (!hasFilled) {
      setError('Please select one or more field(s) to apply filter');
      return;
    }
    if (onApply && !error) {
      onApply(formData);
    }
  };

  const onValueChanged = () => {
    const value = form.getFieldsValue();
    const clearValue = Object.fromEntries(
      Object.entries(value).map(([key, value]) =>
        Array.isArray(value) && !value.length ? [key, undefined] : [key, value],
      ),
    );
    const isValue = Object.values(clearValue).some((filter) => !!filter);
    if (isValue) {
      setIsEnable(true);
    } else {
      setIsEnable(false);
    }
    form.setFieldsValue(clearValue);
    setError(null);
  };

  return (
    <AppSlideDown open={open}>
      <AppCard
        {...rest}
        className={clsx('app-filter-wrapper')}
        title={
          <Typography.Title level={4} className='mb-0'>
            {title}
          </Typography.Title>
        }
        extra={
          <Button
            type={'text'}
            shape='circle'
            size={'small'}
            icon={<RiCloseLine style={{fontSize: '24px'}} />}
            onClick={onClose}
            style={{marginRight: '-12px'}}
          />
        }>
        <Form
          form={form}
          onFinish={onApplyFilter}
          onValuesChange={onValueChanged}
          layout='vertical'>
          <AppGridContainer gutter={30}>
            {children}
            <Col xs={24} hidden={!error} className={'mt-2 mb-2'}>
              <Alert type='error' message={error} />
            </Col>
            <Col xs={24}>
              <div className='filter-actions'>
                <Button
                  ghost
                  type='primary'
                  disabled={!isEnable}
                  onClick={onResetForm}>
                  Reset
                </Button>
                <Button type='primary' disabled={!isEnable} htmlType='submit'>
                  Apply
                </Button>
              </div>
            </Col>
          </AppGridContainer>
        </Form>
      </AppCard>
    </AppSlideDown>
  );
};

AppFilterWrapper.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  initialValues: PropTypes.object,
};

export default AppFilterWrapper;
