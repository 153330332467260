import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {WORK_TYPES} from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {useAccountType} from '../../../../../../hooks/useAccountType';
import {useRole} from '../../../../../../hooks/useRole';

const EngagementDetailsView = () => {
  const {details} = useSelector(({engagement}) => engagement);
  const {isMarketplace} = useAccountType();
  const {isMarketManager, isAdmin} = useRole();
  const isShowButton = isMarketplace && (isMarketManager || isAdmin);

  return (
    <AppCard style={{maxWidth: 620}}>
      {isShowButton && (
        <>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Hiring Manager</Typography.Title>
            <Typography.Paragraph>
              {details.hiring_manager?.first_name +
                ' ' +
                details.hiring_manager?.last_name}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Supplier Contact</Typography.Title>
            <Typography.Paragraph>
              {details.resource_manager &&
                details.resource_manager?.name &&
                details.resource_manager.name}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Account Manager</Typography.Title>
            <Typography.Paragraph>
              {details.account_manager?.first_name +
                ' ' +
                details.account_manager?.last_name}
            </Typography.Paragraph>
          </div>
        </>
      )}
      <div className={'mb-5'}>
        <Typography.Title level={4}>Start Date</Typography.Title>
        <Typography.Paragraph>
          {details?.start_date && details.start_date.format('MM/DD/YYYY')}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>End Date</Typography.Title>
        <Typography.Paragraph>
          {details?.end_date && details.end_date.format('MM/DD/YYYY')}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Type</Typography.Title>
        <Typography.Paragraph>{WORK_TYPES[details?.type]}</Typography.Paragraph>
      </div>
    </AppCard>
  );
};

EngagementDetailsView.propTypes = {
  details: PropTypes.object,
};

export default EngagementDetailsView;
