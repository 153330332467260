import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {ClientCard} from 'app/shared/client';
import Notes from 'app/shared/components/Notes/Notes';
import RequisitionCard from 'app/shared/requisitions/RequisitionCard';
import {SupplierCard} from 'app/shared/supplier';
import PropTypes from 'prop-types';
import React from 'react';

import EditNote from '../../CreateSubmission/AccompanyingMessage/EditNote';
import CandidatesTable from './CandidateTable';
import Overview from './Overview';

const MainContent = ({
  submission,
  requisition,
  isShowNotes,
  notesTotal,
  notesResponse,
  handleEditNote,
  handleShowEdit,
  isShowNote,
}) => {
  const {isSupplier} = useAccountType();

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col sm={24}>
            <AppCard>
              <Overview submission={submission} />
              <EditNote
                isShowForm={isShowNote}
                status={submission.status_key}
                handleEditNote={handleEditNote}
                handleCancel={handleShowEdit}
                message={submission?.accompanying_message}
                title={isSupplier ? 'Note to Client' : 'Note from Supplier'}
              />
              <CandidatesTable
                handleShowEdit={handleShowEdit}
                isShowNoteButton={!submission?.accompanying_message}
                handleEdit={handleEditNote}
                candidates={submission?.candidates}
                status={submission.status_key}
              />
            </AppCard>
          </Col>
          {isShowNotes && (
            <Col sm={24} id='notes'>
              <Notes
                entity={'submission_notes'}
                primaryKey={'submission'}
                primaryId={submission.id}
                notesResponse={notesResponse}
                notesTotal={notesTotal}
              />
            </Col>
          )}
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          <Col xs={24} sm={24} md={12} lg={24} id='client'>
            {isSupplier ? (
              <ClientCard client={submission.client} />
            ) : (
              <SupplierCard supplier={submission.supplier} />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} id='requisition'>
            <RequisitionCard requisition={requisition} />
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  submission: PropTypes.object,
  requisition: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
  handleEditNote: PropTypes.func,
  handleShowEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  isShowNote: PropTypes.bool,
};

export default MainContent;
