import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import UserInfo from '../../../layouts/MainLayout/AppHeader/UserInfo';
import Accept from './components/Accept';
import TermsContent from './components/TermsContent';
import TermsSidebar from './components/TermsSidebar';
import s from './styles/terms.module.less';

const termsPaths = [
  'customer-agreement',
  'supplier-agreement',
  'standard-engagement-terms',
  'terms-of-use',
  'privacy-policy',
  'global-data-processing-agreement',
  'privacy-center',
  'cookie-policy',
];

const AppTerms = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const includesPath = termsPaths.some((path) => pathname.includes(path));
    if (!includesPath) {
      navigate('/');
    }
  }, [navigate, pathname]);
  return (
    <div className={s.wrapper}>
      <header className={s.header}>
        <div className={s.header__container}>
          <div>
            <a href='/'>
              <img
                className='brand-dark'
                src='/assets/images/brand/dark/logo.png'
                width={130}
                alt='Sourcer'
              />
            </a>
          </div>
          <UserInfo />
        </div>
      </header>
      <div>
        <section className={s.title}>
          <div className={s.title__container}>
            <h1 className={s.title__text}>Terms of Service</h1>
          </div>
        </section>
        <section className={s.module}>
          <div className={s.container}>
            <div className={s.content}>
              <div className={s.content__menu}>
                <TermsSidebar />
              </div>
              <div className={s.content__value}>
                <TermsContent />
              </div>
            </div>
          </div>
        </section>
        <Accept />
      </div>
    </div>
  );
};

export default AppTerms;
