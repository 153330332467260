import {Typography} from 'antd';
import ManagerColumn from 'app/pages/manager/dashboard/MainContent/manager-column';
import React from 'react';

export const getColumns = ({
  onProfileView,
  mutate,
  loading,
  isShowManagerColumn,
}) => {
  const array = [
    {
      title: 'Client ID',
      dataIndex: 'hid',
      key: 'hid',
      render: (hid, item) => (
        <Typography.Link onClick={() => onProfileView(item.id)}>
          {hid}
        </Typography.Link>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 400,
    },
    {
      title: 'Open Requisitions',
      dataIndex: 'open_requisitions',
      key: 'open_requisitions',
    },
    {
      title: 'Active Engagements',
      dataIndex: 'active_engagements',
      key: 'active_engagements',
    },
  ];
  const manager = {
    title: 'Account Manager',
    dataIndex: 'account_manager',
    key: 'account_manager',
    render: (_, {account_manager, id}) => (
      <ManagerColumn
        account_manager={account_manager}
        id={id}
        mutate={mutate}
        loading={loading}
        keyId='client'
      />
    ),
  };
  return isShowManagerColumn ? [...array, manager] : array;
};
