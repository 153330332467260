import {configureStore} from '@reduxjs/toolkit';

import CommonReducer from './features/common/commonSlice';
import EngagementTablesReducer from './features/engagement/engaementTablesSlice';
import EngagementReducer from './features/engagement/engagementSlice';
import ExpensesReducer from './features/expenses/expensesSlice';
import InquiryReducer from './features/inquiry/inquirySlice';
import InvoiceReducer from './features/invoice/invoiceSlice';
import ResourceReducer from './features/resource/resourceSlice';
import SubmissionReducer from './features/submission/inquirySlice';
import TimecardsReducer from './features/timecards/timecardsSlice';
import UserReducer from './features/user/userSlice';

export const store = configureStore({
  reducer: {
    user: UserReducer,
    timecards: TimecardsReducer,
    expenses: ExpensesReducer,
    resource: ResourceReducer,
    inquiry: InquiryReducer,
    invoice: InvoiceReducer,
    submission: SubmissionReducer,
    common: CommonReducer,
    engagement: EngagementReducer,
    engagementTables: EngagementTablesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
