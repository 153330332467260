import {
  EDUCATIONS,
  LANGUAGE_LEVELS,
  LANGUAGES,
  PRINT_SCANNER_ACCESS,
  RESOURCE_AVAILABILITY,
  RESOURCE_AVAILABILITY_OVERTIME,
  RESOURCE_AVAILABILITY_PERIOD,
  RESOURCE_WILLING_TO_RELOCATE,
  RESOURCE_WORK_ARRANGEMENT,
  RESOURCE_WORK_LOCATION,
  TIMEZONES,
  TRAVEL_AVAILABILITY,
  WORK_COMPUTER_OS_TYPES,
  WORK_COMPUTER_TYPES,
} from '../constants';
import {DURATION} from '../constants/duration';
import CommonHelper from './CommonHelper';

class ResourceHelper extends CommonHelper {
  static languages(arrayOfLang) {
    const existKeys = arrayOfLang?.length
      ? arrayOfLang.map((l) => l?.language)
      : [];

    return Object.keys(LANGUAGES)
      .filter((l) => !existKeys.includes(l))
      .sort()
      .map((key) => {
        return {
          key: key,
          value: key,
          label: LANGUAGES[key],
        };
      });
  }
  static languageLevel() {
    return Object.keys(LANGUAGE_LEVELS).map((key) => {
      return {
        key: key,
        value: key,
        label: LANGUAGE_LEVELS[key],
      };
    });
  }
  static degreeLevel() {
    return Object.keys(EDUCATIONS).map((key) => {
      return {
        key: key,
        value: key,
        label: EDUCATIONS[key],
      };
    });
  }
  static availabilityStatus(isFilter) {
    const obj = isFilter
      ? {available: 'Available', available_soon: 'Available Soon'}
      : RESOURCE_AVAILABILITY;
    return Object.keys(obj).map((key) => {
      return {
        key: key,
        value: key,
        label: obj[key],
        disabled: key === 'engaged',
      };
    });
  }

  static availabilityPeriods() {
    return Object.keys(RESOURCE_AVAILABILITY_PERIOD).map((key) => {
      return {
        key: key,
        value: key,
        label: RESOURCE_AVAILABILITY_PERIOD[key],
      };
    });
  }

  static willingToRelocate() {
    return Object.keys(RESOURCE_WILLING_TO_RELOCATE).map((key) => {
      return {
        key: key,
        value: key,
        label: RESOURCE_WILLING_TO_RELOCATE[key],
      };
    });
  }

  static workArrangement() {
    return Object.keys(RESOURCE_WORK_ARRANGEMENT).map((key) => {
      return {
        key: key,
        value: key,
        label: RESOURCE_WORK_ARRANGEMENT[key],
      };
    });
  }

  static availabilityOvertime() {
    return Object.keys(RESOURCE_AVAILABILITY_OVERTIME).map((key) => {
      return {
        key: key,
        value: key,
        label: RESOURCE_AVAILABILITY_OVERTIME[key],
      };
    });
  }

  static workLocations() {
    return Object.keys(RESOURCE_WORK_LOCATION).map((key) => {
      return {
        key: key,
        value: key,
        label: RESOURCE_WORK_LOCATION[key],
      };
    });
  }

  static travelAvailability(hasNo = false) {
    const options = hasNo
      ? {no: 'No', ...TRAVEL_AVAILABILITY}
      : TRAVEL_AVAILABILITY;
    return Object.keys(options)
      .filter((el) => el !== 'yes')
      .map((key) => {
        return {
          key: key,
          value: key,
          label: options[key],
        };
      });
  }

  static workComputerTypes() {
    return Object.keys(WORK_COMPUTER_TYPES).map((key) => {
      return {
        key: key,
        value: key,
        label: WORK_COMPUTER_TYPES[key],
      };
    });
  }

  static wokComputerOsTypes() {
    return Object.keys(WORK_COMPUTER_OS_TYPES).map((key) => {
      return {
        key: key,
        value: key,
        label: WORK_COMPUTER_OS_TYPES[key],
      };
    });
  }

  static printerScannerAccess() {
    return Object.keys(PRINT_SCANNER_ACCESS).map((key) => {
      return {
        key: key,
        value: key,
        label: PRINT_SCANNER_ACCESS[key],
      };
    });
  }

  static assignmentDurations() {
    return Object.keys(DURATION).map((key) => {
      return {
        key,
        value: key,
        label: DURATION[key],
      };
    });
  }

  static timezones() {
    return Object.keys(TIMEZONES).map((key) => {
      return {
        key: key,
        value: key,
        label: TIMEZONES[key],
      };
    });
  }

  static experience() {
    return [1, 3, 5, 10].map((count) => {
      return {
        key: count,
        label: `At least ${count} ${count === 1 ? 'year' : 'years'}`,
        value: count,
        query: {
          'years_experience[gte]': count,
        },
      };
    });
  }

  static billingRates() {
    return [10, 20, 30, 40, 50, 60, 70, 100, 125, 150, 200].map((count) => {
      const next =
        count < 100
          ? count + 10
          : count >= 100 && count < 150
          ? count + 25
          : count >= 150 && count < 200
          ? count + 50
          : undefined;

      return {
        key: count,
        label: `$${count}${count === 200 ? '+' : ''} ${
          next ? `- $${next}` : ''
        } / hour`,
        value: count,
        query: {
          'billing_rate[gte]': count,
          'billing_rate[lte]': next,
        },
      };
    });
  }
}

export default ResourceHelper;
