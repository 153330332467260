import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form} from 'antd';
import {CompanyUserDropdown} from 'app/shared/components/dropdowns';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import PropTypes from 'prop-types';
import React from 'react';

const SelectionForm = ({onContinue, onSave, initialValues}) => {
  const [manager, setManager] = React.useState(initialValues?.hiringManager);

  const handleChangeManager = React.useCallback((_, option) => {
    setManager(option);
  }, []);

  const onFormSubmit = React.useCallback(
    (formdata) => {
      const payload = {
        ...formdata,
        hiring_manager: manager,
      };

      onSave(payload);
      onContinue();
    },
    [manager, onContinue, onSave],
  );

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}
          style={{width: '280px'}}>
          <AppFormBody>
            <Form.Item
              name='job_title'
              label='Job Title'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Job Title'),
                },
              ]}>
              <AppInput
                placeholder={getInputPlaceHolder('requisition job title')}
              />
            </Form.Item>

            <Form.Item
              name='openings'
              label='# of Openings'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Openings'),
                },
                {
                  type: 'number',
                },
              ]}>
              <AppInputNumber
                min={1}
                placeholder={getInputPlaceHolder('# of openings')}
                style={{width: '280px'}}
              />
            </Form.Item>

            <Form.Item
              name='type'
              label='Type'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Type'),
                },
              ]}>
              <AppSelect
                placeholder={getInputPlaceHolder('job type')}
                options={RequisitionHelper.workTypes()}
              />
            </Form.Item>

            <Form.Item
              name='priority'
              label='Priority'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Priority'),
                },
              ]}>
              <AppSelect
                placeholder={getInputPlaceHolder('requisition priority')}
                options={RequisitionHelper.jobPriorities()}
              />
            </Form.Item>

            <Form.Item
              name='job_status'
              label='Job Status'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Job Status'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('job status')}
                options={RequisitionHelper.jobStatus()}
              />
            </Form.Item>

            <Form.Item
              name='hiring_manager'
              label='Hiring Manager'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Hiring Manager'),
                },
              ]}>
              <CompanyUserDropdown
                placeholder='Search for hiring manager'
                variant='client'
                role='hiring_manager'
                manager={manager}
                onChange={handleChangeManager}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

SelectionForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default SelectionForm;
