import {
  CreateInvoice,
  default as Financials,
  ViewInvoice,
} from 'app/pages/main/financials';
// import {CreateInvoice} from 'app/pages/client/financials';

export const supplier_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
];

export const client_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
];

export const manager_FinancialRoutes = [
  {
    path: 'financials',
    element: <Financials />,
  },
  {
    path: 'financials/:id/view-invoice',
    element: <ViewInvoice />,
  },
  {
    path: 'financials/add-new-invoice',
    element: <CreateInvoice />,
  },
];
