import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Col, Collapse, Form, Input, Row, Typography} from 'antd';
import {SkillTagDropdown} from 'app/shared/components/dropdowns';
import React from 'react';

import AdvanceFilterOptions from './AdvanceFilterOptions';
import formConfig from './config';

const FilterRequisitions = () => {
  return (
    <React.Fragment>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Title' name='job_title'>
          <Input placeholder={getInputPlaceHolder('title')} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Type' name={'type'}>
          <AppSelect
            placeholder={getSelectPlaceHolder('job type')}
            options={formConfig.type.options}
            allowClear={true}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='# of Openings' name='openings'>
          <AppSelect
            placeholder={getSelectPlaceHolder('range(s)')}
            options={formConfig.openings.options}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Skills' name='skill_tags'>
          <SkillTagDropdown
            labelInValue={true}
            placeholder={getInputPlaceHolder('required skills')}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Location' name={'job_country'}>
          <AppSelect
            placeholder={getSelectPlaceHolder('countries')}
            options={formConfig.job_country.options}
            showSearch={true}
            allowClear={true}
            mode={'multiple'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Start Date' name={'start_date'}>
          <AppDatePicker
            placeholder={getSelectPlaceHolder('date')}
            format='MM/DD/YYYY'
            style={{width: '100%'}}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Status' name={'job_status'}>
          <AppSelect
            placeholder={getSelectPlaceHolder('job status')}
            allowClear={true}
            options={formConfig.job_status.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Requisition Status' name='status'>
          <AppSelect
            placeholder={getSelectPlaceHolder('requisition status')}
            allowClear={true}
            options={formConfig.status.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Duration' name={'job_duration'}>
          <AppSelect
            placeholder={getSelectPlaceHolder('duration')}
            allowClear={true}
            options={formConfig.job_duration.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Job Max Billing Rate' name='billing_rate_max'>
          <AppSelect
            placeholder={getSelectPlaceHolder('billing rate')}
            allowClear={true}
            options={formConfig.billing_rate_max.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Requisition Age' name='created_at'>
          <AppSelect
            placeholder={getSelectPlaceHolder('age')}
            allowClear={true}
            options={formConfig.created_at.options}
          />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={6}>
        <Form.Item label='Requisition Priority' name='priority'>
          <AppSelect
            placeholder={getSelectPlaceHolder('requisition priority')}
            allowClear={true}
            options={formConfig.priority.options}
          />
        </Form.Item>
      </Col>
      <Col sm={24}>
        <Collapse ghost>
          <Collapse.Panel
            key={'advance_filter'}
            header={
              <Typography.Link level={3}>
                Advanced Filter Options
              </Typography.Link>
            }>
            <Row gutter={30}>
              <AdvanceFilterOptions />
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </React.Fragment>
  );
};

export default FilterRequisitions;
