import {Typography} from 'antd';
import {DATE_FORMATS} from 'app/config/app';
import {CANDIDATE_STATUSES} from 'app/constants/candidate-statuses.js';
import {INQUIRY_STATUSES} from 'app/constants/inquiry-statuses.js';
import {SUBMISSION_STATUSES} from 'app/constants/submission-statuses.js';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize.js';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import {
  formatDate,
  getAddress,
  getFullName,
} from 'app/utils/helpers/DataFormatHelper.js';
import {insert} from 'app/utils/helpers/Insert.js';
import React from 'react';
import {Link} from 'react-router-dom';

export const getColumns = (isClient) => {
  const supplier = {
    title: 'Supplier',
    dataIndex: 'role',
    key: 'role',
    render: (_, {supplier}) => {
      return (
        <Link
          to={`/suppliers/${supplier?.id}/view-supplier`}
          component={Typography.Link}>
          {getUserName(supplier?.name, 'SUP-', 'Supplier')}
        </Link>
      );
    },
  };
  const defaultArray = [
    {
      title: 'Name / Location',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource, id}) => {
        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link
                to={`/candidates/${id}/view-candidate`}
                component={Typography.Link}>
                {getFullName(resource)}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {getAddress(resource)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Billing Rate',
      dataIndex: 'billingRateLabel',
      key: 'billingRateLabel',
      width: 100,
      render: (billingRateLabel) => {
        return <Typography.Text>{billingRateLabel}</Typography.Text>;
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
      render: (available_on) => (
        <Typography.Text>
          {formatDate(available_on, DATE_FORMATS.md) || 'N/A'}
        </Typography.Text>
      ),
    },
    {
      title: 'Candidate Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => (
        <Typography.Text>
          {CANDIDATE_STATUSES[value] || capitalizeFirstLetter(value)}
        </Typography.Text>
      ),
    },
    {
      title: 'Inquiry / Submission',
      dataIndex: 'title',
      key: 'title',
      render: (_, {inquiry, submission, requisition}) => {
        const type = inquiry || submission;
        const path = inquiry
          ? `/inquiries/${type?.id}/view-inquiry`
          : `/submissions/${type?.id}/view-submission`;

        return (
          <Typography.Paragraph>
            <Typography.Paragraph className={'mb-0'}>
              <Link to={path} component={Typography.Link}>
                {requisition?.jobTitle}
              </Link>
            </Typography.Paragraph>
            <Typography.Text type={'secondary'} className={'text-xs'}>
              {type?.hid}
            </Typography.Text>
          </Typography.Paragraph>
        );
      },
    },
    {
      title: 'Inquiry / Submission Status',
      dataIndex: 'inquiry/submission_status',
      key: 'inquiry/submission_status',
      render: (_, {inquiry, submission}) => {
        let status;
        if (inquiry) {
          status =
            INQUIRY_STATUSES[inquiry.status] ||
            capitalizeFirstLetter(inquiry.status);
        }

        if (submission) {
          status =
            SUBMISSION_STATUSES[submission.status] ||
            capitalizeFirstLetter(submission.status);
        }

        return (
          <Typography.Text>{capitalizeFirstLetter(status)}</Typography.Text>
        );
      },
    },
  ];

  return isClient ? insert(defaultArray, 4, supplier) : defaultArray;
};
