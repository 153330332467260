import AppCard from '@wieldy/components/AppCard';
import AppRevealCard from '@wieldy/components/AppRevealCard';
import {getAssetsUrl} from '@wieldy/utils/helpers/UrlHelper';
import {Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useReveal} from 'app/hooks/useReveal';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {ClientCard} from '../../client';
import {SupplierCard} from '../../supplier';
import RevealCardFooter from '../partials/RevealCardFooter';
import RequisitionCardDetail from '../RequisitionCardDetail';
import ResourceCardDetail from '../ResourceCardDetail';
import EngagementCardContent from './EngagementCardContent';
import EngagementCardHeader from './EngagementCardHeader';

const EngagementCard = ({engagement}) => {
  const [revealed, setReveal] = useState(false);
  const {isSupplier} = useAccountType();
  const {user, userName, userType, link} = useReveal(engagement);
  useEffect(() => {
    if (engagement) {
      setReveal(false);
    }
  }, [engagement]);

  return (
    <AppRevealCard
      title={`${userType} Details`}
      revealed={revealed}
      setReveal={setReveal}
      onClose={() => setReveal(false)}
      revealComponent={
        isSupplier ? (
          <ClientCard client={user} />
        ) : (
          <SupplierCard supplier={user} />
        )
      }>
      <AppCard
        className='card-header-small'
        title={
          <Typography.Text className='font-medium text-uppercase text-xs'>
            <img
              src={getAssetsUrl('images/hand-shake-dark.png')}
              alt='Hand Shake'
              className={'mr-2'}
            />
            Engagement #{engagement?.hid}
          </Typography.Text>
        }
        extra={
          <Typography.Text className='text-xs font-semibold'>
            {engagement?.statusName}
          </Typography.Text>
        }
        headStyle={{
          backgroundColor: engagement?.isOpen ? '#D7FFD7' : '#DEDEDE',
          borderBottom: 'none',
        }}>
        <EngagementCardHeader engagement={engagement} />
        <EngagementCardContent engagement={engagement} />
        <ResourceCardDetail
          resource={{...engagement?.resource, user: engagement?.resource_user}}
          isEngagement={true}
        />
        <RequisitionCardDetail requisition={engagement?.requisition} />
        <RevealCardFooter
          link={link}
          title={userName}
          subTitle={userType}
          rating={user?.ratingTotal}
          reviews={user?.ratingCount}
          onClick={() => setReveal(true)}
        />
      </AppCard>
    </AppRevealCard>
  );
};

EngagementCard.propTypes = {
  engagement: PropTypes.object,
};

export default EngagementCard;
