import AppLabels from '@wieldy/components/AppLabels';
import PropTypes from 'prop-types';
import React from 'react';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='30%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const RequisitionCardContent = ({requisition}) => {
  return (
    <table className='table' style={{margin: '0 -8px 16px'}}>
      <tbody>
        <ItemInfo
          label='Filled/Openings'
          content={`${requisition?.filled}/${requisition?.openings}`}
        />

        <ItemInfo
          label='Skills'
          content={
            requisition?.skillTags && (
              <AppLabels
                items={requisition.skillTags.map((item) => item?.name)}
                visible={1}
              />
            )
          }
        />

        <ItemInfo label='Type' content={requisition?.workType} />
        <ItemInfo label='Job Status' content={requisition?.jobStatus} />
        <ItemInfo label='Duration' content={requisition?.jobDuration} />
        <ItemInfo label='Start Date' content={requisition?.startDate} />
        <ItemInfo label='End Date' content={requisition?.endDate} />
        <ItemInfo label='Worksite Type' content={requisition?.jobWorkSite} />
        <ItemInfo
          label='Location(s)'
          content={
            requisition?.jobCountry && (
              <AppLabels items={requisition.jobCountry} visible={1} />
            )
          }
        />
      </tbody>
    </table>
  );
};

RequisitionCardContent.propTypes = {
  requisition: PropTypes.object,
};

export default RequisitionCardContent;
