import './index.style.less';
import './styles/index.scss';

import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import {chatApiKey} from 'app/config/chat';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUser} from 'app/store/features/user/userSlice';
import {
  Chat,
  enTranslations,
  Streami18n,
  useCreateChatClient,
} from 'stream-chat-react';

import ClientSupplierChat from './containers/ClientSupplierChat';
import MarketplaceChat from './containers/MarketplaceChat';

const i18nInstance = new Streami18n({
  language: 'en',
  translationsForLanguage: {
    ...enTranslations,
  },
});

const Messages = () => {
  const {user, chatToken} = useUser();
  const accountType = useAccountType();

  const client = useCreateChatClient({
    apiKey: chatApiKey,
    tokenOrProvider: chatToken,
    userData: {id: user.id},
  });

  if (!client) return <div>Loading...</div>;

  return (
    <div className='app__wrapper str-chat'>
      <Chat i18nInstance={i18nInstance} client={client} theme='team messaging'>
        <AppPageContainer contentClass='chatWrapper'>
          {accountType.isMarketplace ? (
            <MarketplaceChat />
          ) : (
            <ClientSupplierChat />
          )}
        </AppPageContainer>
      </Chat>
    </div>
  );
};

export default Messages;
