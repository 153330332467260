import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {isJson} from '../../../utils/helpers/isJson';
import Editor from '../../components/Editor/Editor';

const SkillsResponsibilities = ({requisition}) => {
  return (
    <div className='mb-5'>
      <Typography.Title level={4} className='mb-5'>
        Job Information
      </Typography.Title>
      <Descriptions
        colon={false}
        column={1}
        className={'description-skills'}
        labelStyle={{minWidth: '160px', width: '16.5%'}}>
        <Descriptions.Item label='Job Title'>
          {requisition.jobTitle}
        </Descriptions.Item>

        <Descriptions.Item label='Overview'>
          <Editor
            key='Overview'
            readOnly={true}
            initValue={
              isJson(requisition.jobOverview)
                ? JSON.parse(requisition.jobOverview)
                : requisition.jobOverview
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label='Role Description'>
          <Editor
            key='reole'
            readOnly={true}
            initValue={
              isJson(requisition.jobRoleDescription)
                ? JSON.parse(requisition.jobRoleDescription)
                : requisition.jobRoleDescription
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label='Responsibilities'>
          <Editor
            key='jobResponsibilities'
            readOnly={true}
            initValue={
              isJson(requisition.jobResponsibilities)
                ? JSON.parse(requisition.jobResponsibilities)
                : requisition.jobResponsibilities
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label='Skills/Experience'>
          <Editor
            key='jobSkillsExperience'
            readOnly={true}
            initValue={
              isJson(requisition.jobSkillsExperience)
                ? JSON.parse(requisition.jobSkillsExperience)
                : requisition.jobSkillsExperience
            }
          />
        </Descriptions.Item>

        <Descriptions.Item label='Tags'>
          <Row wrap='wrap' gutter={[5, 5]}>
            {requisition.skillTags.map((tag, index) => (
              <Col key={index}>
                <AppTag
                  className={'border-none'}
                  label={tag?.name}
                  shape={'square'}
                />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

SkillsResponsibilities.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(SkillsResponsibilities);
