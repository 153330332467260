import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Typography} from 'antd';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import PropTypes from 'prop-types';
import React from 'react';

const InformationForm = ({initialValues, onSave, onContinue}) => {
  const onFormSubmit = (formData) => {
    onSave({
      ...formData,
      candidate_min_education: formData?.candidate_min_education ?? undefined,
      billing_rate_max: formData?.billing_rate_max ?? undefined,
      flsa_eligible: formData?.flsa_eligible ?? null,
    });
    onContinue();
  };

  const billingValidator = (_, value, callback) => {
    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return callback('Billing rate is not a valid number');
    }
    if (value && value > 1000) {
      return callback('Max amount is $1000');
    }
    return callback();
  };

  const [form] = Form.useForm();
  const billingValue = Form.useWatch('billing_rate_max', form);

  return (
    <AppCard>
      <AppFormWrapper>
        <Typography.Title level={4} className={'mb-3'}>
          Compensation
        </Typography.Title>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item
              style={{width: 280}}
              name='billing_rate_max'
              label='Maximum Billing Rate'
              rules={[{validator: billingValidator}]}>
              <AppInputNumber
                placeholder='Enter amount'
                prefix='$'
                addonAfter='USD'
                type='number'
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                style={{width: '200px'}}
                controls={false}
              />
            </Form.Item>

            <Form.Item
              style={{width: 280}}
              name='billing_rate_period'
              label='Billing Rate Period'
              rules={[
                {
                  required: !!billingValue,
                  message: getRequiredMessage('Billing Rate Period'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('billing rate period')}
                options={RequisitionHelper.billingRatePeriods()}
                allowClear={true}
              />
            </Form.Item>

            <Form.Item
              name='expenses_eligible'
              label='Expenses Eligible'
              style={{width: 280}}>
              <AppSelect
                placeholder='Is expenses eligible'
                options={RequisitionHelper.yesNoOptions()}
              />
            </Form.Item>

            <Form.Item
              style={{width: 280}}
              name='flsa_eligible'
              label='FLSA Eligible'>
              <AppSelect
                placeholder='Is FLSA eligible'
                options={RequisitionHelper.yesNoOptions({
                  yes: 'Exempt',
                  no: 'Non Exempt',
                })}
                allowClear={true}
              />
            </Form.Item>

            <Typography.Title level={4} className={'mb-3 mt-10'}>
              Candidate Criteria
            </Typography.Title>

            <Form.Item name='candidate_country' label='Location(s)'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'acceptable options for candidate’s location(s)',
                )}
                mode='multiple'
                options={RequisitionHelper.countries()}
              />
            </Form.Item>

            <Form.Item
              // style={}
              name='candidate_visa_status'
              label='Visa Status'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'required candidate’s visa status',
                )}
                options={RequisitionHelper.visaStatus()}
                mode='multiple'
              />
            </Form.Item>

            <Form.Item
              name='candidate_min_experience'
              label='Minimum Experience'>
              <AppSelect
                placeholder={getSelectPlaceHolder('required # of years')}
                options={RequisitionHelper.candidateMinExperience()}
                allowClear={true}
              />
            </Form.Item>

            <Form.Item name='candidate_min_education' label='Minimum Education'>
              <AppSelect
                placeholder={getSelectPlaceHolder(
                  'required level of education',
                )}
                options={RequisitionHelper.educations()}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item name='supplier_country' label='Supplier Based In'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'acceptable options for supplier’s primary location',
                )}
                mode='tags'
                options={RequisitionHelper.countries()}
              />
            </Form.Item>

            <Typography.Title level={5} className={'mb-3 mt-10'}>
              Required Background Checks
            </Typography.Title>

            <Form.Item name='candidate_required_checks' label='Required Checks'>
              <AppSelect
                mode='multiple'
                placeholder={getSelectPlaceHolder('required background checks')}
                options={RequisitionHelper.backgroundChecks()}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
