export const getCardBottomContent = (item, state) => {
  return [
    {
      label: item?.inquiries === 1 ? 'Inquiry' : 'Inquiries',
      count: item?.inquiries,
      path: 'inquiries',
      state,
    },
    {
      label: item?.submissions === 1 ? 'Submission' : 'Submissions',
      count: item?.submissions,
      path: 'submissions',
      state,
    },
    {
      label: item?.engagements === 1 ? 'Engagement' : 'Engagements',
      count: item?.engagements,
      path: 'engagements',
      state,
    },
  ];
};
