import {useAccountType} from 'app/hooks/useAccountType';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';

export const useGetColumns = () => {
  const {isClient} = useAccountType();

  return [
    {
      title: 'Engagement ID',
      dataIndex: 'engagement',
      key: 'engagement',
      render: (_, {engagement}) => engagement.hid,
      width: 200,
    },
    {
      title: 'Resource Name',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource}) => getFullName(resource),
      width: 200,
    },
    {
      title: isClient ? 'Supplier' : 'Client',
      dataIndex: 'user',
      key: 'user',
      render: (_, {client, supplier}) => {
        const user = isClient ? supplier : client;
        return user?.name;
      },
      width: 200,
    },
  ];
};
