import moment from 'moment';

import {
  BACKGROUND_CHECKS,
  EDUCATIONS,
  JOB_PRIORITIES,
  TRAVEL_REQUIREMENTS,
  WORK_TYPES,
  WORKSITES,
} from '../constants';
import CommonHelper from './CommonHelper';

class RequisitionHelper extends CommonHelper {
  /** Job work types - contract, permanent */
  static workTypes() {
    return Object.keys(WORK_TYPES).map((key) => ({
      key: key,
      value: key,
      label: WORK_TYPES[key],
    }));
  }

  /** Job prorities - low, normal, urgent */
  static jobPriorities() {
    return Object.keys(JOB_PRIORITIES).map((key) => ({
      key: key,
      value: key,
      label: JOB_PRIORITIES[key],
    }));
  }

  /** Job worksites - remote, office */
  static worksites() {
    return Object.keys(WORKSITES).map((key) => ({
      key: key,
      value: key,
      label: WORKSITES[key],
    }));
  }

  /** Travel requirments - no, yes, yes less then 25% etc  */
  static travelRequirements() {
    return Object.keys(TRAVEL_REQUIREMENTS).map((key) => ({
      key: key,
      value: key,
      label: TRAVEL_REQUIREMENTS[key],
    }));
  }

  /** Educations - High school, bechlor degree etc */
  static educations() {
    return Object.keys(EDUCATIONS).map((key) => ({
      key: key,
      value: key,
      label: EDUCATIONS[key],
    }));
  }

  /** Background checks -  Civil Court, credit background etc */
  static backgroundChecks() {
    return Object.keys(BACKGROUND_CHECKS).map((key) => ({
      key: key,
      value: key,
      label: BACKGROUND_CHECKS[key],
    }));
  }

  /** Requision status, open, filled, close */
  static requisitionStatus() {
    return [
      {
        key: 'open',
        value: 'open',
        label: 'Open - Accepting Candidates',
      },
      {
        key: 'closed',
        value: 'closed',
        label: 'Closed',
      },
      {
        key: 'filled',
        value: 'filled',
        label: 'Filled',
      },
    ];
  }

  /** Job duration */
  static durations() {
    return [
      {key: '1w', value: 7, label: '1 week'},
      {key: '2w', value: 14, label: '2 weeks'},
      {key: '3w', value: 21, label: '3 weeks'},
      {key: '4w', value: 28, label: '4 weeks'},
      {key: '1m', value: 30, label: '1 month'},
      {key: '2m', value: 60, label: '2 months'},
      {key: '3m', value: 90, label: '3 months'},
      {key: '4m', value: 120, label: '4 months'},
      {key: '5m', value: 150, label: '5 months'},
      {key: '6m', value: 180, label: '6 months'},
      {key: '7m', value: 210, label: '7 months'},
      {key: '8m', value: 240, label: '8 months'},
      {key: '9m', value: 270, label: '9 months'},
      {key: '10m', value: 300, label: '10 months'},
      {key: '11m', value: 330, label: '11 months'},
      {key: '12m', value: 365, label: '1 year'},
      {key: 'unlimited', value: 0, label: 'Over 1 year'},
    ];
  }

  /** Candidate minimum experience */
  static candidateMinExperience() {
    return [
      {key: '1-6m', value: 6, label: 'At least 6 months'},
      {key: '6-12m', value: 12, label: 'At least 1 year'},
      {key: '1-3y', value: 36, label: 'At least 3 years'},
      {key: '3-6y', value: 48, label: 'At least 5 years'},
      {key: '6-10y', value: 120, label: 'At least 10 years'},
      {key: 'over10y', value: 0, label: 'Over 10 years'},
    ];
  }

  /** Filter - number of openings */
  static filterNumOfOpenings() {
    return [
      {
        key: '1-5',
        label: '1-5 openings',
        value: 5,
        query: {
          'openings[gte]': 1,
          'openings[lte]': 5,
        },
      },
      {
        key: '5-10',
        label: '5-10 openings',
        value: 10,
        query: {
          'openings[gte]': 5,
          'openings[lte]': 10,
        },
      },
      {
        key: '10+',
        label: '10+ openings',
        value: 11,
        query: {
          'openings[gt]': 10,
        },
      },
    ];
  }

  /** Filter - job durations */
  static filterJobDuration() {
    return [
      {
        key: 'at_least_1m',
        label: 'At least 1 month',
        value: 30,
        query: {
          'job_duration[gte]': 30,
        },
      },
      {
        key: 'at_least_3m',
        label: 'At least 3 months',
        value: 90,
        query: {
          'job_duration[gte]': 90,
        },
      },
      {
        key: 'at_least_6m',
        label: 'At least 6 months',
        value: 180,
        query: {
          'job_duration[gte]': 180,
        },
      },
      {
        key: 'at_least_12m',
        label: 'At least 12 months',
        value: 365,
        query: {
          'job_duration[gte]': 365,
        },
      },
    ];
  }

  /** Filter - max billing rate */
  static filterMaxBillingRate() {
    return [
      {
        key: 'any_rate',
        label: 'Any rate',
        value: 'any_rate',
        query: {},
      },
      {
        key: 'at_least_10h',
        label: 'At least $10 / hour',
        value: 10,
        query: {
          'billing_rate_max[gte]': 10,
        },
      },
      {
        key: 'at_least_25h',
        label: 'At least $25 / hour',
        value: 25,
        query: {
          'billing_rate_max[gte]': 25,
        },
      },
      {
        key: 'at_least_50h',
        label: 'At least $50 / hour',
        value: 50,
        query: {
          'billing_rate_max[gte]': 50,
        },
      },
      {
        key: 'at_least_75h',
        label: 'At least $75 / hour',
        value: 75,
        query: {
          'billing_rate_max[gte]': 75,
        },
      },
      {
        key: 'at_least_100h',
        label: 'At least $100 / hour',
        value: 100,
        query: {
          'billing_rate_max[gte]': 100,
        },
      },
      {
        key: 'at_least_150h',
        label: 'At least $150 / hour',
        value: 150,
        query: {
          'billing_rate_max[gte]': 150,
        },
      },
      {
        key: 'at_least_200h',
        label: 'At least $200 / hour',
        value: 200,
        query: {
          'billing_rate_max[gte]': 200,
        },
      },
    ];
  }

  /** Filter - requistion created */
  static filterJobRequisitionAge() {
    return [
      {
        key: 'past24',
        label: 'Past 24 hours',
        value: 'day',
        query: {
          'created_at[gte]': moment().subtract(24, 'hours').unix(),
        },
      },
      {
        key: 'past_week',
        label: 'Past week',
        value: 'week',
        query: {
          'created_at[gte]': moment().subtract(1, 'week').unix(),
        },
      },
      {
        key: 'past_month',
        label: 'Past month',
        value: 'month',
        query: {
          'created_at[gte]': moment().subtract(1, 'month').unix(),
        },
      },
    ];
  }
}

export default RequisitionHelper;
