export const USER_CREDENTIALS = {
  USER_TYPES: {
    supplier: 'supplier',
    client: 'client',
    marketplace: 'marketplace',
  },
  USER_ROLES: {
    owner: 'owner',
    admin: 'admin',
    marketplace_manager: 'manager',
    hiring_manager: 'hiring_manager',
    resource_manager: 'resource_manager',
    resource: 'resource',
  },
};
