import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import {useReduceInfinityResponse} from 'app/hooks/useReduceInfinityResponse';
import resourceModal from 'app/modals/ResourceModal';
import {useGetInfinityResource} from 'app/services/resourceService';
import CreateList from 'app/shared/components/inquiry/CreateList';
import FilterResources from 'app/shared/resource/FilterResources';
import {setSubmissionCandidates} from 'app/store/features/submission/inquirySlice';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import CandidateDetails from '../CandidateDetails';

const ChooseSupplierContainer = () => {
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const supplierId = useSelector(({user}) => user.currentAccount.supplier.id);

  const {isEditMode, existingCandidates} = useSelector(
    ({submission}) => submission,
  );

  const currentFormattedCandidate = useMemo(
    () => (currentCandidate ? new resourceModal(currentCandidate) : {}),
    [currentCandidate],
  );

  const handleCurrentCandidate = useCallback((can) => {
    setCurrentCandidate(can);
  }, []);

  const {filterQuery, queryInit} = usePageQuery();

  const {data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage} =
    useGetInfinityResource({
      ...queryInit,
      expand: [...queryInit.expand, 'supplier', 'user'],
      ...filterQuery,
      supplier: supplierId,
      view_all: true,
    });

  const [candidates, totalCount] = useReduceInfinityResponse(data);

  const mappedCandidates = useMemo(() => {
    if (isEditMode) {
      return candidates.map((el) => ({
        ...el,
        isExist: existingCandidates.includes(el.id),
      }));
    }
    return candidates;
  }, [isEditMode, candidates, existingCandidates]);

  useEffect(() => {
    if (mappedCandidates.length && !currentCandidate) {
      handleCurrentCandidate(mappedCandidates[0]);
    }
    if (!mappedCandidates.length) {
      handleCurrentCandidate(null);
    }
  }, [currentCandidate, handleCurrentCandidate, mappedCandidates]);

  return (
    <>
      <AppGridContainer>
        <CreateList
          isSubmission={true}
          isLoading={isLoading}
          title='Candidates'
          items={mappedCandidates}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          totalCount={totalCount}
          filter={<FilterResources />}
          actionCreator={setSubmissionCandidates}
          handleCurrentItem={handleCurrentCandidate}
          currentItem={currentCandidate}
          isCalcItems={true}
        />
        {currentCandidate && (
          <Col xs={24} lg={16}>
            <AppCard style={{height: 'fit-content', minHeight: 300}}>
              <CandidateDetails candidate={currentFormattedCandidate} />
            </AppCard>
          </Col>
        )}
      </AppGridContainer>
    </>
  );
};

export default ChooseSupplierContainer;
