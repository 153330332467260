import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {INQUIRY_STATUS} from '@wieldy/constants/AppDataConsts';
import {Button, Divider, notification, Tooltip} from 'antd';
import {messagesAPI} from 'app/api/messages';
import {USER_INCLUDES} from 'app/constants';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import RequisitionModal from 'app/modals/RequisitionModal';
import SubmissionModal from 'app/modals/SubmissionModal';
import {useCreateSubmissionChannel} from 'app/services/messages';
import {useGetRequisition} from 'app/services/requisitionService';
import {
  useGetSubmission,
  useUpdateSubmission,
} from 'app/services/submissionService';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import React, {useEffect, useMemo, useState} from 'react';
import {RiMessageLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {getViewTabsData} from '../constants';
import MainContent from './MainContant';

const ViewSubmission = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const [isShowNote, setIsShowNote] = useState(false);
  const [channel, setChannel] = useState(null);

  const {mutate: mutationUpdate} = useUpdateSubmission(id);

  const includes = useMemo(
    () =>
      isSupplier
        ? USER_INCLUDES.clientIncludeDetails
        : USER_INCLUDES.supplierIncludeDetails,
    [isSupplier],
  );

  const {data, isLoading} = useGetSubmission(id, {
    expand: ['client', 'supplier'],
    include: ['candidates', 'requisition', 'relation_status', ...includes],
  });

  const submission = useMemo(() => new SubmissionModal(data), [data]);

  useEffect(() => {
    const fetchChannel = async () => {
      const channelId = `${submission.requisition}_${submission.client.id}_${submission.supplier.id}`;
      try {
        const result = await messagesAPI.isChannel(channelId);
        setChannel(result);
        // eslint-disable-next-line no-empty
      } catch (_err) {}
    };

    isSupplier && submission?.id && fetchChannel();
  }, [
    isSupplier,
    submission.client.id,
    submission?.id,
    submission.requisition,
    submission.supplier.id,
  ]);

  const {data: requisitionData} = useGetRequisition(submission.requisition, {
    include: [
      'skill_tags',
      'requisition_common_submissions',
      'requisition_common_inquiries',
      'requisition_common_engagements',
    ],
    ...(isClient && {target_supplier: submission.supplier.id}),
  });

  const createSubmissionChannel = useCreateSubmissionChannel();

  const requisition = new RequisitionModal(requisitionData);

  const {isNotes, handleShowNotes, response, isShowNotes, total} = useNotes(
    'submission_notes',
    'submission',
    id,
  );

  const tabsData = getViewTabsData(isNotes);
  const submissionTitle = isSupplier ? 'Submission For' : 'Submission From';
  const companyName = getUserName(
    submission[isSupplier ? 'client' : 'supplier'].name,
    isSupplier ? 'CLI-' : 'SUP-',
    isSupplier ? 'Client' : 'Supplier',
  );

  const handleSendMessage = () => {
    if (isMarketplace) {
      navigate('/messages');
    } else {
      createSubmissionChannel.mutate(submission.id, {
        onSuccess: ({data}) => {
          const channelId = data.id;
          navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
        },
      });
    }
  };

  const handleEditNote = (form, isCreate = false) => {
    const payload = {
      accompanying_message: form,
    };

    mutationUpdate(payload, {
      onSuccess: () => {
        notification.success({
          message: form
            ? isCreate
              ? 'Note to client created successfully'
              : 'Note to client updated successfully'
            : 'Note to client deleted successfully',
        });
        setIsShowNote(false);
      },
      onError: (error) => {
        notification.error({message: error?.message});
      },
    });
  };

  const handleShowEdit = () => {
    setIsShowNote(!isShowNote);
  };

  const showCreateChannelButton = useMemo(() => {
    if (isMarketplace) return false;
    if (isClient) return true;
    if (isSupplier) {
      if (
        submission?.status === INQUIRY_STATUS.in_review ||
        submission?.status === INQUIRY_STATUS.responded
      ) {
        return true;
      }
      if (submission?.status === INQUIRY_STATUS.sent && channel) {
        return true;
      }
      return false;
    }
  }, [isMarketplace, isClient, isSupplier, submission?.status, channel]);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          header='inner'
          category={`${submissionTitle} ${companyName}`}
          title={requisition?.jobTitle}
          count={
            <AppTag
              className={'tracking-normal'}
              shape={'circle'}
              color={submission?.tagColor}
              label={submission?.status}
            />
          }
          tabs={<AppDynamicTabs tabsData={tabsData} scroll />}
          extra={
            <>
              {showCreateChannelButton && (
                <>
                  <Tooltip
                    overlayStyle={{fontSize: '12px'}}
                    title={'Send message'}>
                    <Button
                      type='primary'
                      shape='circle'
                      onClick={handleSendMessage}
                      icon={<RiMessageLine fontSize={16} />}
                    />
                  </Tooltip>
                </>
              )}
              {showCreateChannelButton && !isNotes && (
                <Divider type='vertical' style={{height: 28}} />
              )}
              {isNotes || (
                <>
                  <Tooltip
                    overlayStyle={{fontSize: '12px'}}
                    title={'Add Internal Note'}>
                    <Button
                      type='primary'
                      shape='circle'
                      onClick={handleShowNotes}
                      icon={<NotesBtnIcon />}
                    />
                  </Tooltip>
                </>
              )}
            </>
          }
        />
      }>
      <MainContent
        handleShowEdit={handleShowEdit}
        submission={submission}
        requisition={requisition}
        isShowNote={isShowNote}
        isShowNotes={isShowNotes}
        notesTotal={total}
        notesResponse={response}
        handleEditNote={handleEditNote}
      />
    </AppPageContainer>
  );
};

export default ViewSubmission;
