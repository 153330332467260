import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const JobDetails = ({engagement}) => {
  return (
    <div className='mb-6'>
      <Typography.Title level={4} className={'mb-4'}>
        Job Details
      </Typography.Title>

      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Worksite Type'>
          {engagement.job_worksite}
        </Descriptions.Item>
        <Descriptions.Item label='Worksite Location'>
          {engagement.job_worksite_location}
        </Descriptions.Item>
        <Descriptions.Item label='Job Status'>
          {engagement.job_status}
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {engagement.job_travel_requirements}
        </Descriptions.Item>

        <Descriptions.Item label='FLSA Eligible'>
          {engagement.job_flsa_eligible}
        </Descriptions.Item>
        <Descriptions.Item label='Expenses Eligible'>
          {engagement.job_expenses_eligible}
        </Descriptions.Item>
        <Descriptions.Item label='Overtime Eligible'>
          {engagement.job_overtime_eligible}
        </Descriptions.Item>
        <Descriptions.Item label='Overtime Multiple'>
          {engagement.job_overtime_multiple}
        </Descriptions.Item>
        <Descriptions.Item label='Payment Terms'>
          {engagement.job_payment_term}
        </Descriptions.Item>
        <Descriptions.Item label='Charge Tax'>
          {engagement.tax_engagement}
        </Descriptions.Item>
        {engagement.tax_engagement !== 'No' && (
          <>
            <Descriptions.Item label='Charge Tax on Labor'>
              {engagement.tax_labor}
            </Descriptions.Item>
            <Descriptions.Item label='Charge Tax on Expenses'>
              {engagement.tax_expenses}
            </Descriptions.Item>
            <Descriptions.Item label='Country Tax'>
              {engagement.tax_country}
            </Descriptions.Item>
            <Descriptions.Item label='State Tax'>
              {engagement.tax_state}
            </Descriptions.Item>
            <Descriptions.Item label='City Tax' span={1}>
              {engagement.tax_city}
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </div>
  );
};

JobDetails.propTypes = {
  engagement: PropTypes.object,
};
export default JobDetails;
