import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {useFirstRender} from '../../../../hooks/useFirstRender';
import {useSupplierUsers} from '../CompanyMemberDropdown/constants';
import {useCompanyUsers} from './constants';

const CompanyUserDropdown = ({
  fetchDelay = 700,
  manager,
  variant = 'client',
  queryParams = {},
  initialFetch = false,
  getValue = () => [],
  filterMembers = (m) => m,
  ...restProps
}) => {
  const [users, setUsers] = React.useState(
    manager && typeof manager !== 'string' ? [manager] : [],
  );
  const {details} = useSelector(({engagement}) => engagement);

  const isMemberList = variant === 'supplier_all';
  const mutation = (isMemberList ? useSupplierUsers : useCompanyUsers)(
    isMemberList ? details.engagement_supplier_id : variant,
  );

  const fetchRef = React.useRef(0);

  if (variant === 'resource') {
    queryParams.user = '';
  }
  const isFirstRender = useFirstRender();

  React.useEffect(() => {
    if (initialFetch && isFirstRender) {
      mutation.mutate(queryParams, {
        onSuccess: (items) => {
          if (items) {
            setUsers(items.filter(filterMembers));
            getValue(items.filter(filterMembers));
          }
        },
      });
    }
  }, [
    fetchDelay,
    filterMembers,
    getValue,
    initialFetch,
    isFirstRender,
    mutation,
    queryParams,
  ]);

  const handleOnSearch = React.useMemo(() => {
    return debounce((value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setUsers([]);
      mutation.mutate(
        {
          ...queryParams,
          first_name: value,
        },
        {
          onSuccess: (items) => {
            if (fetchId !== fetchRef.current) {
              return;
            }
            setUsers(items);
          },
        },
      );
    }, fetchDelay);
  }, [fetchDelay, mutation, queryParams]);

  return (
    <AppSelect
      showSearch={true}
      options={users}
      onSearch={handleOnSearch}
      filterOption={false}
      fieldNames={{label: 'name', value: 'id'}}
      notFoundContent={mutation.isLoading ? 'Fetching...' : null}
      showArrow={false}
      {...restProps}
    />
  );
};

CompanyUserDropdown.propTypes = {
  fetchDelay: PropTypes.number,
  manager: PropTypes.object,
  variant: PropTypes.oneOf(['client', 'supplier']).isRequired,
  queryParams: PropTypes.object,
  initialFetch: PropTypes.bool,
  getValue: PropTypes.func,
  filterMembers: PropTypes.func,
};
export default CompanyUserDropdown;
