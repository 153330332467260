import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {CATEGORIES} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import All from 'app/pages/main/candidates/MainContent/All.js';
import Browse from 'app/pages/main/candidates/MainContent/Browse.js';
import PageQueryProviderNew from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/resource/FilterResources';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {getPageQueries, tabsData} from './MainContent/constants';

const Candidates = () => {
  const {category} = useParams();
  const [activeTab, setActiveTab] = useState(CATEGORIES.ALL);
  const {isSupplier} = useAccountType();

  const activeKey = React.useMemo(() => {
    return category ?? 'all';
  }, [category]);

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  return (
    <PageQueryProviderNew
      category={category}
      pageParams={getPageQueries(isSupplier)}
      filterConfig={filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            title={`${activeTab?.title} Candidates`}
            category='Candidates'
            tabs={
              <AppDynamicTabs
                tabsData={tabsData}
                activeKey={activeKey}
                setActiveTab={setActiveTab}
              />
            }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProviderNew>
  );
};

export default Candidates;
