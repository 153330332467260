import AppTable from '@wieldy/components/AppTable';
import {capitalizedAllWords} from '@wieldy/utils/helpers/StringHelper';
import {DATE_FORMATS} from 'app/config/app';
import {formatUnixDate} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const InvoiceDetail = ({invoice}) => {
  return (
    <AppTable
      columns={[
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
          render: (date) => formatUnixDate(date, DATE_FORMATS['/']),
        },
        {
          title: 'Payment Terms',
          dataIndex: 'payment_terms',
          key: 'payment_terms',
          render: (_, {engagement}) =>
            engagement.job_payment_term
              ? capitalizedAllWords(
                  engagement.job_payment_term.replace('_', ' '),
                )
              : 'N/A',
        },
        {
          title: 'Due Date',
          dataIndex: 'due_date',
          key: 'due_date',
        },
        {
          title: 'Invoice #',
          dataIndex: 'hid',
          key: 'hid',
        },
      ]}
      dataSource={[invoice]}
      pagination={false}
    />
  );
};

InvoiceDetail.propTypes = {
  invoice: PropTypes.object,
};

export default React.memo(InvoiceDetail);
