import {Button, Space} from 'antd';
import useStep from 'app/hooks/useStep';
import {useUpdateResource} from 'app/services/resourceService';
import {formatISO} from 'app/utils/helpers/DataFormatHelper';
import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

const SubmitResourceForm = () => {
  const {currentStep} = useStep();

  const {billing, summary, skills, supplemental, histories} = useSelector(
    ({resource}) => resource,
  );
  const {id} = useParams();
  const navigate = useNavigate();

  const {mutate, isLoading} = useUpdateResource(id);

  const handleCancel = () => {
    navigate(`/resources/${id}/view-resource`);
  };

  const handleSubmit = () => {
    const isUSA = summary.country === 'united_states';

    const payload = {
      ...billing,
      ...summary,
      ...supplemental,
      employment_history: histories,
      us_state: isUSA ? summary.us_state : undefined,
      skill_tags: skills.map(
        ({tag, years_experience, expertise_level, last_used, id, deleted}) => ({
          last_used,
          deleted,
          expertise_level,
          tag: tag?.id,
          years_experience,
          id,
        }),
      ),
      available_to: formatISO(summary.available_to) ?? null,
      available_on: formatISO(summary.available_on) ?? null,
      employed_since: formatISO(summary.employed_since),
    };

    mutate(payload, {
      onSuccess: () => {
        navigate(`/resources/${id}/view-resource`);
      },
    });
  };
  return (
    <>
      {currentStep === 6 && (
        <Space>
          <Button type='primary' loading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </Space>
      )}
    </>
  );
};

export default SubmitResourceForm;
