import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppTable from '@wieldy/components/AppTable';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Button, Space} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse';
import {useGetTimecardList} from 'app/services/timecardsService';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import moment from 'moment';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {getColumns} from './columns';

const TimeCards = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {isSupplier} = useAccountType();
  const {pageSize, currentPage, onChangePage, onShowSizeChange} = useBrowse({
    initPageSize: 5,
  });

  const {data, isLoading} = useGetTimecardList({
    engagement: id,
    statuses: ['submitted', 'approved', 'rejected'],
    include: ['total_count', 'pay_units', 'net_total', 'invoice_author'],
    expand: ['invoice', 'approved_by', 'submitted_by'],
  });

  const [notSortedTimecards, totalCount] = getItemsData('timecards', data);
  const timecards = notSortedTimecards.sort(
    (a, b) => moment(b.end_date) - moment(a.end_date),
  );

  const handleTimeCardView = (timecard_id) => {
    navigate(`/timecards/${timecard_id}/view-timecard`);
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppCard
      title='Timecards'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}
      className={'card-head-wrap-sm'}
      extra={
        isSupplier && (
          <Space align={'center'} wrap={'wrap'}>
            <Button
              className={'text-uppercase'}
              type={'primary'}
              size={'small'}
              onClick={() => navigate('/timecards/add-timecard')}>
              Add New Timecard
            </Button>
            <Button
              className={'text-uppercase'}
              type={'primary'}
              size={'small'}
              onClick={() => navigate('/timecards/add-blank-timecard')}>
              Add New Blank Timecard
            </Button>
          </Space>
        )
      }>
      <AppTable
        className={'column-valign-top'}
        columns={getColumns(handleTimeCardView)}
        dataSource={timecards}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          totalCount,
          onShowSizeChange,
          onChangePage,
        )}
      />
    </AppCard>
  );
};

export default TimeCards;
