import {StepTitle} from 'app/shared/components/AppSteps';

import {
  AccountManagerForm,
  AccountManagerView,
} from '../../CreateClientSupplierCommon/AccountManager';
import {
  AccountOwnerForm,
  AccountOwnerView,
} from '../../CreateClientSupplierCommon/AccountOwner';
import {
  BillingAddressForm,
  BillingAddressView,
} from '../../CreateClientSupplierCommon/BillingAddress';
import CreateAccount from '../../CreateClientSupplierCommon/CreateAccount';
import {
  PrimaryAddressForm,
  PrimaryAddressView,
} from '../../CreateClientSupplierCommon/PrimaryAddress';
import {CompanyInfoForm, CompanyInfoView} from './CompanyInformation';
import {MarketPlaceForm, MarketPlaceView} from './MarketPlaceFees';

export const getStepItems = (handleOnFinish) => {
  return [
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 0}
          onEditClick={props.goToStep(0)}
          title={'Company Information'}
          {...props}
        />
      ),
      subTitle: 'Provide basic details about this supplier',
      preview: ({state}) => <CompanyInfoView details={state?.company_info} />,
      description: ({goToStep, state, setState}) => (
        <CompanyInfoForm
          onContinue={goToStep(1)}
          details={state?.company_info}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 1}
          onEditClick={props.goToStep(1)}
          title={'Primary Address'}
          {...props}
        />
      ),
      subTitle: "Provide supplier's primary business address",
      preview: ({state}) => (
        <PrimaryAddressView details={state?.primary_address} />
      ),
      description: ({goToStep, state, setState}) => (
        <PrimaryAddressForm
          onContinue={goToStep(2)}
          details={state?.primary_address}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 2}
          onEditClick={props.goToStep(2)}
          title={'Billing Address'}
          {...props}
        />
      ),
      subTitle: "Provide supplier's billing address",
      preview: ({state}) => (
        <BillingAddressView details={state?.billing_address} />
      ),
      description: ({goToStep, state, setState}) => (
        <BillingAddressForm
          isRequiredPhone={false}
          onContinue={goToStep(3)}
          details={state?.billing_address}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 3}
          onEditClick={props.goToStep(3)}
          title={'Account Owner'}
          {...props}
        />
      ),
      subTitle: 'Create Account Owner user for this supplier',
      preview: ({state}) => <AccountOwnerView details={state?.account_owner} />,
      description: ({goToStep, state, setState}) => (
        <AccountOwnerForm
          onContinue={goToStep(4)}
          details={state?.account_owner}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 4}
          onEditClick={props.goToStep(4)}
          title={'Account Manager'}
          {...props}
        />
      ),
      subTitle: 'Assign Account Manager to manage this supplier',
      preview: ({state}) => (
        <AccountManagerView account_manager={state?.account_manager} />
      ),
      description: ({goToStep, state, setState}) => (
        <AccountManagerForm
          onContinue={goToStep(5)}
          account_manager={state?.account_manager}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => (
        <StepTitle
          isEdit={props.current > 5}
          onEditClick={props.goToStep(5)}
          title={'Marketplace Fees'}
          {...props}
        />
      ),
      subTitle:
        'List managed service provider fees to be paid by this supplier',
      preview: ({state}) => (
        <MarketPlaceView details={state?.marketplace_fees || []} />
      ),
      description: ({goToStep, state, setState}) => (
        <MarketPlaceForm
          onContinue={goToStep(6)}
          details={state?.marketplace_fees}
          setState={setState}
        />
      ),
    },
    {
      title: (props) => <StepTitle title={'Create'} {...props} />,
      subTitle: 'Create new supplier account',
      description: (
        <CreateAccount onFinish={handleOnFinish} accountType='Supplier' />
      ),
    },
  ];
};
