import {usePrevious} from 'app/hooks/usePrevious';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import PageQueryContextNew from './PageQueryContextNew';

const setQueryIndex = (query, index) => {
  const updatedQuery = query
    ? Object.entries(query).map(([key, value]) => {
        const splitKey = key.split('[');
        const newKey = `${splitKey[0]}[${index}][${splitKey[1]}`;
        return [newKey, value];
      })
    : {};
  return Object.fromEntries(updatedQuery);
};

const getElement = (filter, value) => {
  return filter.options.find((el) => el.value === value);
};

const getQueryParams = (filter, key, value) => {
  const hasQuery = filter.hasQuery;

  if (typeof value === 'string' || typeof value === 'number') {
    if (hasQuery) {
      const element = getElement(filter, value);
      return element.query;
    }

    const {query} = filter.filter
      ? filter.filter(value)
      : {query: {[key]: value}};
    return query;
  }

  if (Array.isArray(value)) {
    if (filter.filter) {
      return filter.filter(value).query;
    }
    return hasQuery
      ? value.map((val, index) => {
          const element = getElement(filter, val);
          return setQueryIndex(element?.query, index);
        })
      : {[key]: value};
  }

  if (moment.isMoment(value)) {
    return filter?.filter(value)?.query || undefined;
  }
};

const PageQueryProvider = ({
  children,
  category,
  queryParamKey = 'queryParams',
  pageParams = {},
  initialSimpleQueries = {},
  filterConfig,
}) => {
  const queryInit = useMemo(
    () => pageParams?.[category]?.[queryParamKey] || initialSimpleQueries,
    [pageParams, category, queryParamKey, initialSimpleQueries],
  );
  const currentPageParams = useMemo(
    () => pageParams?.[category] || {},
    [category, pageParams],
  );
  const [filterState, setFilterState] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const prevCategory = usePrevious(category);

  const resetFilter = useCallback(() => {
    setFilterState({});
  }, []);

  const removeFilter = useCallback((key) => {
    setFilterState((prev) => ({...prev, [key]: undefined}));
  }, []);

  useEffect(() => {
    if (prevCategory !== category) {
      resetFilter();
    }
  }, [category, prevCategory, resetFilter]);

  const handleSetFilter = useCallback((value) => setFilterState(value), []);

  const existFilterState = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filterState).filter(([, value]) => !!value),
      ),
    [filterState],
  );

  const uiData = useMemo(
    () =>
      existFilterState
        ? Object.entries(existFilterState).map(([key, value]) => ({
            key,
            label: filterConfig[key].label,
            value: moment.isMoment(value)
              ? moment(value).format('MM/DD/YYYY')
              : Array.isArray(value)
              ? filterConfig[key].filter
                ? filterConfig[key].filter(value).label
                : value.map(
                    (val) =>
                      filterConfig[key]?.options?.find(
                        (option) => option.value === val,
                      )?.label,
                  )
              : (typeof value === 'string' || typeof value === 'number') &&
                'options' in filterConfig[key]
              ? filterConfig[key]?.options?.find(
                  (option) => option.value === value,
                )?.label
              : value,
          }))
        : [],
    [existFilterState, filterConfig],
  );

  useEffect(() => {
    const filterEntries = Object.entries(existFilterState);

    let queryParams = {};

    filterEntries.forEach(([key, value]) => {
      const filter = filterConfig[key];
      const queries = getQueryParams(filter, key, value);

      if (Array.isArray(queries)) {
        queries.forEach((query) => {
          queryParams = {...queryParams, ...query};
        });
      } else if (typeof queries === 'object') {
        queryParams = {...queryParams, ...queries};
      }
    });

    setFilterQuery(queryParams);
  }, [existFilterState, filterConfig]);

  const contextValues = {
    allPagesParams: pageParams,
    currentPageParams,
    handleSetFilter,
    resetFilter,
    filterState,
    filterQuery,
    removeFilter,
    uiData,
    category,
    queryInit,
  };

  return (
    <PageQueryContextNew.Provider value={contextValues}>
      {children}
    </PageQueryContextNew.Provider>
  );
};

PageQueryProvider.propTypes = {
  category: PropTypes.string,
  queryParamKey: PropTypes.string,
  pageParams: PropTypes.object,
  initialSimpleQueries: PropTypes.object,
  children: PropTypes.node,
  filterConfig: PropTypes.object,
};

export default PageQueryProvider;
