import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const Standard = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.STANDARD_ENGAGEMENT],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.STANDARD_ENGAGEMENT],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='standard-engagement-terms'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center'>
                Standard Engagement Terms
              </h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta mt-3'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              Customers and Suppliers (each of them individually a “Party” and
              collectively the “Parties”) expressly agree to incorporate these
              Standard Engagement Terms (“SET“) into each Engagement. Parties
              may not, by agreement amongst themselves, alter the rights or
              responsibilities of Sourcer, or agree to any terms that would
              violate this SET nor the{' '}
              <Link to='/'>Sourcer Terms of Service</Link>. Neither Sourcer, nor
              any affiliate of Sourcer is a party to any agreement (including
              this SET) by and between the Parties.
            </p>

            <h4>1. DEFINITIONS.</h4>

            <p>The following capitalized terms have the following meanings:</p>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                a. “Background Technology” means all Inventions developed by
                Supplier other than in the course of providing Supplier Services
                to Customer under the Engagement and all Inventions that
                Supplier incorporates into Work Product.
              </li>

              <li>
                b. “Customer” means any entity or organization that enters into
                an Engagement to obtain Supplier Services from a Supplier.
              </li>

              <li>
                c. “Customer Deliverables” means requests, intellectual
                property, and any other information or materials that a Supplier
                receives from a Customer to perform Supplier Services.
              </li>

              <li>
                d. “Customer Materials” means requests, intellectual property,
                and any other information or materials that Customer provides to
                Supplier for Supplier to perform Supplier Services.
              </li>

              <li>
                e. “Confidential Information” means any information, in whatever
                form or media, of a Disclosing Party that is provided or
                disclosed to the Receiving Party including orally, in writing,
                or in other form, and whether identified as confidential or
                proprietary at the time of disclosure or which should be
                understood to be confidential by the nature of the information
                or the circumstances of the disclosure. Confidential Information
                will include the following categories of information without
                limitation: products, network configurations, network
                architecture, financial and operational information, and other
                matters relating to the operation of the Parties’ business,
                including information relating to actual or potential customers
                and customer lists, customer usage or requirements, business and
                customer usage forecasts and projections, accounting, finance or
                tax information, pricing information, business plans,
                strategies, technology, documentation, methodologies, general
                know how, technical information, software, equipment, details of
                deliverables or services rendered under an Engagement, and any
                amendments thereto, any information exchanged between the
                Parties pursuant to a nondisclosure agreement, and all
                information and materials relating to third party vendors,
                systems integrators, or consultants.
                <br />
                <br />
                Confidential Information will not include any information which:
                <ul
                  style={{
                    listStyle: 'none',
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>
                    i. the Disclosing Party confirms in writing is free of such
                    restrictions;
                  </li>
                  <li>
                    ii. at the time of disclosure was already known or becomes
                    lawfully known (independently of disclosure by the
                    Disclosing Party) to the Receiving Party from a source other
                    than one having an obligation of confidentiality to the
                    Disclosing Party;
                  </li>
                  <li>
                    iii. becomes publicly known or otherwise ceases to be secret
                    or confidential, except through a breach of this Engagement;
                    or
                  </li>
                  <li>
                    iv. is independently developed by the Receiving Party
                    without use of or reference to the Confidential Information.
                  </li>
                </ul>
              </li>

              <li>
                f. “Disclosing Party” means the Party disclosing Confidential
                Information.
              </li>

              <li>
                g. “Engagement” means an engagement for Supplier Services that a
                Supplier provides to a Customer under a written engagement per
                the Marketplace. Sourcer is not a party to an Engagement.
              </li>

              <li>
                h. “Expense Reports” are online forms submitted by the Supplier
                via the Marketplace detailing pre-approved expenses on a per
                Staff person basis.
              </li>

              <li>
                i. “Invoice” means a time and expense invoice, prepared by
                Sourcer based on Customer approved Timecards and Expense
                Reports. Sourcer thereafter submits the completed Invoice to
                Customer, through the Marketplace for Customer review, approval,
                and payment.
              </li>

              <li>
                j. “Intellectual Property Rights” means all patent rights,
                copyright rights, mask work rights, moral rights, rights of
                publicity, trademark, trade dress and service mark rights,
                goodwill, trade secret rights and other intellectual property
                rights as may now exist or hereafter come into existence, and
                all applications therefore and registrations, renewals and
                extensions thereof, under the laws of any state, country,
                territory or other jurisdiction.
              </li>

              <li>
                k. “Invention” means any ideas, concepts, information,
                materials, processes, data, programs, know-how, improvements,
                discoveries, developments, designs, artwork, formulae, other
                copyrightable works, and techniques and all Intellectual
                Property Rights therein.
              </li>

              <li>
                l. “Receiving Party” means the Party receiving Confidential
                Information.
              </li>

              <li>
                m. “Supplier” means any entity or organization that enters into
                an Engagement to perform Supplier Services for a Customer.
              </li>

              <li>
                n. “Supplier Deliverables” means requests, intellectual
                property, and any other information or materials that a Customer
                receives from a Supplier for a particular Engagement.
              </li>

              <li>
                o. “Supplier Fees” means an amount equal to the number of hours
                recorded by Supplier in any Timecard, multiplied by the hourly
                rate set by the Supplier.
              </li>

              <li>
                p. “Supplier Services” means all services performed for or
                delivered to Customer by Supplier.
              </li>

              <li>
                q. “Timecards” are online forms submitted by Suppliers via the
                Marketplace detailing the hours worked on a per Staff person
                basis.
              </li>

              <li>
                r. “Work Product” means any tangible or intangible results or
                deliverables that Supplier agrees to create for, or actually
                delivers to, Customer as a result of performing the Supplier
                Services, including, but not limited to, configurations,
                computer programs, or other information, or customized hardware,
                and any intellectual property developed in connection therewith.
              </li>
            </ol>

            <h4>2. PARTIES.</h4>

            <p>
              Customer and Supplier identified on the Marketplace are the
              parties to the Engagement. Sourcer is not a party to the
              Engagement.
            </p>

            <h4>3. SERVICES.</h4>

            <p>
              Customer and Supplier agree that Supplier is performing services
              as an independent contractor and that Supplier is not an employee
              or agent of Customer. Supplier will perform the Supplier Services
              in a professional and workmanlike manner and will timely deliver
              any agreed upon Work Product. The manner and means of performing
              the Supplier Services, including the tools to be used in
              completing the work, will be determined and controlled by Supplier
              except as expressly limited by Customer’s specifications in the
              Engagement.
            </p>

            <p>
              The terms concerning the Supplier Services to be performed under
              the Engagement described on the Marketplace form part of the
              Engagement. The Parties agree that once accepted, the terms of the
              Engagement cannot be modified by a Party without obtaining the
              consent of the other before making changes to the Engagement,
              including by adding additional or different milestones, closing
              the Engagement, or making other changes to the Engagement on the
              Marketplace. If the consent of the other Party is not first
              obtained, the other party may reject such changes by terminating
              the Engagement or accept such changes by continuing to work on the
              Engagement.
            </p>

            <h4>4. RESPONSIBILITY FOR EMPLOYEES AND SUBCONTRACTORS.</h4>

            <p>
              If Supplier subcontracts with or employs third parties to perform
              Supplier Services for any Engagement, Supplier represents and
              warrants that it does so as a legally recognized entity or person
              and in compliance with all applicable laws and regulations.
              Further, at all times Supplier remains responsible for the quality
              of the services and represents and warrants that Supplier has
              entered into agreements with any such employees and subcontractors
              on confidentiality and intellectual property at least as strong as
              those in these Standard Engagement Terms.
            </p>

            <h4>5. BILLING.</h4>

            <p>
              Supplier agrees to submit accurate Timecards and Expense Reports
              to Sourcer.
            </p>

            <h4>6. TERMINATION OF AN ENGAGEMENT.</h4>

            <p>
              Either Customer or Supplier has the right to terminate the
              Engagement after providing any agreed-upon notice, or immediately
              on the end date specified in the Engagement terms and/or upon
              completion of the Supplier Services, in the event of a material
              breach, or with the consent of the other party. Except as required
              by law or as otherwise may be agreed to by the Parties, Customer
              remains obligated to pay the Supplier Fees for any Supplier
              Services provided prior to termination of the Engagement.
            </p>

            <h4>7. REPRESENTATIONS AND WARRANTIES.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                a. Each Party hereto represents and warrants to the other Party
                that (i) it is a validly existing entity duly authorized and
                licensed to do business in each jurisdiction where its services
                under this SET are to be performed, (ii) the execution and
                delivery of this SET, and the performance of its obligations
                hereunder, by such Party have been duly authorized and that the
                SET is valid and legally binding on such Party and enforceable
                in accordance with its terms, and (iii) the execution and
                delivery of this SET, and the performance of its obligations
                hereunder, by such Party does not, and will not, conflict with
                or result in breach of any agreement, indenture or other
                instrument binding on such Party.
              </li>

              <li>
                b. Supplier (with respect to itself and any Staff providing
                services on its behalf) expressly warrants (expressly including
                for the benefit of the Customer) that the services to be
                performed by Supplier for any Customer shall be performed in a
                workmanlike manner, subject to Customer’s reasonable
                requirements. Supplier further represents that all work assigned
                by a Customer will be performed in a manner consistent with that
                level of care and skill ordinarily exercised by other providers
                of similar services under similar circumstances at the time
                services are provided.
              </li>

              <li>
                c. If any Supplier Staff is determined to be unacceptable for
                any reason within a period of five (5) business days following
                the date that such Supplier Staff first provides services to
                such Customer (in connection with any single Engagement), then
                such Customer may terminate the services of such Supplier Staff
                and not be obligated to pay any amount to Supplier for any
                services provided by such Supplier Staff. Upon such termination
                of Supplier Staff, Sourcer, in its sole discretion, may provide
                such Supplier with a reasonable time period (determined by the
                circumstances existing at the time of the termination) to
                replace the terminated Staff; provided that Customer does not
                prohibit or restrict such replacement by Supplier.
              </li>
            </ol>

            <h4>8. INTELLECTUAL PROPERTY RIGHTS.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                <strong>a. ORIGINALITY.</strong> Unless otherwise expressly
                agreed to in any applicable Engagement, all materials produced
                by Supplier in performance of any work for Customer shall be of
                original design and development by the Supplier and will be
                specifically developed for fulfillment of such Engagement. If
                Supplier intends to use or incorporate any component(s) of a
                system already in existence, Supplier shall first notify
                Customer, who, based upon whatever investigation Customer may
                elect to make, may direct the Supplier not to use any of such
                component(s). If Customer, in its sole discretion, authorizes
                the use of such component(s), Supplier shall use such
                component(s) at Supplier’s sole expense, after obtaining and
                providing the Customer with a copy of the written consent of the
                party owning such component(s). In any event any such component
                shall be warranted as specified in any applicable agreement
                between Customer and Supplier, and Supplier will arrange to
                transfer to Customer a perpetual license to such component.
              </li>

              <li>
                <strong>b. THIRD-PARTY RIGHTS.</strong> Supplier represents and
                warrants that Supplier will not incorporate or use the materials
                of any third party including those of any other Customer or any
                employer, in performing the Supplier Services that are:
                <ul
                  style={{
                    listStyle: 'none',
                    listStylePosition: 'outside',
                    marginLeft: '3em',
                  }}>
                  <li>i. not generally available for use by the public,</li>
                  <li>
                    ii. have not been legally transferred to the Customer, or
                  </li>
                  <li>iii. have not been expressly approved by Customer.</li>
                </ul>
              </li>

              <li>
                <strong>c. BACKGROUND TECHNOLOGY.</strong> Supplier will
                disclose in the Engagement terms any Background Technology which
                Supplier proposes to incorporate into Work Product or upon which
                use or distribution of the Work Product will depend. If Supplier
                discloses no Background Technology, Supplier warrants that it
                will not incorporate any Background Technology into Work Product
                provided pursuant thereto. Supplier will separately provide,
                with each delivery of Work Product to Customer, a bill of
                materials that identifies all Background Technology and other
                third-party materials that have been incorporated into the Work
                Product and provides, for each item of Background Technology
                identified, (a) the name and any associated version number, (b)
                the applicable license or licensing terms, (c) whether the item
                has been modified by Supplier, and (d) how the item has been
                incorporated into, is used by, or is relied upon by the Work
                Product. Notwithstanding the foregoing, unless otherwise agreed
                in the Engagement terms, Supplier agrees that it will not
                incorporate into Work Product or otherwise deliver to Customer
                any software code for which the use or distribution of the code
                will create (or purport to create) obligations for Customer to
                grant any rights or immunities under Customer intellectual
                property to a third-party, including without limitation any
                obligation that the Work Product or Customer software combined
                with, derived from, or distributed with such Work Product (x) be
                disclosed or distributed in source code form, (y) be licensed
                for the purpose of making derivative works, or (z) be
                redistributable at no charge.
              </li>

              <li>
                <strong>d. CUSTOMER MATERIALS.</strong> Customer grants Supplier
                a limited, non-exclusive, revocable (at any time, at Customer’s
                sole discretion) right to use the Customer Materials as
                necessary solely for the performance of the Supplier Services
                under the applicable Engagement. Customer reserves all other
                rights and interest, including, without limitation, all
                Intellectual Property Rights, in and to the Customer Materials.
                Upon completion or termination of the Engagement, or upon
                Customer’s written request, Supplier will immediately return all
                Customer Materials to Customer and further agrees to destroy all
                copies of Customer Materials and Deliverables (except for
                Background Technology as permitted by the Engagement) contained
                in or on Supplier’s premises, systems, or any other equipment or
                location otherwise under Supplier’s control. Within ten days of
                such request from Customer, Supplier agrees to provide written
                certification to Customer that Supplier has returned or
                destroyed all Customer Materials and Work Product as provided in
                this subsection.
              </li>

              <li>
                <strong>
                  e. OWNERSHIP OF WORK PRODUCT AND INTELLECTUAL PROPERTY.
                </strong>
                Upon Supplier’s receipt of full payment from Customer, the Work
                Product (except for any Background Technology), including
                without limitation all Intellectual Property Rights in the Work
                Product (except for any Background Technology), will be the sole
                and exclusive property of Customer, and Customer will be deemed
                to be the author thereof. If Supplier has any Intellectual
                Property Rights to the Work Product that are not owned by
                Customer upon Supplier’s receipt of payment from Customer,
                Supplier hereby automatically irrevocably assigns to Customer
                all right, title and interest worldwide in and to such
                Intellectual Property Rights. Except as set forth above,
                Supplier retains no rights to use, and will not challenge the
                validity of Customer’s ownership in, such Intellectual Property
                Rights. Supplier hereby waives any moral rights, attribution
                rights, integrity, disclosure and withdrawal or inalienable
                rights under applicable law in and to the Work Product. If
                payment is made only for partial delivery of Work Product, the
                assignment described herein applies only to the portion of Work
                Product delivered and paid for.
              </li>

              <li>
                <strong>f. LICENSE TO BACKGROUND TECHNOLOGY.</strong> Upon
                Supplier’s receipt of full payment from Customer for delivery of
                Work Product, Supplier hereby automatically grants to Customer
                an exclusive, perpetual, fully-paid and royalty-free,
                irrevocable and worldwide right, with rights to sublicense
                through multiple levels of sublicensees, to reproduce, make
                derivative works of, distribute, publicly perform, and publicly
                display in any form or medium, whether now known or later
                developed, make, have made, use, sell, import, offer for sale,
                and exercise any and all present or future rights in the
                Background Technology incorporated in Work Product delivered for
                that payment. If payment is made only for partial delivery of
                Work Product, the license described herein applies only to the
                portion of Work Product delivered and paid for.
              </li>

              <li>
                <strong>g. LICENSE TO OR WAIVER OF OTHER RIGHTS.</strong> If
                Supplier has any right to the Work Product, including without
                limitation any Intellectual Property Right, that cannot be
                assigned to Customer by Supplier, Supplier hereby automatically,
                upon Supplier’s receipt of full payment from Customer,
                unconditionally and irrevocably grants to Customer during the
                term of such rights, an exclusive, even as to Customer’s
                affiliates, irrevocable, perpetual, worldwide, fully-paid and
                royalty-free license to such rights, with rights to sublicense
                through multiple levels of sublicensees, to reproduce, make
                derivative works of, distribute, publicly perform and publicly
                display in any form or medium, whether now known or later
                developed, make, use, sell, import, offer for sale and exercise
                any and all such rights. If Supplier has any rights to such Work
                Product that cannot be assigned or licensed, Supplier hereby
                automatically, upon Supplier’s receipt of payment from Customer,
                unconditionally and irrevocably waives the enforcement of such
                rights, and all claims and causes of action of any kind against
                Customer or related to Customer’s customers, with respect to
                such rights, and will, at Customer’s request and expense,
                consent to and join in any action to enforce such rights. If
                payment is made only for partial delivery of Work Product, the
                grant described herein applies only to the portion of Work
                Product delivered.
              </li>

              <li>
                <strong>h. ASSISTANCE.</strong> Supplier will assist Customer in
                every way, including by signing any documents or instruments
                reasonably required, both during and after the term of the
                Engagement, to obtain and enforce Intellectual Property Rights
                relating to Work Product in all countries. In the event Customer
                is unable, after reasonable effort, to secure Supplier’s
                signature on any document needed in connection with the
                foregoing, Supplier hereby designates and appoints Customer and
                its duly authorized officers and agents as its agent and
                attorney in fact to act on its behalf to further the purposes of
                this Section with the same legal force and effect as if executed
                by Supplier.
              </li>
            </ol>

            <h4>9. MUTUAL NON-SOLICITATION.</h4>

            <p>
              Subject to applicable local laws, during the Term of any
              Engagement, and for a period of six (6) months thereafter, neither
              party will solicit any employees or subcontractors of the other
              party or its affiliates who, within six (6) months prior to such
              solicitation: (a) directly performed under such Engagement, (b)
              had substantial contact with the hiring party in relation to the
              Engagement, or (c) the hiring party became aware of due to, or
              derived from information learned through the performance of the
              Engagement. For this purpose, {'"solicitation"'} does not include
              contact resulting from indirect means such as public
              advertisement, placement firm searches or similar means not
              directed specifically at the employee and/or subcontractors to
              which the employee and/or subcontractors responds on his or her
              own initiative. Notwithstanding the foregoing, either party may at
              any time, directly or indirectly, solicit and hire any employee
              and/or subcontractors of the other party if such employee and/or
              subcontractor did not resign but was terminated by the other
              party.
            </p>

            <h4>10. CONFIDENTIAL INFORMATION.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                <strong>a. Treatment of Confidential Information</strong>
                <p>
                  The Receiving Party agrees that it (and each of its
                  Affiliates, employees, agents, contractors, or Private
                  Entrepreneurs) will not use the Confidential Information of
                  the Disclosing Party in any way except for the purpose of
                  performing its obligations under the Engagement. The Receiving
                  Party (and each of its affiliates, and their respective
                  directors, officers, employees, agents, contractors, or
                  Private Entrepreneurs) will take reasonable precautions to
                  protect the confidentiality of the Confidential Information
                  and will use the same degree of care to protect the Disclosing
                  Party’s Confidential Information that it uses to protect its
                  own Confidential Information (but in no event less than
                  reasonable care).
                </p>

                <p>
                  Notwithstanding the foregoing, the Receiving Party may
                  disclose Disclosing Party’s Confidential Information to its
                  affiliates, employees, agents, or contractors, who have a need
                  to know such information in order to fulfill its obligations
                  under the Engagement, provided that all such parties are bound
                  by non-disclosure agreements containing confidentiality
                  obligations substantially equivalent to those contained
                  herein. The Receiving Party will be liable for any act or
                  omission of its affiliates, employees, agents, or contractors,
                  as if such were a party to this Engagement. The Receiving
                  Party (and each of its affiliates, employees, agents, or
                  contractors) will not reverse engineer, disassemble or
                  decompile any prototypes, software or other tangible objects
                  which embody the other Party’s Confidential Information.
                </p>

                <p>
                  Further, either Party may disclose Confidential Information to
                  the extent required by law, by subpoena, judicial or
                  administrative order, or otherwise, provided that, if legally
                  permitted to do so, the Receiving Party gives the Disclosing
                  Party prior written notice of such required disclosure to
                  allow the Disclosing Party a reasonable opportunity to object
                  to and to take legal action to prevent such disclosure, and
                  will furthermore provide full cooperation and assistance in
                  seeking to obtain such protection, all at the Disclosing
                  Party’s cost and expense.
                </p>
              </li>

              <li>
                <strong>b. Term of Confidentiality Obligations</strong>
                <p>
                  The Disclosing Party agrees that the confidentiality
                  obligations of this section will apply during the Term and for
                  three (3) years following the termination or expiration date
                  of this Engagement. The terms and conditions, but not the
                  existence, of this Engagement, in whole or part, constitute
                  Confidential Information; provided, that this Engagement may
                  be disclosed as necessary in connection with a financing or
                  change of control transaction. Notwithstanding the foregoing,
                  any trade secrets disclosed under this Engagement will be held
                  in confidence by the Receiving Party for: (i) as long as the
                  Confidential Information remains Disclosing Party’s trade
                  secret under applicable law; or (ii) until Confidential
                  Information falls under one of the exceptions to the
                  confidentiality obligations (see Definition).
                </p>
              </li>

              <li>
                <strong>c. Return or Destruction of Records</strong>
                <p>
                  Except as otherwise provided in this Engagement, promptly upon
                  a request by the Disclosing Party, the Receiving Party will
                  destroy or return to the Disclosing Party all Confidential
                  Information of the Disclosing Party, and all documents or
                  media containing any such Confidential Information and any
                  copies or extracts thereof, except that the Receiving Party
                  may retain one (1) copy solely for record-keeping purposes.
                </p>
              </li>

              <li>
                <strong>d. Unauthorized Disclosure</strong>
                <p>
                  If the Receiving Party becomes aware of any unauthorized use
                  or disclosure of the Confidential Information of the
                  Disclosing Party, the Receiving Party will promptly notify the
                  Disclosing Party of all facts known to it concerning such
                  unauthorized use or disclosure. Each Party acknowledges and
                  agrees that due to the unique nature of the Disclosing Party’s
                  Confidential Information, there may be no adequate remedy at
                  law for any breach of its confidentiality obligations
                  hereunder, which breach may result in irreparable harm to the
                  Disclosing Party, and therefore, that upon any such breach or
                  any threat thereof, the Disclosing Party will be entitled to
                  seek appropriate equitable relief in addition to whatever
                  remedies it might have at law.
                </p>
              </li>
            </ol>

            <h4>11. INDEMNITY.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                <p>
                  {`a.Supplier hereby agrees to indemnify, defend and hold
                    harmless Customer, and its employees, proprietors, partners,
                    representatives, shareholders, directors, servants, attorneys,
                    predecessors, successors and assigns (hereinafter collectively
                    referred to as "Indemnitees"), from any and all Losses that
                    result from (i) an assertion or claim of Intellectual Property
                    Rights infringement based on the use of deliverables,
                    equipment, programming, materials, or other resources supplied
                    or controlled by Supplier, (ii) any delay or failure of
                    Supplier to pay its personnel or Subcontractors amounts due
                    for their services, (iii) Supplier’s failure to comply with
                    the terms of this SET, (iv) any finding that Customer or
                    Sourcer was or is a deemed a statutory “employer” or
                    “co-employer” of any Staff or any Staff provided by Supplier,
                    (v) damage to personal property or injury or death that
                    results from the negligence or willful misconduct of Supplier
                    (including without limitation its Staff, agents, employees, or
                    Subcontractors), or (vi) any taxes or associated liabilities,
                    expenses or penalties incurred or paid by Customer in
                    connection with applicable international, federal, state and
                    local personal income taxes, withholding obligations, and
                    other payroll taxes payable with respect to the compensation
                    of Supplier, its employees, agents and/or contractors.`}
                </p>
              </li>

              <li>
                <p>
                  b. This indemnification applies notwithstanding the fact that
                  third parties or any of the Indemnitees may be partially
                  responsible for the situation giving rise to the claim.
                  However, if any of the Indemnitees or third parties are
                  partially responsible for the events giving rise to the claim,
                  Supplier indemnification hereunder shall apply only to the
                  extent that Supplier contributed to the Losses.
                </p>
              </li>

              <li>
                <p>
                  c. Supplier shall, at its expense, be entitled to and shall
                  have the duty to participate in the defense of any suit
                  against the Indemnitees. No settlement or compromise of any
                  claim, loss or damage entered into by Indemnitees shall be
                  binding upon Supplier unless approved in writing by Supplier.
                  No settlement or compromise of any claim, loss or damage
                  entered into by Supplier shall be binding upon Indemnitees
                  unless approved in writing by Indemnitees.
                </p>
              </li>

              <li>
                <p>
                  d. Any Indemnitee shall promptly tender the defense of any
                  claim for which Supplier shall indemnify Indemnitees promptly,
                  provided that the Indemnitee may be represented by counsel of
                  its own choosing, at its own expense.
                </p>
              </li>
            </ol>

            <h4>12. LIMITATION OF LIABILITY.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                <p>
                  a. Notwithstanding anything to the contrary in this SET,
                  neither Party hereto shall be liable to the other Party for
                  indirect, special or consequential damages.
                </p>
              </li>

              <li>
                <p>
                  b. The foregoing limitation of liability shall not apply to
                  Supplier’s liability for indemnification obligations as
                  specified under Section 11 (“Indemnity”).
                </p>
              </li>

              <li>
                <p>
                  c. Except as provided in Subsection (b) above, Supplier’s
                  total aggregate liability to Customer with respect to any
                  Losses arising out of or relating to this SET or any
                  individual Engagement(s) (including any breach of this SET or
                  any individual Engagement(s)) or Supplier’s performance
                  hereunder or thereunder shall be limited to the sum of (A) the
                  aggregate payments received by Supplier for services rendered
                  pursuant to this SET and any individual Engagement(s)
                  thereunder, and (B) any insurance proceeds on policies owned
                  or controlled by Supplier and all participating Subcontractors
                  payable with respect to such Losses.
                </p>
              </li>

              <li>
                <p>
                  d. The total aggregate liability of Customer to Supplier with
                  respect to any Losses arising out of or relating to this SET
                  or any individual Engagement(s) (including any breach of this
                  SET and any individual Engagement(s)) or Supplier’s
                  performance hereunder or thereunder shall be limited to the
                  aggregate payments received by Supplier for services rendered
                  pursuant to this SET and any Engagement(s) thereunder.
                </p>
              </li>
            </ol>

            <h4>13. GOVERNING LAW.</h4>

            <p>
              This SET and any dispute or claim including non-contractual
              disputes or claims arising out of or in connection with it or its
              subject matter or formation shall be governed by and construed in
              accordance with the laws of the State of Delaware, USA, as they
              presently exist or may hereafter be amended, without regard to
              principles of conflict of laws. The United Nations Convention on
              Contracts for the International Sale of Goods shall not apply to
              this SET.
            </p>

            <h4>14. MEDIATION AND DISPUTE RESOLUTION.</h4>

            <ol
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                <p>
                  a. If a dispute arises from or relates to this SET or the
                  breach thereof, the Parties shall first communicate directly
                  with each other for a period of ten (10) business days.
                </p>
              </li>

              <li>
                <p>
                  b. If the Parties are unable to resolve the matter informally,
                  they may request assistance from Sourcer via the applicable
                  Account Manager.
                </p>
              </li>

              <li>
                <p>
                  c. If the Parties remain unable to resolve the matter, then
                  the Account Manager may organize a conference call with
                  executive leadership of each of the Parties, at which the
                  Account Manager will attempt to mediate a settlement.
                </p>
              </li>

              <li>
                <p>
                  d. If the dispute cannot be settled through direct
                  discussions, the parties agree to endeavor first to settle the
                  dispute by mediation administered by the American Arbitration
                  Association under its Commercial Mediation Procedures before
                  resorting to arbitration. The parties further agree that any
                  unresolved controversy or claim arising out of or relating to
                  this contract, or breach thereof, shall be settled by
                  arbitration administered by the American Arbitration
                  Association in accordance with its Commercial Arbitration
                  Rules and judgment on the award rendered by the arbitrator(s)
                  may be entered in any court having jurisdiction thereof.
                </p>
              </li>

              <li>
                <p>
                  e. Claims shall be heard by a single arbitrator, unless the
                  claim amount exceeds One Hundred Thousand Dollars ($100,000),
                  in which case the dispute shall be heard by a panel of three
                  (3) arbitrators. Within fifteen (15) days after the
                  commencement of arbitration, each party shall select one (1)
                  person to act as arbitrator and the two (2) selected shall
                  select a third arbitrator within ten days of their
                  appointment. If the arbitrators selected by the parties are
                  unable or fail to agree upon the third arbitrator, the third
                  arbitrator shall be selected by the American Arbitration
                  Association. The place of arbitration shall be Dover,
                  Delaware, USA. The arbitration shall be governed by the laws
                  of the State of Delaware. If the dispute is less than Twenty
                  Five Thousand Dollars ($25,000) there shall be no discovery
                  other than the exchange of documents. If the dispute is over
                  Twenty Five Thousand ($25,000), discovery shall consist of no
                  more than two (2) depositions of up to four (4) hours each in
                  duration.{' '}
                </p>
              </li>

              <li>
                <p>
                  {`f. In making determinations regarding the scope of exchange of
                  electronic information, the arbitrator(s) and the parties
                  agree to be guided by The Sedona Principles, Third Edition:
                  Best Practices, Recommendations & Principles for Addressing
                  Electronic Document Production. The arbitration will be based
                  on the submission of documents and there shall be no in-person
                  or oral hearing. The award shall be made within four (4)
                  months of the filing of the notice of intention to arbitrate
                  (demand), and the arbitrator(s) shall agree to comply with
                  this schedule before accepting appointment. However, this time
                  limit may be extended by the arbitrator for good cause shown,
                  or by mutual agreement of the parties. The arbitrators will
                  have no authority to award punitive or other damages not
                  measured by the prevailing party's actual damages, except as
                  may be required by statute. The arbitrator(s) shall not award
                  consequential damages in any arbitration initiated under this
                  section. Pursuant to the Commercial Arbitration Rules, the
                  arbitrators will have the authority to allocate the costs of
                  the arbitration process among the parties, but will only have
                  the authority to allocate attorneys' fees if a particular law
                  permits them to do so. The award of the arbitrators shall be
                  accompanied by a reasoned opinion. Except as may be required
                  by law, neither a party nor an arbitrator may disclose the
                  existence, content, or results of any arbitration hereunder
                  without the prior written consent of both parties. The parties
                  agree that failure or refusal of a party to pay its required
                  share of the deposits for arbitrator compensation or
                  administrative charges shall constitute a waiver by that party
                  to present evidence or cross-examine witnesses. In such event,
                  the other party shall be required to present evidence and
                  legal argument as the arbitrator(s) may require for the making
                  of an award. Such waiver shall not allow for a default
                  judgment against the non-paying party in the absence of
                  evidence presented as provided for above.`}
                </p>
              </li>
            </ol>
          </div>
        </div>
      </article>
    </div>
  );
};

export default Standard;
