import AppCard from '@wieldy/components/AppCard';
import AppFilterWrapper from '@wieldy/components/AppFilterWrapper';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  List,
  notification,
  Typography,
} from 'antd';
import usePageQuery from 'app/hooks/usePageQuery';
import useStep from 'app/hooks/useStep';
import FilterResults from 'app/shared/components/FIlter/FilterResults';
import {getName} from 'app/utils/helpers/GetName';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {RiArrowDropRightLine} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';

import {useFirstRender} from '../../../hooks/useFirstRender';
import FilterButton from '../FilterButton';

// import usePageQuery from '../../hooks/usePageQuery';
// import useStep from '../../hooks/useStep';
// import FilterButton from '../../shared/buttons/FilterButton';
// import FilterResults from '../common/FIlter/FilterResults';

const CreateList = ({
  isSubmission = false,
  totalCount,
  filter,
  actionCreator,
  items,
  handleCurrentItem,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  title,
  currentItem,
  isLoading,
  isCalcItems = false,
  limitError = 'Max 10 candidates per submission',
}) => {
  const {targetResource} = useSelector(({inquiry}) => inquiry);
  const [openFilter, setOpenFilter] = useState(false);
  const [itemsIds, setItemsIds] = useState([]);
  const {nextStep} = useStep();
  const {handleSetFilter, resetFilter, filterState} = usePageQuery();
  const dispatch = useDispatch();

  const existingLength = items?.filter((el) => el.isExist).length;

  const ableLength = items.length - existingLength;
  const listRef = useRef();
  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (items?.length && isFirstRender) {
      // const ul = listRef.current.querySelector('.ant-card');
      // const li = ul.children[ul.children.length - 1];

      const ul = listRef.current.parentElement;
      ul.scrollIntoView({block: 'center'});
    }
  }, [isFirstRender, items, itemsIds.length]);

  useEffect(() => {
    if (isFirstRender && targetResource) {
      setItemsIds([targetResource]);
    }
  }, [targetResource, isFirstRender]);

  const handleApplyFilter = React.useCallback(
    (form) => {
      handleSetFilter(form);
      setOpenFilter(false);
      setItemsIds([]);
    },
    [handleSetFilter],
  );

  const handleApplyReset = React.useCallback(() => {
    setItemsIds([]);
    resetFilter();
  }, [resetFilter]);

  const toggleSelectItem = (event, itemId, remove) => {
    event.stopPropagation();
    if (!remove && isCalcItems && itemsIds.length >= 10) {
      notification.error({message: limitError});
    } else {
      if (remove) {
        setItemsIds(itemsIds.filter((id) => id !== itemId));
      } else {
        setItemsIds([...itemsIds, itemId]);
      }
    }
  };

  const onClickItem = (event, supplier) => {
    event.stopPropagation();
    handleCurrentItem(supplier);
  };

  const handleContinue = () => {
    const pickedSuppliers = items.filter((el) => itemsIds.includes(el.id));

    if (isCalcItems) {
      if (pickedSuppliers.length <= 10) {
        dispatch(actionCreator(pickedSuppliers));
        nextStep();
      } else {
        notification.error({message: limitError});
      }
    } else {
      dispatch(actionCreator(pickedSuppliers));
      nextStep();
    }
  };

  const handleLoad = () => {
    fetchNextPage();
  };

  return (
    <>
      <Col xs={24}>
        <FilterResults />
        <AppFilterWrapper
          title={`Filter ${title}`}
          open={openFilter}
          onApply={handleApplyFilter}
          onReset={handleApplyReset}
          initialValues={filterState}
          style={{marginBottom: 0}}
          onClose={() => setOpenFilter(false)}>
          {filter}
        </AppFilterWrapper>
      </Col>
      <Col xs={24} lg={8} style={{height: 'fit-content', position: 'relative'}}>
        <AppCard
          style={{overflow: 'hidden'}}
          className='relative'
          title={
            <React.Fragment>
              {title}
              {!!totalCount && (
                <AppTag size={'small'} className={'ml-2'}>
                  {totalCount}
                </AppTag>
              )}
            </React.Fragment>
          }
          extra={
            <FilterButton onClick={() => setOpenFilter(!openFilter)} enabled />
          }
          headStyle={{borderBottom: 'none'}}
          bodyStyle={{
            paddingTop: 5,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div ref={listRef}>
            <List
              loading={isLoading}
              style={{margin: '0 -16px', height: 400, overflow: 'auto'}}
              className='list-menu'
              itemLayout='horizontal'
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`No ${title}`}
                  />
                ),
              }}
              dataSource={items}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  className={clsx({
                    active: currentItem?.id === item.id,
                  })}
                  onClick={(event) => onClickItem(event, item)}>
                  <List.Item.Meta
                    avatar={
                      <Checkbox
                        disabled={
                          item.isDisable ||
                          item.isExist ||
                          (isSubmission &&
                            (!item.employment_history?.length ||
                              !item.skill_tags?.data?.length))
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={(event) =>
                          toggleSelectItem(
                            event,
                            item.id,
                            itemsIds.includes(item.id),
                          )
                        }
                        checked={itemsIds.includes(item.id) || item.isExist}
                      />
                    }
                    title={getName(item)}
                  />
                  <RiArrowDropRightLine fontSize={24} />
                </List.Item>
              )}
            />
          </div>

          <div style={{paddingTop: 20, marginBottom: 10}}>
            <Button
              disabled={!hasNextPage || !items.length}
              onClick={handleLoad}>
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
          <div className='mt-auto relative' style={{paddingTop: 36}}>
            {!!itemsIds.length && (
              <Typography.Paragraph className='absolute left-0 top-0'>
                {itemsIds.length} selected out of {ableLength} available{' '}
                {title.toLowerCase()}
              </Typography.Paragraph>
            )}
            <Button
              disabled={!itemsIds?.length}
              type='primary'
              onClick={handleContinue}>
              Continue
            </Button>
          </div>
        </AppCard>
      </Col>
    </>
  );
};

CreateList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  currentItem: PropTypes.object,
  handleCurrentItem: PropTypes.func,
  totalCount: PropTypes.number,
  filter: PropTypes.node,
  actionCreator: PropTypes.func,
  hasNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCalcItems: PropTypes.bool,
  limitError: PropTypes.string,
  isSubmission: PropTypes.bool,
};

export default CreateList;
