import {DATE_FORMATS} from 'app/config/app';
import {COUNTRIES, ENGAGEMENT_ALL_STATUSES, US_STATES} from 'app/constants';
import CompanyHelper from 'app/utils/CompanyHelper';

import BaseModal from './BaseModal';

class CompanyModal extends BaseModal {
  constructor(company) {
    super(company);
    this.id = company?.id;
    this.hid = company?.hid ?? null;
    this.name = company?.name ?? null;
    this.status = company?.status ?? null;
    this.statusName = this.status
      ? ENGAGEMENT_ALL_STATUSES[this.status]
      : 'N/A';
    this.statusColor = 'success';
    this.accountManager = company?.account_manager
      ? this.getUser(company.account_manager)
      : null;
    this.companyRepresentative = company?.company_representative
      ? this.getUser(company.company_representative)
      : null;
    this.country = company?.country ? COUNTRIES[company?.country] : null;
    this.state = company?.us_state ? US_STATES[company?.us_state] : null;
    this.city = company?.city ?? null;
    this.location = this.getCompanyLocation();
    this.companySize = company?.company_size
      ? this.getCompanySize(company.company_size)
      : 'N/A';
    this.website = company?.website ? this.getHref(company?.website) : null;
    this.linkedin = company?.linkedin ? this.getHref(company?.linkedin) : null;
    this.about = company?.about ?? null;
    this.logo = company?.logo ?? null;
    this.locationProfileVisibility = this.getCountries(
      company?.location_profile_visibility,
    );
    this.primaryAddress = this.getPrimaryAddress(
      company?.primary_address ?? {},
    );
    this.billingAddress = this.getBillingAddress(
      company?.billing_address ?? {},
    );
    this.ratingTotal = company?.rating_total ?? null;
    this.ratingCount = company?.rating_count ?? null;
    this.rating = company?.rating ?? {};
    this.joinedAt = company?.created_at
      ? 'Joined ' + this.formatDate(company?.created_at, DATE_FORMATS.sm)
      : 'N/A';
    this.createdAt = company?.created_at
      ? this.getCreatedAt(company?.created_at)
      : 'N/A';
    this.updatedAt = company?.updated_at
      ? this.formatDate(company.updated_at, DATE_FORMATS.lg)
      : 'N/A';
    this.lastUpdated = company?.updated_at
      ? this.formatRelativeTime(company.updated_at)
      : null;

    // Additional items
    this.inquiries = company?.inquiries || 0;
    this.submissions = company?.submissions || 0;
    this.engagements = company?.engagements || 0;
    this.bookmarked = company?.bookmarked ?? false;
  }

  getCompanySize(size) {
    const option = CompanyHelper.getEmployeeSize().find(
      (item) => item.value === size,
    );
    return option?.label;
  }

  getCreatedAt(created) {
    return `${this.formatDate(
      created,
      DATE_FORMATS.sm,
    )} (${this.formatRelativeTime(created)})`;
  }

  getCompanyLocation() {
    return this.state && this.city
      ? `${this.city}, ${this.state}`
      : this.city && this.country
      ? `${this.city}, ${this.country}`
      : (this.country || this.state) && !this.city
      ? this.country || this.state
      : this.city;
  }

  getPrimaryAddress(address) {
    return {
      addressLine1: address?.line_1 ?? null,
      addressLine2: address?.line_2 ?? null,
      postalCode: address?.postal_code ?? null,
      state: this.state,
      country: this.country,
      city: this.city,
    };
  }

  getBillingAddress(address) {
    return {
      addressLine1: address?.line_1 ?? null,
      addressLine2: address?.line_2 ?? null,
      postalCode: address?.postal_code ?? null,
      legalName: address?.company_legal_name ?? null,
      contact: address?.contact ?? null,
      email: address?.email_address ?? null,
      phoneNumber: this.getPhoneNumber(address?.phone_number),
      state: address?.state
        ? US_STATES[address?.state] || address?.state
        : null,
      country: address?.country ? COUNTRIES[address?.country] : null,
      city: address?.city ?? null,
    };
  }
  getHref(string) {
    if (!string) {
      return null;
    }
    return string.startsWith('http') ? string : `https://${string}`;
  }

  getCountries(items) {
    return Array.isArray(items)
      ? items.map((country) => COUNTRIES[country])
      : [];
  }
}

export default CompanyModal;
