import useStep from 'app/hooks/useStep';
import JobInformation from 'app/shared/requisitions/RequisitionSteps/JobDescription/JobInformation';
import SelectionForm from 'app/shared/requisitions/RequisitionSteps/JobDescription/SelectionForm';
import PropTypes from 'prop-types';
import React from 'react';

const JobDescription = ({requisitionModal}) => {
  const {nextStep, setStepData, stepData, currentStep} = useStep();

  return (
    <React.Fragment>
      {currentStep > 1 && stepData ? (
        <JobInformation requisitionModal={requisitionModal} />
      ) : (
        currentStep === 1 && (
          <SelectionForm
            initialValues={{
              job_title: stepData?.job_title,
              openings: stepData?.openings,
              type: stepData?.type,
              priority: stepData?.priority,
              job_status: stepData?.job_status,
              hiring_manager: stepData?.hiring_manager?.id,
              hiringManager: stepData?.hiring_manager,
            }}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

JobDescription.propTypes = {
  requisitionModal: PropTypes.object,
};

export default React.memo(JobDescription);
