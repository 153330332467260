import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {DATE_FORMATS} from 'app/config/app';
import {useAccountType} from 'app/hooks/useAccountType';
import {AppStepsWrapper} from 'app/providers/AppStepProvider';
import {
  useCreateEngagement,
  useUpdateEngagement,
} from 'app/services/engagementsService';
import {resetEngagementState} from 'app/store/features/engagement/engagementSlice';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import MainContent from './MainContent';
import Preview from './Preview';

const CreateEngagement = ({isEdit = false}) => {
  const [isPreview, setIsPreview] = useState(false);
  const engagement = useSelector(({engagement}) => engagement);
  const {isMarketplace, isClient, isSupplier} = useAccountType();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {mutate, isLoading} = (
    isEdit ? useUpdateEngagement : useCreateEngagement
  )();

  useEffect(() => {
    if (isMarketplace && !isClient && !isSupplier) {
      navigate('/');
    }
  }, [isClient, isMarketplace, isSupplier, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetEngagementState());
    };
  }, [dispatch]);

  const handleOnSubmit = () => {
    const {candidate, details, jobDetails, id_engagement} = engagement;

    const payload = {
      inquiry: candidate.inquiry,
      submission: candidate.submission,
      resource: candidate.resource,
      ...details,
      ...jobDetails,
      start_date: moment(details.start_date).format(DATE_FORMATS['/']),
      end_date: moment(details.end_date).format(DATE_FORMATS['/']),
      original_end_date: moment(details.end_date).format(DATE_FORMATS['/']),
      job_duration: moment(details.end_date).diff(
        moment(details.start_date),
        'days',
      ),
      hiring_manager: details.hiring_manager?.id,
      account_manager: details.account_manager?.id,
      resource_manager: details.resource_manager?.id,
    };

    const payloadEdit = {
      id_engagement,
      ...details,
      ...jobDetails,
      start_date: moment(details.start_date).format(DATE_FORMATS['/']),
      end_date: moment(details.end_date).format(DATE_FORMATS['/']),
      job_duration: moment(details.end_date).diff(
        moment(details.start_date),
        'days',
      ),
      hiring_manager: details.hiring_manager?.id,
      account_manager: details.account_manager?.id,
      resource_manager: details.resource_manager?.id,
    };

    const data = isEdit ? payloadEdit : payload;

    mutate(data, {
      onSuccess: () => {
        dispatch(resetEngagementState());
        navigate('/engagements/all');
      },
    });
  };

  const handlePreview = useCallback((bool) => setIsPreview(bool), []);

  return (
    <AppStepsWrapper currentStep={isEdit ? 3 : 1} isLoading={isLoading}>
      {isPreview ? (
        <Preview
          onCancel={() => setIsPreview(false)}
          onSubmit={handleOnSubmit}
          engagement={engagement}
        />
      ) : (
        <AppPageContainer
          header={
            <AppPageHeader
              category='Engagement'
              title={`${isEdit ? 'Edit' : 'New'} Engagement`}
            />
          }>
          <MainContent
            isEdit={isEdit}
            onPreview={handlePreview}
            onFinish={handleOnSubmit}
          />
        </AppPageContainer>
      )}
    </AppStepsWrapper>
  );
};
CreateEngagement.propTypes = {
  isEdit: PropTypes.bool,
};

export default CreateEngagement;
