import AppCard from '@wieldy/components/AppCard';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const JobInformation = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 680}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Job Title'>
          {requisitionModal.jobTitle}
        </Descriptions.Item>
        <Descriptions.Item label='Priority'>
          {requisitionModal.jobPriority}
        </Descriptions.Item>
        <Descriptions.Item label='Openings'>
          {requisitionModal.openings}
        </Descriptions.Item>
        <Descriptions.Item label='Job Status'>
          {requisitionModal.jobStatus}
        </Descriptions.Item>
        <Descriptions.Item label='Type'>
          {requisitionModal.workType}
        </Descriptions.Item>
        <Descriptions.Item label='Hiring Manager'>
          {requisitionModal.hiringManager?.name}
        </Descriptions.Item>
      </Descriptions>
    </AppCard>
  );
};

JobInformation.propTypes = {
  requisitionModal: PropTypes.object,
};

export default JobInformation;
