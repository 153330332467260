import {DownloadOutlined} from '@ant-design/icons';
import AppLoader from '@wieldy/components/AppLoader';
import AppTag from '@wieldy/components/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {INVOICE_STATUS} from '@wieldy/constants/AppDataConsts';
import {Button, Divider, Tooltip, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useInvoiceHeaders} from 'app/hooks/useInvoiceHeaders';
import {useToggle} from 'app/hooks/useToggle';
import SubmitModal from 'app/pages/main/financials/ViewInvoice/SubmitModal/index';
import {useGetInvoice as clientUseGetInvoice} from 'app/services/clientService';
import {useDownloadInvoice} from 'app/services/invoiceService';
import {useGetInvoice as supplierUseGetInvoice} from 'app/services/supplierService';
import {formatUnixDate, getFullName} from 'app/utils/helpers/DataFormatHelper';
import moment from 'moment';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {DATE_FORMATS} from '../../../../config/app';
import {downloadBlob} from '../../../common/Terms/components/utils/downloadBlob';
import MainContent from './MainContent';

const ViewInvoice = () => {
  const {id} = useParams();
  const {isSupplier, isMarketplace, isClient} = useAccountType();
  const userParams = useInvoiceHeaders();
  const {isLoading, data: invoice} = (
    isSupplier ? supplierUseGetInvoice : clientUseGetInvoice
  )(id, userParams);
  const {mutate: download, isLoading: isDownloadLoading} = useDownloadInvoice(
    id,
    isMarketplace ? userParams : null,
  );

  const isPrepared = useMemo(() => invoice?.status === 'prepared', [invoice]);
  const isSubmitted = useMemo(
    () => invoice?.status === 'submitted_to_client',
    [invoice],
  );

  const [isOpenModal, handleIsOpenModal] = useToggle(false);
  const handleSubmit = () => {
    handleIsOpenModal(true);
  };
  const fileName = `${invoice?.engagement.hid}_${getFullName(
    invoice?.resource,
    true,
  )}_${
    invoice?.end_date && moment(invoice?.end_date).format('MM-DD-YYYY')
  }.pdf`;

  const params = {
    invoice_date: formatUnixDate(
      invoice?.created_at,
      DATE_FORMATS['/'],
    ).toString(),
    due_date: invoice?.due_date.toString(),
  };

  const handleDownload = () => {
    download(
      {
        ...params,
      },
      {
        onSuccess: (response) => {
          downloadBlob(response, fileName);
        },
      },
    );
  };

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          tabs={[]}
          extra={
            <>
              <Tooltip
                overlayStyle={{fontSize: '12px'}}
                title={'Download Invoice'}>
                <Button
                  style={{
                    marginRight: 8,
                  }}
                  type='primary'
                  shape='circle'
                  onClick={handleDownload}
                  icon={<DownloadOutlined style={{fontSize: '18px'}} />}
                  loading={isDownloadLoading}
                />
              </Tooltip>
              {(isPrepared || isSubmitted) && isMarketplace && isClient && (
                <Button type='primary' shape='round' onClick={handleSubmit}>
                  {isSubmitted ? 'Resend' : 'Submit'} Invoice
                </Button>
              )}
            </>
          }
          header='inner'
          category='Invoice'
          backHeader>
          <Typography.Title
            level={2}
            className='d-flex flex-wrap align-items-center'>
            <Typography.Text type={'secondary'}>
              Invoice {invoice.hid}
            </Typography.Text>
            <Divider type='vertical' style={{height: 20}} />
            <AppTag
              color='success'
              label={INVOICE_STATUS[invoice.status]}
              shape={'circle'}
              className={'tracking-normal'}
            />
          </Typography.Title>
        </AppPageHeader>
      }>
      <MainContent invoice={invoice} />
      <SubmitModal
        invoice={invoice}
        isOpen={isOpenModal}
        handleClose={() => handleIsOpenModal(false)}
        isResend={isSubmitted}
      />
    </AppPageContainer>
  );
};

export default ViewInvoice;
