import {resourceAPI} from 'app/api/resourceApi';
import axios from 'app/config/axios';
import useReactQuery, {
  useInfiniteQuery,
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';
import {useMutation} from 'react-query';

/** Get resources list */
const useGetResources = (queryParams = {}) => {
  const params = {
    include: ['total_count'],
    ...queryParams,
  };
  return useReactQuery(['resources', queryParams], async () => {
    const {data} = await axios.get('resources', {params});
    return {
      resources: data?.data ?? [],
      total_count: data?.total_count ?? 0,
    };
  });
};

/** Get single resource */
const useGetResource = (resId, params = {}) => {
  return useReactQuery(['resources', resId], async () => {
    const {data} = await axios.get('resources/' + resId, {params});
    return data;
  });
};

const useGetAllResources = (params = {}, statuses = []) => {
  return useReactQuery(
    ['resources', params],
    async () => {
      const allPromise = statuses.map((relation_status) => {
        return resourceAPI.getResources({...params, relation_status});
      });
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        resources: data?.data || [],
        total_count: data?.total_count || 0,
        status: config.params.relation_status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

const useGetResourceDetail = (
  resId,
  params = {},
  supParams = {},
  isClient,
  isEnabled = false,
) => {
  return useReactQuery(
    ['resources', resId],
    async () => {
      const {data: resourceData} = await axios.get('resources/' + resId, {
        params,
      });
      const {supplier: supId} = resourceData;
      if (supId && isClient) {
        const {data: supplierData} = await axios.get(`suppliers/${supId}`, {
          params: supParams,
        });
        return {
          resourceData,
          supplierData,
        };
      }
      return {
        resourceData,
        supplierData: null,
      };
    },
    {enabled: isEnabled},
  );
};

/** Create a new resource by supplier */
const useCreateSupplierResource = () => {
  return useReactMutation(async (payload) => {
    const {data} = await axios.post('resources', payload);
    return {
      data,
      message: 'Resource created successfully',
    };
  });
};

/** Update resource */
const useUpdateResource = (resourceId) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    if (payload.employment_history) {
      payload.employment_history = payload.employment_history.map((item) => ({
        ...item,
        description: item.description,
      }));
    }

    if (!payload.employed_since) {
      payload.employed_since = null;
    }

    if (payload.educations?.length) {
      payload.educations = payload.educations.map((edu) => ({
        ...edu,
        year: Object.keys(edu).includes('year') && !edu.year ? null : edu.year,
      }));
    }

    if (payload.courses?.length) {
      payload.courses = payload.courses.map((course) => ({
        ...course,
        year:
          Object.keys(course).includes('year') && !course.year
            ? null
            : course.year,
      }));
    }

    if (payload.certifications?.length) {
      payload.certifications = payload.certifications.map((cr) => ({
        ...cr,
        company:
          Object.keys(cr).includes('company') && !cr.company
            ? null
            : cr.company,
        year: Object.keys(cr).includes('year') && !cr.year ? null : cr.year,
      }));
    }

    const {data} = await axios.patch('resources/' + resourceId, payload);
    await client.invalidateQueries('resources');
    return data;
  });
};

/** Add resource bookmark via client */
const useAddBookmarkToResource = (singlePage = false) => {
  const client = useQueryClient();
  return useReactMutation(async ({resource, isBookmarked}) => {
    const payload = {resource};
    let message;
    if (isBookmarked === false) {
      await axios.post('/resource_bookmarks', payload);
      message = 'Resource bookmarked successfully';
    } else {
      await axios.delete('/resource_bookmarks', {data: payload});
      message = 'Resource bookmark removed';
    }
    const queryKey = singlePage ? ['resources', resource] : ['resources'];
    await client.invalidateQueries(queryKey);
    return {message};
  });
};

const useGetInfinityResource = (queryParams = {}) => {
  return useInfiniteQuery(
    ['resource-infinity', queryParams],
    async ({pageParam = 0}) => {
      const {data} = await resourceAPI.getResources({
        page: pageParam,
        ...queryParams,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalCount = lastPage.total_count;
        const currentPageCount = allPages.length;
        const maxPageCount = totalCount / queryParams?.limit || 10;
        return currentPageCount < maxPageCount ? currentPageCount : undefined;
      },
    },
  );
};

/** Get resource total hours metric */
const useGetTotalHoursReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/time_worked', params],
    async () => {
      const {data} = await axios.get('/reports/time_worked', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

/** Get resource total expenses metric */
const useGetTotalExpensesReport = (queryParams = {}) => {
  const params = {
    ...queryParams,
  };
  return useReactQuery(
    ['/reports/used_expenses', params],
    async () => {
      const {data} = await axios.get('/reports/used_expenses', {
        params,
      });
      return {
        items: data?.data ? data?.data : [],
      };
    },
    {refetchOnMount: true},
  );
};

const useDownloadResource = () => {
  return useMutation({
    mutationFn: async ({id}) => {
      const data = await resourceAPI.getResourcePdf({
        id,
        options: {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        },
      });

      return data;
    },
  });
};

export {
  useAddBookmarkToResource,
  useCreateSupplierResource,
  useDownloadResource,
  useGetAllResources,
  useGetInfinityResource,
  useGetResource,
  useGetResourceDetail,
  useGetResources,
  useGetTotalExpensesReport,
  useGetTotalHoursReport,
  useInfiniteQuery,
  useUpdateResource,
};
