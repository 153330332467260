import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import {PERMISSIONS} from 'app/config/auth';
import {USER_CREDENTIALS} from 'app/constants/user-creds';
import {useAccountType} from 'app/hooks/useAccountType';
import {useHasPermission} from 'app/hooks/useHasPermission';
import {UserProfile} from 'app/shared/user';
import ResetPassword from 'app/shared/user/ResetAnotherUserPassword';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import EditUserForm from './EditUserForm';

const MainContent = ({user, formProps}) => {
  const [editProfile, setEditProfile] = React.useState(false);
  const {isSupplier} = useAccountType();

  const permission = useMemo(() => {
    switch (formProps?.role) {
      case USER_CREDENTIALS.USER_ROLES.owner:
      case USER_CREDENTIALS.USER_ROLES.admin:
        return isSupplier
          ? PERMISSIONS.CAN_UPDATE_SUPPLIER_MEMBER_ADMINS
          : PERMISSIONS.CAN_UPDATE_CLIENT_MEMBER_ADMINS;
      case USER_CREDENTIALS.USER_ROLES.hiring_manager:
        return PERMISSIONS.CAN_UPDATE_CLIENT_MEMBER_MANAGERS;
      case USER_CREDENTIALS.USER_ROLES.resource_manager:
        return PERMISSIONS.CAN_UPDATE_SUPPLIER_MEMBER_MANAGERS;
      case USER_CREDENTIALS.USER_ROLES.resource:
        return PERMISSIONS.CAN_UPDATE_RESOURCE;
      default:
        return 'no';
    }
  }, [formProps?.role, isSupplier]);

  const canUpdateUser = useHasPermission(permission);
  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        {editProfile ? (
          <EditUserForm
            onCancel={() => setEditProfile(false)}
            onSave={() => setEditProfile(false)}
            formProps={formProps}
          />
        ) : (
          <UserProfile user={user}>
            {canUpdateUser && (
              <Button
                type='primary'
                className={'mt-6'}
                onClick={() => setEditProfile(true)}>
                Edit User Profile
              </Button>
            )}
          </UserProfile>
        )}
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <ResetPassword email={user?.email} />
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  user: PropTypes.object,
  formProps: PropTypes.object,
};

export default MainContent;
