import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const SupplierAgreement = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.SUPPLIER_AGREEMENT],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.SUPPLIER_AGREEMENT],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='supplier-agreement'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center'>Supplier Agreement</h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              This Sourcer Supplier Agreement (“Agreement”) is made by and
              between Sourcer and Supplier (each of them individually a “Party”
              and collectively the “Parties”) as of the date Supplier’s
              representative entered into the Agreement (“Agreement Effective
              Date”).
            </p>

            <h4>1. Definitions.</h4>

            <p>
              The following terms shall have the meaning indicated below (all
              terms defined in this section or in any other provision of this
              Agreement (as defined below) in the singular are to have the same
              meanings when used in the plural and vice versa):
            </p>

            <p>
              “Agreement” means this Sourcer Supplier Agreement, together with
              any exhibits, schedules or attachments hereto, as such may be
              amended, modified or supplemented from time to time.
            </p>

            <p>
              “Billing Period” shall be weekly and shall extend from Monday
              through Sunday unless otherwise defined in the exhibits, schedules
              or attachments.
            </p>

            <p>
              “Change of Control” means: (a) any sale, conveyance or other
              disposition of all or substantially all of the assets of Supplier
              or, (b) any merger or consolidation of Supplier with or into any
              other corporation, limited liability company or other entity
              resulting in the equity holders of the Supplier as they exist
              immediately before the transaction no longer owning fifty percent
              (50%) or more of the voting power of the surviving corporation,
              limited liability company or other entity.
            </p>

            <p>
              “Confidential Information” means any information, in whatever form
              or media, of a Disclosing Party that is provided or disclosed to
              the Receiving Party including orally, in writing, or in other
              form, and whether identified as confidential or proprietary at the
              time of disclosure or which should be understood to be
              confidential by the nature of the information or the circumstances
              of the disclosure. Confidential Information will include the
              following categories of information without limitation: products,
              network configurations, network architecture, financial and
              operational information, and other matters relating to the
              operation of the Parties’ business, including information relating
              to actual or potential customers and customer lists, customer
              usage or requirements, business and customer usage forecasts and
              projections, accounting, finance or tax information, pricing
              information, business plans, strategies, technology,
              documentation, methodologies, general know-how, technical
              information, software, equipment, details of deliverables or
              services rendered under this Agreement or applicable Engagement,
              and any amendments thereto, any information exchanged between the
              Parties pursuant to a nondisclosure agreement, and all information
              and materials relating to third party vendors, systems
              integrators, or consultants.
            </p>

            <p>
              Confidential Information will not include any information which:
            </p>

            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                i. the Disclosing Party confirms in writing is free of such
                restrictions;
              </li>
              <li>
                ii. at the time of disclosure was already known or becomes
                lawfully known (independently of disclosure by the Disclosing
                Party) to the Receiving Party from a source other than one
                having an obligation of confidentiality to the Disclosing Party;
              </li>
              <li>
                iii. becomes publicly known or otherwise ceases to be secret or
                confidential, except through a breach of this Agreement or
                applicable Engagement; or
              </li>
              <li>
                iv. is independently developed by the Receiving Party without
                use of or reference to the Confidential Information.
              </li>
            </ul>

            <p>
              “Customer” means any entity or organization, including but not
              limited to its divisions, departments, institutions, subsidiaries,
              affiliates, partners, suppliers, customers, successors, and
              assigns, that enters into a Customer Agreement with Sourcer or
              otherwise agrees to be governed by its terms. Additional Customers
              may be added by Sourcer as per the Marketplace. The parties hereto
              acknowledge and agree that Customer is an intended third party
              beneficiary and may enforce the terms of the Agreement.
            </p>

            <p>
              “Customer Agreement” means that certain agreement by and between
              Customer and Sourcer, including all exhibits and addenda thereto,
              as the same may be amended or supplemented from time to time by
              agreement of Customer and Sourcer, pursuant to which Sourcer
              agrees to provide access to the Marketplace and vendor management
              services to Customer. Supplier is not a party to the Customer
              Agreement and shall have no contractual or other rights it may
              claim from Customer or Sourcer thereunder.
            </p>

            <p>
              “Disclosing Party” means the Party disclosing Confidential
              Information.
            </p>

            <p>
              “Employee” means any person determined to be in the service of
              another under any contract of hire, express or implied, oral or
              written, where the employer has the power or right to control and
              direct the employee in the material details of how the work is to
              be performed.
            </p>

            <p>
              “Engagement” means that engagement record created following the
              successful onboarding of any Staff following an accepted and
              approved Submission that includes but is not limited to candidate
              information, scope or statements of work, pricing, and duration.
              Customer and Sourcer, as Customer’s agent, reserve the right to
              amend, supplement, suspend or terminate an Engagement at any time.
              In no event shall Sourcer be construed as responsible, directly or
              indirectly, for the payment or performance of the Customer’s or
              Supplier’s obligations under the Engagement, or any Invoice, or
              for the matters contained in any Requisition or Submission.
              Engagements are limited to a single Staff person at a time.
            </p>

            <p>
              “Expense Reports” are online forms submitted by the Supplier via
              the Marketplace detailing pre-approved expenses on a per Staff
              person basis.
            </p>

            <p>
              “Intellectual Property” means: (i) patents, patent disclosures and
              inventions (whether patentable or not), ideas, discoveries,
              developments, and improvements; (ii) trademarks, service marks,
              trade dress, trade names, logos, corporate names and domain names,
              together with all of the goodwill associated therewith; (iii)
              authorship rights, copyrights and copyrightable works (including
              computer programs), mask works, and rights in data and databases;
              (iv) trade secrets, general know-how and other confidential
              information; and (v) all other intellectual property rights, in
              each case whether registered or unregistered and including all
              applications for, and renewals or extensions of, such rights, and
              all similar or equivalent rights or forms of protection provided
              by applicable law in any jurisdiction throughout the world.
            </p>

            <p>
              “Intellectual Property Rights” means any and all rights comprising
              or relating to Intellectual Property.
            </p>

            <p>
              “Invoice” means, with respect to a Supplier, a time and expense
              invoice, prepared by Sourcer based on Customer approved Timecards
              and Expense Reports. Sourcer thereafter submits the completed
              Invoice to Customer, through the Marketplace for Customer review,
              approval, and payment.
            </p>

            <p>
              “Invoicing Date” means the date that the Invoice is issued to
              Customer and is generally scheduled for, unless otherwise
              specified in Sourcer’s written policies and procedures, or posted
              online within the Sourcer Marketplace, the second Monday following
              the end of the last Billing Period that falls within the Invoicing
              Period as defined below.
            </p>

            <p>
              “Invoicing Period” shall be either: (i) weekly (Monday through
              Sunday), or (ii) monthly (from the first day of the month through
              the last day of the month), unless otherwise specified in
              Sourcer’s written policies and procedures, or posted online within
              the Sourcer Marketplace.
            </p>

            <p>
              “Losses” means claims, demands, liabilities, losses, costs or
              expenses, including reasonable attorneys&apos; fees of any nature
              whatsoever (whether for personal injury, property damage or
              otherwise) arising under any theory, including breach of contract
              or warranty, tort (including negligence), statutory liability, or
              strict liability.
            </p>

            <p>
              “Marketplace” means the online marketplace, including a vendor
              management solution for managing the end-to-end process (Procure
              to Pay) of staffing and other services enabled through the use of
              web-based systems.
            </p>

            <p>
              “Means of Direct Contact” means any information that would allow
              another person to contact Supplier directly, including, without
              limitation, phone number, email address, physical address, a link
              to a contact form or form requesting contact information, any link
              to an applicant management system or means to submit a proposal or
              application outside of the Marketplace, or any information that
              would enable a Customer to contact Supplier on social media or
              other website or platform or application that includes a
              communications tool (including without limitations Skype, Slack,
              WeChat, or Facebook). Information is a Means of Direct Contact if
              it would enable a Customer to identify any of the information
              above through other sources, such as going to a website that
              included an email address or identifying Customer through social
              media.
            </p>

            <p>
              “Receiving Party” means the Party receiving Confidential
              Information.
            </p>

            <p>
              “Requisition” means that document or request distributed by
              Sourcer through the Marketplace that contains specifications and
              requirements pertaining to a need for services to be procured from
              a qualified Supplier.
            </p>

            <p>
              “Sourcer” means Sourcer, Inc. a Delaware corporation, its
              successors and assigns.
            </p>

            <p>
              “Staff/Key Persons” means employees and, as the context requires,
              permitted Subcontractors of Supplier who are engaged to perform
              services for a Customer hereunder.
            </p>

            <p>
              “Submission” means the form sent by Supplier to the Customer via
              the Marketplace in response to either: (i) an inquiry by the
              Customer directly to Supplier, or (ii) a Requisition provided by
              the Customer through the Marketplace; and includes (without
              limitation) pricing, candidate skills, qualifications, and
              credentials.
            </p>

            <p>
              “Supplier” means, at any time, a vendor under contract with
              Sourcer who is eligible (as determined by Sourcer in its sole
              discretion) to receive Requisitions, submit Submissions and/or
              provide services to a Customer, who has duly completed all
              requirements, and is not disqualified from providing services to
              Customers.
            </p>

            <p>
              “Subcontractors” means direct or indirect subcontractors,
              independent contractors, vendors, and suppliers (collectively
              “Subcontractors”).
            </p>

            <p>
              “Timecards” are online forms submitted by the Supplier via the
              Marketplace detailing the hours worked on a per Staff person
              basis.
            </p>

            <h4>2. Nature of Agreement.</h4>

            <p>
              This Agreement details the terms pursuant to which Supplier
              accesses and uses the Marketplace and those services that
              Marketplace provides to Supplier.
            </p>

            <h4>3. Obligations of Supplier.</h4>

            <p>
              Supplier acknowledges and agrees that a violation of this Section
              3 is a material breach of this Agreement and access to the
              Marketplace may be permanently suspended for such violations.
            </p>

            <p>
              a) In order for Supplier to receive Requisitions, submit
              Submissions and/or render services to a Customer, Supplier agrees
              that during the term of this Agreement, it shall maintain
              eligibility (based on policies established by Sourcer in its sole
              discretion), maintain accurate information in the Marketplace
              (including: (i) Staff skills, education, certifications,
              availability, and (ii) Supplier entity name, billing contact, and
              primary address), meet the qualifications, and adhere to the other
              policies and procedures established by Sourcer from time to time,
              as such requirements and policies may be amended from time to
              time. Sourcer, acting in consultation with Customer, reserves the
              absolute right and discretion to establish and, as it sees fit,
              amend or supplement eligibility requirements, qualifications,
              policies and procedures applicable to Supplier and Staff with
              respect to existing or future Engagements, Requisitions and
              Submissions. Such an amendment or supplement shall be effective
              following a minimum ten (10) business days’ notice period (a) for
              an existing Engagement, if the amendment or supplement is required
              by the Customer or is a system-wide change adopted by Sourcer for
              all suppliers, and (b) in all other cases, for new Engagements or
              renewals of existing Engagements at the time the new Engagement or
              renewal is executed. Supplier may exercise its option for
              termination for convenience if it disapproves of the amendment or
              supplement.
            </p>

            <p>
              b) In response to a Requisition, Supplier may submit one or more
              Submissions through the Marketplace within a time frame as
              specified. Prior to the submission of a Submission for any
              Requisition, unless waived by Sourcer, Supplier shall confirm that
              any Staff submitted in connection with the Submission has good
              references and qualifications, shall confirm availability of Staff
              to complete the services requested, and shall use its best efforts
              to verify that information provided in the Submission is complete,
              accurate and up-to-date. In furtherance thereof, unless waived in
              writing by Sourcer, the Supplier shall have a background
              verification completed (to at least the minimum standards required
              by Customer or Sourcer) for any Staff to perform services for
              Customer and submitted to Customer according to policies and
              procedures set forth by Sourcer hereto, as they may be amended
              from time to time. Supplier shall refrain from assigning any Staff
              to any task with Customer if such investigation reveals a
              disregard for the law or other background which indicates an
              unacceptable risk. Customer may submit one or more Requisitions
              through the Marketplace. Customer shall use its best efforts to
              verify that information provided in the Requisition is complete,
              accurate and up-to-date. Pricing information contained within each
              Engagement is considered binding and shall not be changed through
              the end of the Engagement duration or Engagement extensions or
              renewals, except by mutual agreement of Customer and Supplier and
              updated within the Marketplace by Sourcer.
            </p>

            <p>
              c) Supplier shall submit (or cause its Staff to submit) Timecards
              and Expense Reports for services rendered during each Billing
              Period by noon on the first business day following the end of the
              last Billing Period. Sourcer shall authorize Supplier to access
              and use the Marketplace for the purpose of submitting such
              Timecards and Expense Reports. If Supplier (or its Staff) fails to
              file Timecards within thirty (30) days after the end of the
              Billing Period that the work is performed or Expense Reports
              within thirty (30) days of when the expense is incurred, then
              Supplier waives any and all claims for compensation or
              reimbursement with respect to such Timecards or expenses.
            </p>

            <p>
              d) Supplier hereby acknowledges and agrees that Candidates and
              Staff provided by Supplier to Customer are not employees of
              Customer or Sourcer within the application of any international,
              federal, state, or local laws or regulations, including but not
              limited to laws or regulations covering unemployment insurance,
              retirement benefits, and liability with respect thereto. Supplier
              agrees that it will abide by all local wage and hour laws,
              including properly classifying Staff provided to Customer and
              providing supervision as necessary or required. Supplier also
              agrees that it will comply with all applicable immigration, labor
              and employment laws and regulations, including laws prohibiting
              discrimination in employment. Supplier shall immediately report to
              Customer’s human resources department any complaint made by
              Supplier’s Staff of unlawful harassment or discrimination related
              to an Engagement. Supplier also agrees to comply with any
              prohibition or restriction imposed by Customer on the use of
              off-shore performance of services.
            </p>

            <p>
              e) Supplier shall require all Staff engaged by Supplier working at
              Customer‘s site(s) to adhere to Customer’s workplace policies.
              Supplier is responsible for assuring the quality and the
              supervision of its Staff while such Staff are providing services
              for Customer, subject to whatever directions or conditions that
              Customer may provide such Staff. Neither Supplier nor Supplier
              Staff shall engage in any activity at any Customer worksite or in
              any other work context intended by Supplier or Supplier Staff to
              recruit other Staff to Supplier’s organization or to solicit
              additional Requisitions. Supplier shall cause each of its
              permanent and hourly employees (i.e., non-subcontractors)
              providing services on its behalf to Customer to sign any
              documentation required by Customer or Sourcer.
            </p>

            <p>
              f) Supplier shall only use (and require that its Staff only use)
              software on projects for a Customer that have been pre-approved by
              such Customer and have been scanned for computer viruses. Supplier
              shall also require that any computer software or Internet services
              used at any facility of any Customer be directly related to the
              services being performed by such Supplier (or its Staff) for such
              Customer.
            </p>

            <p>
              g) In the event that a Customer or Sourcer has entered into or
              enters into agreements with other contractor/suppliers for any
              other work, related or unrelated, Supplier agrees to reasonably
              cooperate with such other contractor/suppliers as appropriate to
              facilitate the performance of each contractor/supplier’s work and
              maintain a professional atmosphere. Neither Supplier nor its Staff
              shall commit any act which will interfere with the performance of
              work by any other contractor/supplier.
            </p>

            <p>
              h) Communication: Supplier agrees to use the communication
              services available on the Marketplace to communicate with
              Customers prior to entering into an Engagement. Supplier agrees
              that prior to entering into an Engagement, Supplier (a) will use
              Marketplace as the sole manner to communicate with Customers; (b)
              will not provide Supplier’s Means of Direct Contact to any
              Customers or another person that Supplier identified or were
              identified by through the Marketplace; (c) will not use Means of
              Direct Contact of another person to attempt to or to communicate
              with, solicit, contact, or find the contact information of a
              Customer outside of Sourcer; (d) will not ask for, provide, or
              attempt to identify through public means the contact information
              of a Customer; and (e) will not include any Means of Direct
              Contact or means by which Supplier contact information could be
              discovered in any profile, proposal, job posting, invitation, or
              pre-Engagement communication through the Marketplace’s
              communications services (including in each case in any attached
              file), except as otherwise provided on the Marketplace.
            </p>

            <h4>4. Insurance.</h4>

            <p>
              Sourcer highly recommends that Supplier procures and maintains
              insurance as specified in Schedule A during the Term of this
              Agreement o. Some Customers may require insurance, and will be
              informed if Supplier does not possess insurance coverage.
            </p>

            <p>
              If obtained, Supplier shall procure the insurance policies at its
              own expense and shall provide Sourcer with a Certificate of
              Insurance naming both Sourcer and the Customer as additional
              insureds (certificate holders) and referencing this agreement. The
              insurance certificate must document that the liability insurance
              coverage purchased by the Supplier includes contractual liability
              coverage to protect the Customer and Sourcer. If Sourcer or
              Customer is damaged by the failure of the Supplier to maintain
              such insurance, then Supplier shall be responsible for all
              reasonable costs properly attributable thereto.
            </p>

            <p>
              Notice of cancellation of any required insurance policy must be
              submitted to Sourcer when issued and a new coverage binder shall
              be submitted immediately to ensure no break in coverage.
            </p>

            <h4>5. Fees and Invoicing.</h4>

            <p>a) The Marketplace operates exclusively in USD.</p>

            <p>
              b) The fees and expenses for services rendered by a Supplier to
              Customer shall be those agreed to by the Customer in connection
              with the solicitation of the services of the Supplier. In
              compensation for the Marketplace’s services hereunder, Supplier
              shall pay Sourcer the fees specified in Schedule B. Unless
              otherwise specified in Schedule B, Supplier acknowledges that (i)
              Sourcer shall deliver, on the Invoicing Date, to Customer, an
              aggregate invoice for the fees and expenses evidenced by Timecards
              and Expense Reports approved by such Customer during such
              Invoicing Period for all services provided for or on behalf of
              such Customer, (ii) Customer shall pay to Sourcer, and not to
              Supplier, the full amount of each invoice, and (iii) Sourcer shall
              remit within five (5) business days after receipt of full payment
              from the applicable Customer (but solely to the extent of the
              receipt of such payment) to Supplier the portion of such payment
              owed to the Supplier for services rendered and expenses incurred
              pursuant to such Invoice. With respect to the payments to be made
              by Sourcer to Supplier, Supplier agrees to reasonably cooperate
              with Sourcer in effecting any wire transfer or electronic funds
              transfer.
            </p>

            <p>
              c) Customer, in its Customer Agreement, shall be required to agree
              not to unreasonably withhold approval of any proper Invoice
              submitted to Sourcer and will notify Sourcer and Supplier if
              Customer disputes item(s) contained in an Invoice. Moreover,
              Customer shall be required to agree as part of its Customer
              Agreement to pay on a timely basis any fees and expenses except in
              the event of a dispute by Customer or for other good cause.
              However, Sourcer is not responsible for any delay in approval or
              payment of an Invoice by Customer. Payment of an Invoice shall not
              preclude Customer from making claims or objections to an Invoice
              if there are errors or issues with the work required to be
              performed, up to thirty (30) days from date of invoice.
            </p>

            <p>
              d) In the event that a Customer pays Sourcer a partial amount or
              partial amounts of aggregate Invoices, Supplier acknowledges that
              Sourcer shall remit to Supplier, within five (5) business days of
              the 15th day of each month, the portion of such payment (but
              solely to the extent of the receipt of such payment) the portion
              of such payment owed to Supplier for services rendered and
              expenses incurred pursuant to such Invoice received through the
              10th day of such month.
            </p>

            <p>
              e) To the extent Supplier’s fees and charges are for amounts due
              from Supplier to Supplier’s Staff or Subcontractors, Supplier
              agrees to pay such Staff and Subcontractors in a timely manner in
              accordance with the terms Supplier may establish with them, but in
              all cases, not significantly later than the time Supplier is paid
              the corresponding fees and charges. Sourcer may at its option, at
              any time, require Supplier to furnish Sourcer with a payment bond
              in such amount as Sourcer may specify to secure full and prompt
              payment by Supplier of amounts due its Staff and/or
              Subcontractors.
            </p>

            <p>
              f) Sourcer may elect to provide, and Supplier may elect to accept,
              payments in advance of Customer payments for mutually agreed fees
              and deductions.
            </p>

            <p>
              g) Supplier agrees to communicate through the Marketplace, and
              receive payments of Invoices for work performed by Supplier
              exclusively through the Marketplace during and up to two (2) years
              after the date that Supplier stops participating in the
              Marketplace (the “Non-Circumvention Period”). Violations of this
              Section constitute a material breach of this Agreement, may result
              in suspension or termination of Supplier’s account, and Sourcer
              retains the right to pursue a claim for damages.
            </p>

            <p>
              During the Non-Circumvention Period, Supplier agrees to use (and
              shall cause its employees, agents, or representatives to use) the
              Marketplace as the exclusive method to request and receive all
              payments for work directly or indirectly from Customer and not to
              circumvent the payment methods offered on the Marketplace. By way
              of example only, Supplier agrees that during the Non-Circumvention
              period Supplier will not:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Offer or solicit or accept any offer or solicitation from
                parties identified through the Marketplace to contract or
                receive all payments in any manner other than through the
                Marketplace.
              </li>
              <li>
                Refer anyone that Supplier identified on the Marketplace to a
                third party who is not an authorized user of the Marketplace for
                purposes of making or receiving payments other than through the
                Marketplace.
              </li>
            </ul>

            <p>
              h) Supplier is solely responsible for the creation, storage, and
              backup of Supplier’s business records. Supplier agrees that
              Sourcer has no obligation to store, maintain, or provide Supplier
              a copy of any content or information that Supplier provides,
              except to the extent required by applicable law.
            </p>

            <h4>6. Taxes.</h4>

            <p>
              Supplier shall be responsible for payment of all applicable taxes
              associated with the performance of this Agreement (other than
              taxes based on income reported by Sourcer) including, but not
              limited to, international, federal, state, or local law,
              withholding obligations, and other payroll taxes payable with
              respect to the compensation of Supplier, its employees, agents
              and/or contractors. Supplier shall forthwith pay all taxes
              lawfully imposed upon it with respect to this Agreement or any
              services delivered to Customer in accordance herewith. The amounts
              payable to Supplier under any Submissions and resulting
              Engagements shall be inclusive of all taxes payable by it
              hereunder whether or not any such taxes are separately reflected
              in any such Submission or Engagement.
            </p>

            <p>
              Sourcer makes no representations as to the liability or exemption
              from liability of the Supplier to any tax imposed by any
              governmental entity.
            </p>

            <h4>7. Representations and Warranties.</h4>

            <p>
              a) Each party hereto represents and warrants to the other party
              that (i) it is either a natural person or a validly existing
              entity duly authorized and licensed to do business in each
              jurisdiction where its services under this Agreement are to be
              performed, (ii) the execution and delivery of this Agreement, and
              the performance of its obligations hereunder, by such party have
              been duly authorized and that the Agreement is a valid and legal
              agreement binding on such party and enforceable in accordance with
              its terms, and (iii) the execution and delivery of this Agreement,
              and the performance of its obligations hereunder, by such party
              does not, and will not, conflict with or result in breach of any
              agreement, indenture or other instrument binding on such party.
            </p>

            <p>
              b) Supplier (with respect to itself and any Staff providing
              services on its behalf) expressly warrants (expressly including
              for the benefit of the Customer) that the services to be performed
              by Supplier for any Customer shall be performed in a workmanlike
              manner, subject to the reasonable requirements of the applicable
              Customer. Supplier further warrants that all work assigned and
              performed by it (or its Staff) shall be performed in accordance
              with the requirements of the Customer for whom such services are
              being performed. Supplier further represents that all work
              assigned by a Customer will be performed in a manner consistent
              with that level of care and skill ordinarily exercised by other
              providers of similar services under similar circumstances at the
              time services are provided.
            </p>

            <p>
              c) If any Staff placed by Supplier with a Customer is determined
              by such Customer or Sourcer to be unacceptable for any reason
              within a period of five (5) business days following the date that
              such Staff first provides services to such Customer (in connection
              with any single Engagement), then such Customer or Sourcer may
              terminate the services of such Staff and not be obligated to pay
              any amount to Supplier for any services provided by such Staff.
              Upon such termination of Supplier Staff, Sourcer, in its sole
              discretion, may provide such Supplier with a reasonable time
              period (determined by the circumstances existing at the time of
              the termination) to replace the terminated Staff; provided
              Customer does not prohibit or restrict such replacement by
              Supplier.
            </p>

            <p>
              d) Except as otherwise expressly authorized by a Customer in an
              applicable Engagement, all materials produced by Supplier assigned
              Staff in performance of work under such Requisition, including but
              not limited to software, charts, graphs, diagrams, and other
              project documentation shall be deemed to be work made for hire by
              Supplier and its Staff for Customer to the fullest extent allowed
              by law and shall be the property of the Customer. If any of such
              material or work product (whether in process or completed) and all
              associated intellectual property rights do not qualify as work
              made for hire by Supplier owned by the Customer under applicable
              law, then Supplier agrees, and shall require its Staff to agree,
              that such material and work product and all associated
              intellectual property rights shall be assigned to Customer
              automatically upon their creation or delivery to Customer, without
              any requirement of additional consideration or further written
              documentation. Supplier agrees, and shall require its Staff to
              agree, to take such further actions and sign and record such
              further documents as Sourcer or Customer may request to give
              effect to this Section. This assignment of rights does not include
              pre-existing Supplier property (other than components of a system
              as described in Section 9 below) or pre-existing intellectual
              property rights therein or any invention that Supplier (or its
              Staff) might develop during his/her/its own time without using
              Customer’s equipment, supplies, facilities, or trade secret
              information, except for those inventions that either relate to
              Customer’s business, or actual or demonstrably anticipated
              research or development of Customer, or result from any work
              performed by such Supplier for Customer. Supplier agrees to assist
              Customer (at Customer’s expense) by taking any actions (including
              execution of assignments or such other documents as Customer may
              request) in order for Customer to obtain and enforce patents,
              copyrights or other rights in any country relating to proprietary
              information.
            </p>

            <h4>8. Confidentiality.</h4>

            <h5>a. Treatment of Confidential Information</h5>

            <p>
              The Receiving Party agrees that it (and each of its affiliates,
              and their respective directors, officers, employees, agents, or
              contractors) will not use the Confidential Information of the
              Disclosing Party in any way except for the purpose of performing
              its obligations under the Engagement. The Receiving Party (and
              each of its affiliates, and their respective directors, officers,
              employees, agents, or contractors) will take reasonable
              precautions to protect the confidentiality of the Confidential
              Information and will use the same degree of care to protect the
              Disclosing Party’s Confidential Information that it uses to
              protect its own Confidential Information (but in no event less
              than reasonable care).
            </p>

            <p>
              Notwithstanding the foregoing, the Receiving Party may disclose
              Disclosing Party’s Confidential Information to its affiliates,
              employees, agents, or contractors, who have a need to know such
              information in order to fulfill its obligations under the
              Engagement, provided that all such parties are bound by
              non-disclosure agreements containing confidentiality obligations
              substantially equivalent to those contained herein. The Receiving
              Party will be liable for any act or omission of its affiliates,
              employees, agents, or contractors, as if such were a party to this
              Agreement or applicable Engagement. The Receiving Party (and each
              of its affiliates, employees, agents, or contractors) will not
              reverse engineer, disassemble or decompile any prototypes,
              software or other tangible objects which embody the other Party’s
              Confidential Information.
            </p>

            <p>
              Further, either Party may disclose Confidential Information to the
              extent required by law, by subpoena, judicial or administrative
              order, or otherwise, provided that, if legally permitted to do so,
              the Receiving Party gives the Disclosing Party prior written
              notice of such required disclosure to allow the Disclosing Party a
              reasonable opportunity to object to and to take legal action to
              prevent such disclosure, and will furthermore provide full
              cooperation and assistance in seeking to obtain such protection,
              all at the Disclosing Party’s cost and expense.
            </p>

            <h5>b. Term of Confidentiality Obligations</h5>

            <p>
              The Disclosing Party agrees that the confidentiality obligations
              of this section will apply during the Term and for three (3) years
              following the termination or expiration date of this Agreement or
              applicable Engagement. The terms and conditions, but not the
              existence, of this Agreement or applicable Engagement, in whole or
              part, constitute Confidential Information; provided, that this
              Agreement or applicable Engagement may be disclosed as necessary
              in connection with a financing or change of control transaction.
              Notwithstanding the foregoing, any trade secrets disclosed under
              this Agreement or applicable Engagement will be held in confidence
              by the Receiving Party for: (i) as long as the Confidential
              Information remains Disclosing Party’s trade secret under
              applicable law; or (ii) until Confidential Information falls under
              one of the exceptions to the confidentiality obligations (see
              Definition).
            </p>

            <h5>c. Return or Destruction of Records</h5>

            <p>
              Except as otherwise provided in this Agreement or applicable
              Engagement, promptly upon a request by the Disclosing Party, the
              Receiving Party will destroy or return to the Disclosing Party all
              Confidential Information of the Disclosing Party, and all
              documents or media containing any such Confidential Information
              and any copies or extracts thereof, except that the Receiving
              Party may retain one (1) copy solely for record-keeping purposes.
            </p>

            <h5>d. Unauthorized Disclosure</h5>

            <p>
              If the Receiving Party becomes aware of any unauthorized use or
              disclosure of the Confidential Information of the Disclosing
              Party, the Receiving Party will promptly notify the Disclosing
              Party of all facts known to it concerning such unauthorized use or
              disclosure. Each Party acknowledges and agrees that due to the
              unique nature of the Disclosing Party’s Confidential Information,
              there may be no adequate remedy at law for any breach of its
              confidentiality obligations hereunder, which breach may result in
              irreparable harm to the Disclosing Party, and therefore, that upon
              any such breach or any threat thereof, the Disclosing Party will
              be entitled to seek appropriate equitable relief in addition to
              whatever remedies it might have at law.
            </p>

            <h4>9. Termination of Services.</h4>

            <p>
              a) Supplier agrees that if Customer or Sourcer, in their sole
              discretion, determine that any Supplier Staff are unacceptable for
              any reason, Customer or Sourcer may request that such Supplier
              Staff’s services to Customer be terminated immediately with no
              further obligation on the part of Customer other than payment of
              any invoice for such Supplier Staff’s services rendered prior to
              such termination.
            </p>

            <p>
              b) With regard to any Engagement, a Customer or Sourcer may cancel
              such Engagement for any reason (or no reason) immediately upon
              providing ten (10) business days’ prior written notice to
              Supplier, provided that the Customer shall pay any amounts due and
              payable for services received prior to such termination.
            </p>

            <h4>10. Discipline, Suspension and Termination.</h4>

            <p>
              a) In the event that Sourcer has reason to believe that Supplier
              at any time (i) is not in compliance with eligibility
              requirements, qualifications, or other policies or procedures
              established by Sourcer, (ii) has provided false or misleading
              information or documentation to Sourcer or Customer, (iii) acts,
              or knowingly causes or permits its Staff to act, in any manner,
              that is in violation of any law or ordinance, or, in violation of
              any rule or policy of the Customer or Sourcer, or (iv) fails to
              maintain and pay Staff as necessary to meet the terms of each
              Engagement, Sourcer may do any of the following in any
              combination: (I) suspend Supplier’s access to and use of the
              Marketplace, (II) audit Supplier’s compliance status; (III) impose
              sanctions that Supplier must meet in order to maintain
              eligibility; (IV) suspend or terminate this Agreement; (V) amend,
              suspend, reduce, terminate or transfer Engagements of the Supplier
              then in effect; and (VI) withhold or redirect payment as
              appropriate to reserve for or mitigate the damages for such
              issues.
            </p>

            <p>
              b) Sourcer or Supplier may terminate this Agreement, at its
              convenience, at any time on ten (10) business days’ prior written
              notice.
            </p>

            <p>
              c) Following receipt of notice of termination of or a change to
              this Agreement or any Engagement, Supplier agrees to terminate
              and/or reassign Staff as directed by Sourcer or Customer. Supplier
              and/or its Staff, or any other contractor, may be allowed to
              continue to provide such services as directed by Sourcer or
              Customer.
            </p>

            <p>
              d) Upon termination of this Agreement or any Engagement, Supplier
              shall deliver, without charge to Customer, any supplies or
              equipment provided by Customer for use by Supplier (or any of its
              Staff) in performing services for Customer, all materials produced
              pursuant to an applicable Engagement, and all physical property
              and documents or other media (including copies) that contain
              Confidential Information of Customer.
            </p>

            <p>
              e) Supplier shall also, without charge, respond to requests
              regarding any work performed submitted for any Engagement whether
              or not completed.
            </p>

            <p>
              f) At Sourcer’ request, Supplier shall provide Sourcer with
              written certification of compliance with this section and any
              other applicable terms of the Agreement.
            </p>

            <h4>11. Indemnity.</h4>

            <p>
              a) Supplier hereby agrees to indemnify, defend and hold harmless
              Sourcer, and each Customer and their respective employees,
              proprietors, partners, representatives, shareholders, directors,
              servants, attorneys, predecessors, successors and assigns
              (hereinafter collectively referred to as {`"Indemnitees"`}), from
              any and all Losses that result from (i) an assertion or claim of
              Intellectual Property Rights infringement based on the use of
              deliverables, equipment, programming, materials, or other
              resources supplied or controlled by Supplier, (ii) any delay or
              failure of Supplier to pay its personnel or Subcontractors amounts
              due for their services, (iii) Supplier’s failure to comply with
              the terms of this Agreement, (iv) any finding that Customer or
              Sourcer was or is a deemed a statutory “employer” or “co-employer”
              of any Staff or any Staff provided by Supplier in response to a
              Requisition, (v) damage to personal property or injury or death
              that results from the negligence or willful misconduct of Supplier
              (including without limitation its Staff, agents, employees, or
              Subcontractors), or (vi) any taxes or associated liabilities,
              expenses or penalties incurred or paid by Sourcer or Customer in
              connection with applicable international, federal, state and local
              personal income taxes, withholding obligations, and other payroll
              taxes payable with respect to the compensation of Supplier, its
              employees, agents and/or contractors.
            </p>

            <p>
              b) This indemnification applies notwithstanding the fact that
              third parties or any of the Indemnitees may be partially
              responsible for the situation giving rise to the claim. However,
              if any of the Indemnitees or third parties are partially
              responsible for the events giving rise to the claim, Supplier
              indemnification hereunder shall apply only to the extent that
              Supplier contributed to the Losses.
            </p>

            <p>
              c) Supplier shall, at its expense, be entitled to and shall have
              the duty to participate in the defense of any suit against the
              Indemnitees. No settlement or compromise of any claim, loss or
              damage entered into by Indemnitees shall be binding upon Supplier
              unless approved in writing by Supplier. No settlement or
              compromise of any claim, loss or damage entered into by Supplier
              shall be binding upon Indemnitees unless approved in writing by
              Indemnitees.
            </p>

            <p>
              d) Any Indemnitee shall promptly tender the defense of any claim
              for which Supplier shall indemnify Indemnitees promptly, provided
              that the Indemnitee may be represented by counsel of its own
              choosing, at its own expense.
            </p>

            <h4>12. Disclaimer of Warranties.</h4>

            <p>
              SUPPLIER’S USE OF THE MARKETPLACE, ITS CONTENT AND ANY SERVICES
              OBTAINED THROUGH THE MARKETPLACE IS AT SUPPLIER’S RISK. THE
              MARKETPLACE, ITS CONTENT AND ANY SERVICES OBTAINED THROUGH THE
              MARKETPLACE ARE PROVIDED ON AN {`"AS IS"`} AND {`"AS AVAILABLE"`}{' '}
              BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED. NEITHER SOURCER NOR ANY PERSON ASSOCIATED WITH SOURCER
              MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
              AVAILABILITY OF THE MARKETPLACE.
            </p>

            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h4>13. Limitation of Liability.</h4>

            <p>
              a) Notwithstanding anything to the contrary contained herein,
              neither party hereto shall be liable to the other party for
              indirect, special or consequential damages.
            </p>

            <p>
              b) The foregoing limitation of liability shall not apply to
              Supplier’s liability for indemnification obligations as specified
              under Section 11 {`"Indemnity"`}.
            </p>

            <p>
              c) Except as provided in Subsection (b) above, the liability of
              Supplier to Sourcer and/or Customer with respect to any Losses
              arising out of or relating to this Agreement or any Engagement
              (including any breach of this Agreement or Engagement) or
              Supplier’s performance hereunder or thereunder shall be limited to
              the sum of (A) the aggregate payments received by Supplier from
              Sourcer for services rendered pursuant to this Agreement and (B)
              any insurance proceeds on policies owned or controlled by Supplier
              and all participating Subcontractors payable with respect to such
              Losses.
            </p>

            <p>
              d) The total aggregate liability of Sourcer to Supplier with
              respect to any Losses arising out of or relating to this Agreement
              or any Engagement (including any breach of this Agreement or
              Engagement) or Supplier’s performance hereunder or thereunder
              shall be limited to the aggregate payments received by Supplier
              from Sourcer for services rendered pursuant to this Agreement.
            </p>

            <h4>14. Notices.</h4>

            <p>
              All notices, requests, demands, and other communications required
              or permitted under this Agreement shall be in writing and deemed
              delivered when hand-delivered to the receiving person, or when
              mailed, certified mail, return receipt requested, in first class
              U.S. mail, to the addresses provided, or when electronically
              transmitted to the Internet address specified, with hard copy
              mailed within three (3) days thereafter in the manner set forth
              above.
            </p>

            <h4>15. Assignment.</h4>

            <p>
              Supplier may not transfer, sublicense or otherwise assign this
              Agreement or any of its rights or obligations hereunder without
              the prior written consent of Sourcer, and any attempted transfer,
              sublicense or assignment by a party without such consent shall be
              null and void. This Agreement shall be binding on and beneficial
              to the parties to this Agreement and their respective heirs,
              executors, administrators, legal representatives, successors and
              assigns.
            </p>

            <h4>16. Independent Contractors; Third-Party Beneficiaries.</h4>

            <p>
              In making and performing this Agreement, the parties hereto have
              acted and shall continue to act at all times as independent
              contractors. Except as otherwise provided herein, nothing
              contained in this Agreement or any actions or activity by Sourcer,
              either within Marketplace or in the performance of its obligations
              or services to Customers, shall be construed or implied to create
              the relationship of agent, representative, partner or of employer
              and employees between the parties hereto or between Supplier or
              any of its staff and Sourcer or Customer. At no time shall either
              party hereto make commitments for or in the name of the other
              party. The foregoing notwithstanding, each Customer shall be
              deemed to be a third-party beneficiary of this Agreement with
              respect to any materials or rights to be afforded hereunder to any
              Customer.
            </p>

            <h4>17. Verification and Audit Rights.</h4>

            <p>
              a) Sourcer may at any time require Supplier to provide
              information, documents or records relevant to the subject of this
              Agreement or any Engagement or Submission to show Supplier’s
              compliance with the requirements of this Agreement or any
              Engagement or Submission and Supplier’s satisfaction of
              eligibility requirements, qualifications, policies and procedures
              applicable to Supplier and Staff. Requested information, documents
              and records shall be produced and provided electronically or
              mailed within three (3) business days if so requested.
            </p>

            <p>
              b) Sourcer shall have the right, exercisable at any reasonable
              time during normal business hours, to inspect and copy any
              documents and records concerning performance of services under
              this Agreement or any Engagement, including, but not limited to,
              books, records, documents and other evidence pertaining to work
              done and/or the costs and expenses incurred by Supplier in
              performing this Agreement or any Engagement.
            </p>

            <p>
              c) The audit and verification rights shall include Subcontractors
              in which services are subcontracted by Supplier.
            </p>

            <p>
              d) If the results of the audit or verification reveal
              discrepancies greater than one percent (1.0%) of Timecard or
              Expense Report amounts submitted, Supplier shall, at Sourcer’ sole
              discretion, make corrections, allow Sourcer to make corrections,
              and pay for the costs of the audit, verification, and corrective
              actions.
            </p>

            <h4>18. Compliance.</h4>

            <p>
              Supplier shall comply at all times with Customer and Sourcer
              Policies and Procedures, and also the provisions of international,
              federal, state, or local law and certify that such provisions have
              not and will not be violated under the terms of this Agreement.
            </p>

            <h4>19. Service Continuity.</h4>

            <p>
              Sourcer gives no assurance that Staff (including Subcontractors)
              cannot or will not be hired or engaged by Customer, as Suppliers
              in their own right if they establish eligibility, or by other
              Suppliers. Supplier shall not impose any restriction on Staff that
              would imperil, prevent or delay the successful completion of
              services performed for Customer(s). Supplier releases Sourcer,
              Customer, and any other Supplier and its and their respective
              officers, employees, agents and advisors from any putative claim
              Supplier could assert in connection with any such action or
              communication.
            </p>

            <h4>20. Resolution Procedures.</h4>

            <p>
              If a dispute arises from or relates to this Agreement or the
              breach thereof, and if the dispute cannot be settled through
              direct discussions, the parties agree to endeavor first to settle
              the dispute by mediation administered by the American Arbitration
              Association under its Commercial Mediation Procedures before
              resorting to arbitration. The parties further agree that any
              unresolved controversy or claim arising out of or relating to this
              contract, or breach thereof, shall be settled by arbitration
              administered by the American Arbitration Association in accordance
              with its Commercial Arbitration Rules and judgment on the award
              rendered by the arbitrator(s) may be entered in any court having
              jurisdiction thereof.
            </p>

            <p>
              Claims shall be heard by a single arbitrator, unless the claim
              amount exceeds One Hundred Thousand Dollars ($100,000), in which
              case the dispute shall be heard by a panel of three (3)
              arbitrators. Within fifteen (15) days after the commencement of
              arbitration, each party shall select one (1) person to act as
              arbitrator and the two (2) selected shall select a third
              arbitrator within ten days of their appointment. If the
              arbitrators selected by the parties are unable or fail to agree
              upon the third arbitrator, the third arbitrator shall be selected
              by the American Arbitration Association. The place of arbitration
              shall be Dover, Delaware, USA. The arbitration shall be governed
              by the laws of the State of Delaware. If the dispute is less than
              Twenty Five Thousand Dollars ($25,000) there shall be no discovery
              other than the exchange of documents. If the dispute is over
              Twenty Five Thousand ($25,000), discovery shall consist of no more
              than two (2) depositions of up to four (4) hours each in duration.
            </p>

            <p>
              In making determinations regarding the scope of exchange of
              electronic information, the arbitrator(s) and the parties agree to
              be guided by The Sedona Principles, Third Edition: Best Practices,
              Recommendations & Principles for Addressing Electronic Document
              Production. The arbitration will be based on the submission of
              documents and there shall be no in-person or oral hearing. The
              award shall be made within four (4) months of the filing of the
              notice of intention to arbitrate (demand), and the arbitrator(s)
              shall agree to comply with this schedule before accepting
              appointment. However, this time limit may be extended by the
              arbitrator for good cause shown, or by mutual agreement of the
              parties. The arbitrators will have no authority to award punitive
              or other damages not measured by the prevailing party&apos;s
              actual damages, except as may be required by statute. The
              arbitrator(s) shall not award consequential damages in any
              arbitration initiated under this section. Pursuant to the
              Commercial Arbitration Rules, the arbitrators will have the
              authority to allocate the costs of the arbitration process among
              the parties, but will only have the authority to allocate
              attorneys&apos; fees if a particular law permits them to do so.
              The award of the arbitrators shall be accompanied by a reasoned
              opinion. Except as may be required by law, neither a party nor an
              arbitrator may disclose the existence, content, or results of any
              arbitration hereunder without the prior written consent of both
              parties. The parties agree that failure or refusal of a party to
              pay its required share of the deposits for arbitrator compensation
              or administrative charges shall constitute a waiver by that party
              to present evidence or cross-examine witnesses. In such event, the
              other party shall be required to present evidence and legal
              argument as the arbitrator(s) may require for the making of an
              award. Such waiver shall not allow for a default judgment against
              the non-paying party in the absence of evidence presented as
              provided for above.
            </p>

            <h4>21. Change of Control.</h4>

            <p>
              In the event that Supplier is subject to a Change of Control, it
              shall give notice of the same to Sourcer within five (5) business
              days following such event.
            </p>

            <h4>22. Use of Subcontractors.</h4>

            <p>
              Supplier shall furnish to Sourcer for its review and approval the
              name of any Subcontractors which Supplier intends or decides to
              use to perform work for Customer. Supplier shall provide Sourcer
              for each proposed Subcontractor with contact names and evidence of
              insurance equal to that required pursuant to Section 4 hereunder.
              Subcontractors shall be subject to the same requirements that
              apply to Supplier Staff under this Agreement. Sourcer shall have
              the right to object to any such Subcontractors at any time and, in
              the event of such objection, Supplier shall promptly replace the
              Subcontractor in question. Nothing contained within this agreement
              shall prevent Sourcer or other Suppliers from engaging
              Subcontractors, for the same or any other work. Supplier shall
              enter into written agreements with Subcontractors which contain
              necessary “flow-down” of the provisions of this Agreement that
              apply to Supplier’s Staff on substantially the same terms as this
              Agreement. Supplier shall furnish to Sourcer for its review and
              approval, a copy of all executed written agreements with
              Subcontractors. All agreements with Subcontractors shall provide
              that Subcontractors shall not be beneficiaries of this Agreement
              and instead shall look solely to Supplier for any rights or
              payment due in connection with their engagement to perform work
              for Supplier. Sourcer and Customer shall have no contractual or
              other legal obligation to any such Subcontractor or its Staff.
              Supplier shall be responsible for the actions and work of its
              Subcontractors and for failure of its Subcontractors and their
              Staff to perform their respective obligations under the law or
              this Agreement, to the same extent as Supplier would be liable to
              Sourcer if the act or omission was committed by Supplier. Supplier
              shall inspect and expedite subcontracted work and promptly report
              to Sourcer any defects in such work or the performance thereof.
            </p>

            <h4>23. Governing Law.</h4>

            <p>
              This Agreement and any dispute or claim including non-contractual
              disputes or claims arising out of or in connection with it or its
              subject matter or formation shall be governed by and construed in
              accordance with the laws of the State of Delaware, USA, as they
              presently exist or may hereafter be amended, without regard to
              principles of conflict of laws. The United Nations Convention on
              Contracts for the International Sale of Goods shall not apply to
              this Agreement.
            </p>

            <h4>24. Survival.</h4>

            <p>
              The following provisions of this Agreement shall survive any
              termination of this Agreement in accordance with Section 10 above:
              any payment provision relating to any amounts due but not paid at
              the time of such termination, expiration, or extension of services
              as provided in Section 10(c), Sections 7 through 8, and 11 through
              25.
            </p>

            <h4>25. General Provisions.</h4>

            <p>
              This Agreement constitutes the entire agreement between the
              parties relating to the subject matter hereof and supersedes all
              prior agreements, written or oral, between the parties relating to
              the subject matter contained in this Agreement. Section titles
              used in this Agreement are for reference purposes only and shall
              not be deemed to be a part of this Agreement. Time is of the
              essence of this Agreement. Each Engagement shall be considered a
              separate legal obligation existing solely between Customer and
              Supplier and is not to be construed as integrated with this
              Agreement. Subject to (a) the rights reserved by Customer and/or
              Sourcer with regard to Requisitions and Engagements, and (b) the
              right reserved by Sourcer to amend or supplement eligibility
              requirements, qualifications, policies and procedures applicable
              to Supplier and Staff with respect to existing or future
              Engagements, Requisitions and Submissions, and except as otherwise
              provided in this Agreement, this Agreement may not be amended
              except by written agreement signed by authorized representatives
              of Sourcer and Supplier, and no oral waiver, amendment or
              modification shall be effective under any circumstances. This
              Agreement may be entered into in one or more counterparts, each of
              which shall be an original and all of which shall constitute one
              and the same Agreement. Electronic signatures and documents will
              be accepted as original signatures and valid documents. If any
              term, covenant or condition of this Agreement shall for any reason
              be held unenforceable by a court of competent jurisdiction, the
              rest of this Agreement shall remain in full force and shall in no
              way be affected or impaired. Supplier shall not possess or assert
              any property interest in or any lien or other right against or to
              any work product, deliverables, or output produced or provided by
              Supplier or its personnel or Subcontractors, all of which are
              waived hereby.
            </p>

            <br />
            <br />
            <h4>
              <center>SCHEDULE A – INSURANCE REQUIREMENTS</center>
            </h4>
            <br />
            <p>
              Subject to local law, Supplier shall comply and provide (as
              applicable) the following:
            </p>

            <p>
              A. All Certificates of Insurance must be submitted before Supplier
              is able to be onboarded and/or receive Requisitions.
            </p>

            <p>
              B. Failure to file or provide a proper certificate for all
              required insurance prior to the commencement of work or upon
              request shall result in an assessment of penalties.
            </p>

            <p>
              C. Supplier will maintain the coverage included in Subsections for
              the longer of (a) the term of this Agreement or (b) the term of
              any applicable Engagement:
            </p>

            <p>
              D. Certificate Holder designated as: <br />
              <i>Sourcer, Inc.</i>
              <br />
              <i>Each Customer</i>
            </p>

            <p>
              E. <u>Worker’s Compensation Insurance</u> as required by state
              statute, and Employer’s Liability Insurance covering all of
              Supplier Staff acting within the course and scope of their
              employment. Required if Supplier has any Staff working for
              Customer onsite. Insurance must stay in place and in effect even
              if the Engagement or this Agreement expire, until all terms of the
              Engagement or this Agreement are completed and satisfied up to one
              hundred and twenty (120) days after such expiration.
            </p>

            <p>
              F. <u>Commercial General Liability Policy</u> (Occurrence), to
              include contractual liability. The Commercial General Liability
              Policy shall have a minimum One Million Dollars ($1,000,000) limit
              and shall insure that there is no gap in coverage between this
              policy and the Commercial Umbrella Policy described below.
              Coverage should include: One Million Dollars ($1,000,000) per
              occurrence, Fifty Thousand Dollars ($50,000) fire/property damage,
              One Million Dollars ($1,000,000) personal/advertising injury, Two
              Million Dollars ($2,000,000) general aggregate, and One Million
              Dollars ($1,000,000) completed operations aggregate.
            </p>

            <p>
              G. <u>Commercial Umbrella Policy</u> (Occurrence), which must
              provide the same or broader coverage than those provided for in
              the above Commercial General Liability Policy. Policy limits for
              the Commercial Umbrella Policy shall have an annual aggregate
              limit of not less than One Million Dollars ($1,000,000).
            </p>

            <p>
              H. <u>Professional Liability</u> (also known as Errors and
              Omissions) policy (claims made basis) with Electronic Data
              Processing coverage which must provide liability limits of One
              Million Dollars ($1,000,000) per occurrence.
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Coverage for the Insured’s interest in joint ventures, if
                applicable
              </li>
              <li>Punitive damages coverage (where not prohibited by law)</li>
              <li>Limited contractual liability</li>
              <li>Retroactive date prior to commencement of work</li>
              <li>Extended reporting period of thirty-six (36) months</li>
            </ul>

            <p>
              I. <u>Cyber Liability</u> (Occurrence). Data security and data
              breach insurance with at a minimum, coverage for claims and losses
              with respect to network or data risks such as data breaches,
              unauthorized release of confidential information, unauthorized
              access/use of information, identity theft, invasion of privacy,
              damage/loss/theft of data, etc. and have limits of liability of
              not less than One Million Dollars ($1,000,000).
            </p>

            <p>
              The above requirements for Cyber/Network Security and Professional
              Errors & Omissions can be met by separate policies or a
              combination of these coverages under one policy form with minimum
              limits One Million Dollars ($1,000,000) each occurrence and in the
              aggregate.
            </p>

            <p>
              J. <u>Additional Insured:</u> Sourcer and each Customer shall be
              named as additional insured on all Commercial General Liability
              Insurance policies.
            </p>

            <p>
              K. <u>Subrogation Waiver:</u> All insurance policies secured and
              maintained by Supplier or its Subcontractors as required herein
              shall include clauses stating that each carrier shall waive all
              rights of recovery, under subrogation or otherwise, against
              Sourcer, Customers, or their respective officers, agents,
              employees, contractors, and volunteers.
            </p>

            <p>
              L. <u>Primacy of Coverage:</u> Coverage shall be primary over any
              insurance or self-insurance program carried by Sourcer or
              Customer.
            </p>

            <p>
              M. <u>Cancellation:</u> insurance policies shall include
              provisions preventing cancellation or non-renewal without at least
              thirty (30) days prior notice to Supplier and Supplier shall
              forward such notice to Sourcer and Customer within seven (7) days
              of Supplier&apos;s receipt of such notice.
            </p>

            <p>
              N. <u>Certificates:</u> Supplier shall provide Sourcer and
              Customer with certificates evidencing Supplier’s insurance
              coverage within ten (10) business days following the Effective
              Date. Supplier shall provide to Sourcer and Customer certificates
              evidencing Subcontractor insurance coverage required within ten
              (10) business days following the Effective Date, except that, if
              Supplier’s subcontract is not in effect as of the Effective Date,
              Supplier shall provide certificates showing Subcontractor
              insurance coverage required within ten (10) business days
              following Supplier’s execution of the subcontract. No later than
              fifteen (15) days before the expiration date of Supplier’s or any
              Subcontractor’s coverage, Supplier shall deliver certificates of
              insurance evidencing renewals of coverage.
            </p>

            <br />
            <br />
            <h4>
              <center>SCHEDULE B – MARKETPLACE FEES</center>
            </h4>
            <br />
            <p>
              In compensation for its services hereunder, Supplier shall pay
              Sourcer the following fees:
            </p>

            <table width='100%' border={1} cellPadding={10}>
              <thead>
                <tr>
                  <th>
                    <strong>Total Customer Revenue Amount*</strong>
                  </th>
                  <th>
                    <strong>Marketplace Fees (%)</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <center>Any Amount</center>
                  </td>
                  <td>
                    <center>10.00%</center>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                “Total Customer Revenue Amount” means the total amount of all
                Invoices (excluding expenses) issued to a single Customer.
              </li>
              <li>
                “Marketplace Fees” means the fees charged to Supplier based on
                the Total Customer Revenue Amount charged to a single Customer.
              </li>
            </ul>
          </div>
        </div>
      </article>
    </div>
  );
};

export default SupplierAgreement;
