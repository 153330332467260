import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag';
import {Avatar, Button, Typography} from 'antd';
import {SUPPLIER_ENGAGEMENT_STATUSES} from 'app/constants';
import {getName} from 'app/utils/helpers/GetName';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {RiBuildingLine} from 'react-icons/ri';
import {useSelector} from 'react-redux';

const getColumns = () => {
  return [
    {
      title: 'Avatar',
      dataIndex: 'id',
      key: 'logo',
      render: (logo) => (
        <Avatar src={logo} icon={<RiBuildingLine fontSize={24} />} size={32} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (_name, item) => getName(item),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      render: (badgeContent) => (
        <AppTag
          className={'tracking-normal mr-0'}
          shape={'circle'}
          color={'success'}
          label={SUPPLIER_ENGAGEMENT_STATUSES[badgeContent] || badgeContent}
        />
      ),
    },
  ];
};

const SelectedList = () => {
  const [showMore, setShowMore] = useState(false);
  const [list, setList] = useState([]);

  const {suppliers} = useSelector(({inquiry}) => inquiry);

  const onClickMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (showMore) {
      setList(suppliers);
    } else {
      setList([...suppliers.slice(0, 2)]);
    }
  }, [showMore, suppliers]);

  return (
    <>
      <Typography.Paragraph>
        {suppliers?.length || 'All'} suppliers selected
      </Typography.Paragraph>
      {!!suppliers?.length && (
        <div className={'d-flex'}>
          <AppCard
            actions={
              suppliers.length > 2 && [
                <Button key='more' type='link' onClick={onClickMore}>
                  {showMore ? 'Less' : `+${suppliers.length - 2} more`}
                </Button>,
              ]
            }
            className={'card-supplier'}
            bodyStyle={{padding: '0'}}
            headStyle={{borderBottom: 'none'}}>
            <AppTable
              emptyText='Select from All Suppliers'
              columns={getColumns()}
              dataSource={list}
              showHeader={false}
              pagination={false}
              // className={'text-left'}
            />
          </AppCard>
        </div>
      )}
    </>
  );
};

SelectedList.propTypes = {
  suppliers: PropTypes.array,
};

export default SelectedList;
