import {INQUIRY_STATUS} from '@wieldy/constants/AppDataConsts';

import {DATE_FORMATS} from '../config/app';
import BaseModal from './BaseModal';
import CandidateModal from './CandidateModal';
import ClientModal from './ClientModal';
import RequisitionModal from './RequisitionModal';
import SupplierModal from './SupplierModal';

class InquiryModal extends BaseModal {
  constructor(inquiry = {}) {
    super();
    this.id = inquiry?.id;
    this.hid = inquiry?.hid;
    this.name = inquiry?.requisition?.job_title || '';
    this.created_at = this.formatDate(inquiry?.created_at, 'MMM DD, YYYY');
    this.updated_at = this.formatRelativeTime(inquiry?.updated_at);
    this.candidates =
      inquiry?.candidates &&
      inquiry.candidates.map((candidate) => new CandidateModal(candidate));
    this.requisition =
      typeof inquiry?.requisition === 'object'
        ? new RequisitionModal(inquiry?.requisition)
        : inquiry?.requisition;
    this.supplier = new SupplierModal(this.buildExpandSupplier(inquiry, true));
    this.client = new ClientModal(this.buildExpandClient(inquiry, true));
    this.status_key = inquiry?.status;
    this.status = inquiry?.status && INQUIRY_STATUS[inquiry.status];
    this.tagColor = this.getTagColor(this);
    this.aboutDates = {
      inquired: this.formatDate(inquiry?.created_at, DATE_FORMATS.lg),
      updated: this.formatDate(inquiry?.updated_at, DATE_FORMATS.lg),
    };
    this.accompanying_message = inquiry?.accompanying_message || null;
  }

  getTagColor({status}) {
    if (!status) return 'default';
    if (
      [
        INQUIRY_STATUS.sent,
        INQUIRY_STATUS.in_review,
        INQUIRY_STATUS.responded,
        INQUIRY_STATUS.engaged,
      ].includes(status)
    ) {
      return 'success';
    }
    return 'default';
  }
}

export default InquiryModal;
