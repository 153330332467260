import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Descriptions, Row} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const InformationView = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 820}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root table-layout-auto'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Worksite Type'>
          {requisitionModal?.jobWorkSite}
        </Descriptions.Item>
        <Descriptions.Item label='Worksite Location(s)'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal?.jobCountry &&
              requisitionModal.jobCountry.map((country) => (
                <AppTag
                  key={country}
                  className={'border-none'}
                  shape={'square'}
                  label={country}
                />
              ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Travel Required'>
          {requisitionModal?.travelRequirements}
        </Descriptions.Item>
        <Descriptions.Item label='Duration'>
          {requisitionModal?.jobDuration}
        </Descriptions.Item>
        <Descriptions.Item label='Start Date'>
          {requisitionModal.startDate}
        </Descriptions.Item>
        <Descriptions.Item label='End Date'>
          {requisitionModal.endDate}
        </Descriptions.Item>
      </Descriptions>
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
