import AppTable from '@wieldy/components/AppTable';
import {useAccountType} from 'app/hooks/useAccountType';
import {useGetColumns} from 'app/pages/main/financials/ViewInvoice/MainContent/common/columns';
import PropTypes from 'prop-types';
import React from 'react';

const CommonTable = ({invoice}) => {
  const {isClient} = useAccountType();

  const columns = useGetColumns(isClient);

  return (
    <AppTable
      style={{maxWidth: 650}}
      columns={columns}
      dataSource={[invoice]}
      pagination={false}
    />
  );
};

CommonTable.propTypes = {
  invoice: PropTypes.object,
};

export default CommonTable;
