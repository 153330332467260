import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {TITLE_REGX} from '@wieldy/constants/ValidationConsts';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, notification, Row, Space, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUploadImageToAws} from 'app/services/globalService';
import AvatarUpload from 'app/shared/components/AvatarUpload';
import {CompanyMemberDropdown} from 'app/shared/components/dropdowns';
import Editor from 'app/shared/components/Editor/Editor';
import ClientHelper from 'app/utils/ClientHelper';
import SupplierHelper from 'app/utils/SupplierHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';

import {useRole} from '../../../../../../../hooks/useRole';
import {InsuranceSupplierState} from '../../../../../requisitions/ViewRequisition/constatns';

const EditCompanyDetail = ({
  onCancel,
  onSubmit,
  initialValues = {},
  loading = false,
}) => {
  const {isSupplier} = useAccountType();
  const [disabled, setDisabled] = React.useState(true);
  const [uploadLogo, setUploadLogo] = React.useState();
  const [companyLogo, setCompanyLogo] = React.useState(initialValues?.logo);
  const uploadMutation = useUploadImageToAws();
  const {isMarketManager, isAdmin} = useRole();

  React.useEffect(() => {
    if (uploadLogo) {
      setDisabled(false);
    }
  }, [uploadLogo]);

  const handleOnFinish = (formdata) => {
    formdata.has_insurance =
      formdata.has_insurance === InsuranceSupplierState.yes;

    if (uploadLogo) {
      uploadMutation.mutate(
        {
          uploadUrl: uploadLogo.upload_url,
          file: uploadLogo.file,
        },
        {
          onSuccess: () => {
            formdata.logo = uploadLogo.url;
            formdata.has_insurance =
              formdata.has_insurance === InsuranceSupplierState.yes;
            onSubmit(formdata);
          },
          onError: () => {
            notification.error({
              message:
                'Timeout to upload a logo expired, please try again to upload file',
            });
            setUploadLogo(undefined);
            setCompanyLogo(initialValues?.logo);
          },
        },
      );
    } else {
      onSubmit(formdata);
    }
  };

  return (
    <AppFormWrapper>
      <Form
        layout={'vertical'}
        initialValues={{
          ...(initialValues ? initialValues : {}),
          company_representative: initialValues?.company_representative?.id,
          has_insurance: initialValues?.has_insurance
            ? InsuranceSupplierState.yes
            : InsuranceSupplierState.no,
        }}
        onFinish={handleOnFinish}
        onFieldsChange={() => setDisabled(false)}
        colon={false}>
        <AppFormBody>
          <Row gutter={[10, 5]}>
            <Col md={6}>
              <Form.Item
                name={'name'}
                label={'Company Name'}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Company name'),
                  },
                  () => ({
                    validator(_, value) {
                      if (value.trim().length < 3) {
                        return Promise.reject(
                          'Name must be at least 3 characters long',
                        );
                      }
                      return value && !TITLE_REGX.test(value)
                        ? Promise.reject('Enter a valid name')
                        : Promise.resolve();
                    },
                  }),
                ]}>
                <AppInput placeholder={getInputPlaceHolder('company name')} />
              </Form.Item>
            </Col>
            <Col md={6}>
              {isSupplier ? (
                <Form.Item
                  name={'service_focus'}
                  label={'Service Focus'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Service focus'),
                    },
                  ]}>
                  <AppSelect
                    options={SupplierHelper.serviceFocuses()}
                    showSearch
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name={'industry'}
                  label={'Industry'}
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('Industry'),
                    },
                  ]}>
                  <AppSelect
                    options={ClientHelper.getIndustries()}
                    placeholder={getSelectPlaceHolder('industry')}
                  />
                </Form.Item>
              )}
            </Col>
            <Col md={6}>
              <Form.Item
                name={'company_size'}
                label={'Company Size'}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Company size'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('company size')}
                  options={ClientHelper.getEmployeeSize()}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name={'status'}
                label={'Engagement Status'}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Engagement status'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('status')}
                  options={(isSupplier
                    ? SupplierHelper
                    : ClientHelper
                  ).getEngagementStatus()}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name={'website'} label={'Website'}>
                <AppInput placeholder={getInputPlaceHolder('website')} />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name={'linkedin'} label={'LinkedIn Page'}>
                <AppInput placeholder={getInputPlaceHolder('LinkedIn page')} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name={'company_representative'}
                label={'Company Representative'}>
                <CompanyMemberDropdown
                  variant={isSupplier ? 'supplier' : 'client'}
                  placeholder={getSelectPlaceHolder('company representative')}
                  filterMembers={(m) =>
                    m.role !== 'resource' && m.role !== 'hiring_manager'
                  }
                  initialFetch={true}
                  showArrow={false}
                  allowClear={true}
                  defaultValue={initialValues?.company_representative?.name}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {isSupplier && (
              <>
                <Col md={6}>
                  <Form.Item
                    name={'diversity_status'}
                    label={'Diversity Status'}>
                    <AppSelect
                      placeholder={getSelectPlaceHolder('status')}
                      options={SupplierHelper.getDiversityStatus()}
                      mode={'multiple'}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    name={'industry_focuses'}
                    label={'Industry Focus'}
                    rules={[
                      {
                        required: true,
                        message: getRequiredMessage('Industry'),
                      },
                    ]}>
                    <AppSelect
                      options={SupplierHelper.getIndustries()}
                      mode={'multiple'}
                      placeholder={getSelectPlaceHolder('industry focus')}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                {(isAdmin || isMarketManager) && (
                  <Col md={24}>
                    <Form.Item
                      name={'has_insurance'}
                      label={'Has Insurance'}
                      rules={[
                        {
                          required: true,
                          message: getRequiredMessage('Insurance'),
                        },
                      ]}>
                      <AppSelect
                        options={SupplierHelper.getInsuranceValue()}
                        placeholder={getSelectPlaceHolder('has insurance')}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}
            <Col md={24}>
              <Form.Item name={'about'} label={'About'}>
                <Editor readOnly={false} isCompanyProfile />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Typography.Title level={4}>Company Logo</Typography.Title>
              <AvatarUpload
                variant={isSupplier ? 'supplier' : 'client'}
                id={initialValues?.id}
                url={companyLogo}
                onChange={setUploadLogo}
                icon={<RiBuildingLine fontSize={40} />}
              />
            </Col>
          </Row>
        </AppFormBody>
        <AppFormActions>
          <Space size={16} className={'mt-7'}>
            <Button
              type='primary'
              htmlType='submit'
              loading={uploadMutation.isLoading || loading}
              disabled={disabled}>
              Save
            </Button>

            <Button type='primary' onClick={onCancel} ghost>
              Cancel
            </Button>
          </Space>
        </AppFormActions>
      </Form>
    </AppFormWrapper>
  );
};

EditCompanyDetail.propTypes = {
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default EditCompanyDetail;
