import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {useRole} from 'app/hooks/useRole';
import {getCurrentClient} from 'app/services/authService';
import {useGetClients, useUpdateClient} from 'app/services/clientService';
import {
  setUserAccount,
  updateCurrentAccount,
} from 'app/store/features/user/userSlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {getColumns} from './columns';

const ClientsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isOwner, isAdmin} = useRole();
  const isShowManagerColumn = isOwner || isAdmin;

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {isLoading, isFetching, data} = useGetClients({
    page: currentPage - 1,
    limit: pageSize,
    include: ['open_requisitions', 'active_engagements', 'total_count'],
    expand: ['account_manager'],
    sort: ['+name'],
  });
  const [clients, clientsTotal] = getItemsData('clients', data);

  const {mutate, isLoading: loading} = useUpdateClient(false);

  const onShowSizeChange = (current, pageSize) => {
    setCurrentPage(current);
    setPageSize(pageSize);
  };

  const onProfileView = async (userId) => {
    if (userId) {
      dispatch(
        setUserAccount({
          id: userId,
          header: 'sourcer-client',
          accountType: 'client',
        }),
      );
      navigate('/dashboard');
      const response = await getCurrentClient();
      const payload = {
        client: response.client,
        permissions: response?.permissions ?? [],
        role: response?.role ?? null,
        status: response?.status ?? null,
      };
      dispatch(updateCurrentAccount(payload));
    }
  };

  const columns = getColumns({
    onProfileView,
    mutate,
    loading,
    isShowManagerColumn,
  });

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <AppCard
      title={
        <React.Fragment>
          Client Accounts{' '}
          {!!clientsTotal && <AppTag className={'ml-1'}>{clientsTotal}</AppTag>}
        </React.Fragment>
      }
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 0}}
      extra={
        <AppHeaderPagination
          total={clientsTotal}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
          hideStartBorder
        />
      }>
      <AppTable
        loading={isFetching}
        className={'column-valign-top'}
        columns={columns}
        dataSource={clients}
        scroll={{x: 'max-content'}}
        pagination={getTablePagination(
          pageSize,
          currentPage,
          clientsTotal,
          onShowSizeChange,
          onPageChange,
        )}
      />
    </AppCard>
  );
};

export default ClientsTable;
