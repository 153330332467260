import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag';
import {Col, Descriptions, Row, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const InformationView = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 990}}>
      <Descriptions
        title='Compensation'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Max Bill Rate'>
          {requisitionModal.billingRateMax}
        </Descriptions.Item>
        <Descriptions.Item label='Expenses Eligibility'>
          {requisitionModal.expensesEligible}
        </Descriptions.Item>
        <Descriptions.Item label='Bill Rate Period'>
          {requisitionModal.billingRatePeriod}
        </Descriptions.Item>
        <Descriptions.Item label='FLSA Eligible'>
          {requisitionModal.flsaEligible}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions
        title='Candidate Criteria'
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Location(s)'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal.candidateCountries?.map((location, index) => (
              <Col key={index}>
                <AppTag
                  className={'border-none'}
                  shape={'square'}
                  label={location}
                />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label='Visa Status'>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal?.candidateVisaStatus &&
              requisitionModal.candidateVisaStatus?.map((visa) => (
                <Col key={visa}>
                  <AppTag
                    className={'border-none'}
                    shape={'square'}
                    label={visa}
                  />
                </Col>
              ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item
          label='Minimum Experience'
          labelStyle={{width: '50%'}}>
          {requisitionModal.candidateMinimumExperience}
        </Descriptions.Item>
        <Descriptions.Item label='Minimum Education'>
          {requisitionModal.candidateMinEducation}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Based In' span={1}>
          <Row wrap='wrap' gutter={[10, 10]}>
            {requisitionModal.supplierCountries?.map((location, index) => (
              <Col key={index}>
                <AppTag
                  className={'border-none'}
                  shape={'square'}
                  label={location}
                />
              </Col>
            ))}
          </Row>
        </Descriptions.Item>
      </Descriptions>
      {!!requisitionModal?.candidateRequiredChecks?.length && (
        <Typography.Title level={4} className='mb-4 mt-3'>
          Required Background Checks
        </Typography.Title>
      )}
      {requisitionModal.candidateRequiredChecks.map((item) => (
        <Typography.Paragraph key={item}>{item}</Typography.Paragraph>
      ))}
    </AppCard>
  );
};

InformationView.propTypes = {
  requisitionModal: PropTypes.object,
};

export default InformationView;
