import AppCard from '@wieldy/components/AppCard';
import {Button, notification, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateClient} from 'app/services/clientService';
import {useUpdateSupplier} from 'app/services/supplierService';
import {
  updateCurrentUser,
  useCurrentUser,
} from 'app/store/features/user/userSlice';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import CompanyDetail from './CompanyDetail';
import EditCompanyDetail from './EditCompanyDetail';

const CompanyInfo = () => {
  const [showEdit, setShowEdit] = React.useState(false);
  const dispatch = useDispatch();
  const {companyModal} = useSelector(({user}) => user);
  const companyProfile = useCurrentUser();
  const {isSupplier} = useAccountType();
  const mutationSupplier = useUpdateSupplier();
  const mutationClient = useUpdateClient();

  const mutation = useMemo(
    () => (isSupplier ? mutationSupplier : mutationClient),
    [isSupplier, mutationClient, mutationSupplier],
  );

  const handleUpdateCompany = React.useCallback(
    (payload) => {
      mutation.mutate(
        {
          [isSupplier ? 'supplierId' : 'clientId']: companyModal.id,
          payload,
        },
        {
          onSuccess: (data) => {
            dispatch(updateCurrentUser(data));
            notification.success({
              message: 'Company information updated successfully',
            });
            setShowEdit(false);
          },
          onError: (error) => {
            notification.error({message: error.message});
          },
        },
      );
    },
    [companyModal.id, dispatch, isSupplier, mutation],
  );

  return (
    <AppCard>
      <Typography.Title level={3} className={'mb-3'}>
        Company Information
      </Typography.Title>

      {showEdit ? (
        <EditCompanyDetail
          onCancel={() => setShowEdit(false)}
          initialValues={{
            ...companyProfile,
            company_representative: companyModal.companyRepresentative,
          }}
          loading={mutation.isLoading}
          onSubmit={handleUpdateCompany}
        />
      ) : (
        <CompanyDetail company={companyModal}>
          <Button type='primary' onClick={() => setShowEdit(true)}>
            Edit Company Information
          </Button>
        </CompanyDetail>
      )}
    </AppCard>
  );
};

export default React.memo(CompanyInfo);
