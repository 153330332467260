import AppTags from '@wieldy/components/AppTags';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Overview = ({supplier}) => {
  const {
    companySize,
    relationStatus,
    serviceFocus,
    hid,
    createdAt,
    diversityStatus,
    industryFocuses,
    completedEngagements,
  } = supplier;
  return (
    <Descriptions
      colon={false}
      labelStyle={{width: '50%'}}
      className='description-root mb-4'
      column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
      <Descriptions.Item label='Relationship Status'>
        {relationStatus ?? 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label='Service Focus'>
        {serviceFocus ?? 'N/A'}
      </Descriptions.Item>
      <Descriptions.Item label='Company Size'>{companySize}</Descriptions.Item>
      <Descriptions.Item label='# of Deployed Resources'>
        {completedEngagements}
      </Descriptions.Item>
      <Descriptions.Item label='Diversity Status'>
        <AppTags items={diversityStatus} />
      </Descriptions.Item>
      <Descriptions.Item label='Industry Focus'>
        <AppTags items={industryFocuses} />
      </Descriptions.Item>
      <Descriptions.Item label='Joined'>{createdAt}</Descriptions.Item>
      <Descriptions.Item label='Company ID'>{hid}</Descriptions.Item>
    </Descriptions>
  );
};

Overview.propTypes = {
  supplier: PropTypes.object,
};

export default React.memo(Overview);
