import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  requisition: {
    id: undefined,
    job_title: undefined,
  },
  candidates: [],
  existingCandidates: [],
  isEditMode: false,
  accompanying_message: null,
};

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setSubmissionRequisition: (state, action) => {
      state.requisition = action.payload;
    },
    setSubmissionCandidates: (state, action) => {
      state.candidates = action.payload;
    },
    setSubmissionMessage: (state, action) => {
      state.accompanying_message = action.payload;
    },
    resetSubmissionState: () => initialState,
    setSubmissionEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setSubmissionEditData: (state, action) => {
      const {payload} = action;
      state.requisition = {
        id: payload.requisition.id,
        job_title: payload.requisition.job_title,
      };
      state.suppliers = [payload.supplier];
      state.existingCandidates = payload.candidates.map(
        ({resource}) => resource.id,
      );
      state.accompanying_message = payload.accompanying_message;
    },
  },
});

export const {
  setSubmissionRequisition,
  setSubmissionCandidates,
  resetSubmissionState,
  setSubmissionEditMode,
  setSubmissionEditData,
  setSubmissionMessage,
} = submissionSlice.actions;

export default submissionSlice.reducer;
