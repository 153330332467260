import {DATE_FORMATS} from 'app/config/app';

import {
  DIVERSITY_STATUSES,
  INDUSTRIES,
  SERVICE_FOCUSES,
  SUPPLIER_RELATIONSHIP_STATUSES,
} from '../constants';
import CompanyModal from './CompanyModal';

class SupplierModal extends CompanyModal {
  constructor(supplier) {
    super(supplier);
    this.serviceFocus = supplier?.service_focus
      ? SERVICE_FOCUSES[supplier?.service_focus]
      : 'N/A';
    this.relationStatus = supplier?.relation_status
      ? SUPPLIER_RELATIONSHIP_STATUSES[supplier?.relation_status]
      : null;
    this.industryFocuses = supplier?.industry_focuses
      ? this.getIndustries(supplier?.industry_focuses)
      : [];
    this.diversityStatus = supplier?.diversity_status
      ? this.getDiversityStatus(supplier?.diversity_status)
      : [];
    this.locationInquiriesPreference = this.getCountries(
      supplier?.location_inquiries_preference,
    );
    this.browsingVisible = this.getProfileVisibility(
      supplier?.browsing_visible,
    );
    this.receiveInquiry = this.getReceiveInquiry(supplier?.receive_inquiry);
    this.joinedAt = supplier?.created_at
      ? 'Joined ' + this.formatDate(supplier?.created_at, DATE_FORMATS.sm)
      : 'N/A';
    this.activeClients = supplier?.active_clients ?? 0;
    this.completedEngagements = supplier?.completed_engagements ?? 0;
    this.availableResources = supplier?.available_resources ?? 0;
    this.has_insurance = supplier?.has_insurance || false;
  }

  getDiversityStatus(diversities) {
    return Array.isArray(diversities)
      ? diversities.map((item) => DIVERSITY_STATUSES[item])
      : [];
  }

  getProfileVisibility(visibility) {
    if (visibility === false && this.locationProfileVisibility.length > 0) {
      return 'Visible to some clients';
    } else if (
      visibility === false &&
      this.locationProfileVisibility.length === 0
    ) {
      return 'Not visible';
    } else {
      return 'Visible to all clients';
    }
  }

  getReceiveInquiry(allowed) {
    if (allowed === false && this.locationInquiriesPreference.length > 0) {
      return 'Allowed from some clients';
    } else if (
      allowed === false &&
      this.locationInquiriesPreference.length === 0
    ) {
      return 'Not allowed';
    } else {
      return 'Allowed from all clients';
    }
  }

  getIndustries(industries) {
    return Array.isArray(industries)
      ? industries.map((name) => INDUSTRIES[name])
      : [];
  }
}

export default SupplierModal;
