import {CloseOutlined} from '@ant-design/icons';
import {Button, Divider, Dropdown, Menu, Space, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const SearchResultBox = ({onClose, title, items}) => {
  if (Array.isArray(items)) {
    const label = items[0];
    if (items.length > 1) {
      const menuItems = [];
      for (let counter = 0; counter < items.length; counter++) {
        if (counter === 0) {
          continue;
        }
        menuItems.push(<Menu.Item key={counter}>{items[counter]}</Menu.Item>);
      }
      const menu = <Menu>{menuItems}</Menu>;
      return (
        <Space direction={'vertical'}>
          <Typography.Text type={'secondary'}>{title}</Typography.Text>
          <Dropdown placement={'bottom'} overlay={menu}>
            <Button onClick={onClose}>
              <Space size='small'>
                <Typography.Text style={{maxWidth: '150px'}} ellipsis>
                  {label}
                </Typography.Text>
                <span>
                  <Divider style={{height: '20px'}} type='vertical' />+
                  {items.length - 1}
                </span>
                <CloseOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      );
    }
  }
  return (
    <Space direction={'vertical'}>
      <Typography.Text type={'secondary'}>{title}</Typography.Text>
      <Button onClick={onClose}>
        <Space size='small'>
          <Typography.Text
            style={{minWidth: '120px', maxWidth: '150px'}}
            ellipsis>
            {items}
          </Typography.Text>
          <CloseOutlined />
        </Space>
      </Button>
    </Space>
  );
};

SearchResultBox.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default SearchResultBox;
