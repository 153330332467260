import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {USER_CREDENTIALS} from '../constants/user-creds';

export const useRole = () => {
  const {userRole} = useSelector(({user}) => user);

  return useMemo(
    () => ({
      isResource: userRole === USER_CREDENTIALS.USER_ROLES.resource,
      isMarketManager:
        userRole === USER_CREDENTIALS.USER_ROLES.marketplace_manager,
      isManager: userRole === USER_CREDENTIALS.USER_ROLES.resource_manager,
      isHiring: userRole === USER_CREDENTIALS.USER_ROLES.hiring_manager,
      isOwner: userRole === USER_CREDENTIALS.USER_ROLES.owner,
      isAdmin: userRole === USER_CREDENTIALS.USER_ROLES.admin,
    }),
    [userRole],
  );
};
