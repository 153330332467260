import AppTag from '@wieldy/components/AppTag/AppTag';
import {Typography} from 'antd';
import UserMiniProfile from 'app/shared/components/UserMiniProfile';
import {getUserName} from 'app/utils/helpers/ConvertHID.js';
import PropTypes from 'prop-types';
import React from 'react';

import {topCounts} from '../constants';

const ViewSupplierHeader = ({supplier}) => {
  const topItems = React.useMemo(() => topCounts(supplier), [supplier]);

  const supplierName = getUserName(supplier?.name, 'SUP-', 'Supplier');

  return (
    <div className='page-header-info'>
      <UserMiniProfile
        name={supplierName}
        avatarUrl={supplier?.logo || ''}
        location={supplier?.location}
        rating={supplier?.ratingTotal}
        reviews={supplier?.ratingCount}
        renderTitle={
          <Typography.Title
            level={3}
            className={'d-flex flex-wrap align-items-center mb-0'}>
            <span className='mr-2'> {supplierName}</span>
            <AppTag
              color={supplier?.statusColor}
              shape='circle'
              label={supplier?.statusName}
              className='tracking-normal my-1'
            />
          </Typography.Title>
        }
        avatarProps={{shape: 'square'}}
      />
      <div className={'page-header-info-extra'}>
        <div className='list-info'>
          {topItems &&
            topItems?.map((counter, index) => (
              <div key={index} className='list-info-item'>
                <Typography.Text className='info-count'>
                  {counter?.count}
                </Typography.Text>
                <Typography.Text type={'secondary'}>
                  {counter?.label}
                </Typography.Text>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

ViewSupplierHeader.propTypes = {
  supplier: PropTypes.object,
};

export default React.memo(ViewSupplierHeader);
