import SupplierHelper from 'app/utils/SupplierHelper';

export default {
  industries: {
    label: 'Industry',
    options: SupplierHelper.getIndustries(),
  },
  company_size: {
    label: 'Company Size',
    options: SupplierHelper.getEmployeeSize(),
  },
  engagement_status: {
    label: 'Engagement Status',
    options: SupplierHelper.getEngagementStatus().filter(
      (el) => el.key !== 'closed',
    ),
  },
  service_focus: {
    label: 'Service Status',
    options: SupplierHelper.serviceFocuses(),
  },
  diversity_focus: {
    label: 'Diversity Status',
    options: SupplierHelper.getDiversityStatus(),
  },
};
