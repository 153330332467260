import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import {
  getInputPlaceHolder,
  getRequiredMessage,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, InputNumber, Space} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const MarketPlaceForm = ({onContinue, details, setState}) => {
  const [form] = Form.useForm();

  const handleOnAddRow = (add) => {
    add();
  };

  const handleOnSubmit = (formData) => {
    if (formData.marketplace_fees.length > 0) {
      const marketPlaceFees = formData.marketplace_fees.map((el) => ({
        ...el,
        end: el.end || -1,
      }));
      if (marketPlaceFees.length > 0) {
        setState({marketplace_fees: marketPlaceFees});
      } else {
        setState({marketplace_fees: []});
      }
    }
    if (onContinue) onContinue();
  };

  const rowItems = {
    marketplace_fees: [
      {
        start: '',
        end: '',
        percentage: '',
      },
    ],
  };
  if (details && details.length > 0) {
    rowItems.marketplace_fees = details.map((el) => ({
      ...el,
      end: el.end === -1 ? undefined : el.end,
    }));
  }

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          autoComplete='off'
          onFinish={handleOnSubmit}
          form={form}
          initialValues={rowItems}>
          <AppFormBody style={{display: 'flex', flexDirection: 'column'}}>
            <Form.List name='marketplace_fees' style={{width: '810px'}}>
              {(fields, {add, remove}) => {
                const length = fields?.length;
                return (
                  <>
                    {fields.map(({key, name, ...rest}, index) => {
                      const isRequired =
                        length > 1 ? index === length - 2 : false;
                      return (
                        <Space key={key} size={'large'}>
                          <Form.Item
                            {...rest}
                            required={length > 1}
                            rules={[
                              {
                                required: length > 1,
                                message: getRequiredMessage('From Amount'),
                              },
                            ]}
                            name={[name, 'start']}
                            label='From Amount'>
                            <InputNumber
                              style={{width: 180}}
                              prefix='$'
                              suffix='USD'
                              controls={false}
                              placeholder={getInputPlaceHolder('amount')}
                            />
                          </Form.Item>
                          <Form.Item
                            {...rest}
                            required={isRequired}
                            rules={[
                              {
                                required: isRequired,
                                message: getRequiredMessage('To Amount'),
                              },
                            ]}
                            name={[name, 'end']}
                            label='To Amount'>
                            <InputNumber
                              style={{width: 180}}
                              prefix='$'
                              suffix='USD'
                              controls={false}
                              placeholder={getInputPlaceHolder('amount')}
                            />
                          </Form.Item>
                          <Form.Item
                            {...rest}
                            required={length > 1}
                            rules={[
                              {
                                required: length > 1,
                                message: getRequiredMessage('MSP Fees'),
                              },
                            ]}
                            name={[name, 'percentage']}
                            label='MSP Fees'>
                            <InputNumber
                              style={{width: 180}}
                              controls={false}
                              prefix='%'
                              placeholder={getInputPlaceHolder('percentage')}
                            />
                          </Form.Item>
                          {index === 0 && (
                            <Button
                              style={{marginTop: 8}}
                              onClick={() => handleOnAddRow(add)}
                              icon={<PlusOutlined />}
                              block>
                              Add Row
                            </Button>
                          )}
                          {index > 0 && (
                            <Button
                              style={{marginTop: 8}}
                              onClick={() => remove(name)}
                              icon={<MinusCircleOutlined />}
                              block>
                              Remove
                            </Button>
                          )}
                        </Space>
                      );
                    })}
                  </>
                );
              }}
            </Form.List>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

MarketPlaceForm.propTypes = {
  details: PropTypes.array,
  onContinue: PropTypes.func,
  setState: PropTypes.func,
};

export default React.memo(MarketPlaceForm);
