import AppLoader from '@wieldy/components/AppLoader';
import {QUERY_CONSTANTS, USER_INCLUDES} from 'app/constants';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import CandidateModal from 'app/modals/CandidateModal';
import SupplierModal from 'app/modals/SupplierModal';
import {useGetCandidateDetails} from 'app/services/candidateService';
import {useGetResourceDetail} from 'app/services/resourceService';
import {ResourcePageContainer, ResourceProvider} from 'app/shared/resource';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {getPageQueries} from '../MainContent/constants';
import {getPrimaryTabs} from './constants';
import MainContent from './MainContent';

const ViewCandidate = () => {
  const {id} = useParams();
  const {isClient, isSupplier} = useAccountType();

  const {isLoading, data: details} = useGetCandidateDetails(
    id,
    {...getPageQueries(!isClient, true)},
    {
      expand: [isClient ? 'supplier' : 'client'],
      include: isSupplier
        ? [
            'skill_tags',
            'client_active_suppliers',
            'client_engaged_resources',
            'client_open_requisitions',
            'common_inquiries',
            'common_submissions',
            'common_engagements',
            'client_relationship_status',
          ]
        : ['skill_tags'],
    },
  );

  const {isLoading: isLoadingResource, data} = useGetResourceDetail(
    details?.candidate?.resource.id,
    {
      include: [
        'skill_tags',
        'completed_engagements',
        ...USER_INCLUDES.supplierIncludeDetails.filter(
          (item) => item !== 'relation_status',
        ),
        'supplier_relationship_status',
      ],
    },
    QUERY_CONSTANTS.SUPPLIER.VIEW,
    isClient,
    !!details?.candidate,
  );

  const {resourceData, supplierData} = data || {
    resourceData: null,
    supplierData: null,
  };

  const candidate = useMemo(
    () =>
      new CandidateModal(details?.candidate, {
        ...resourceData,
        ...details?.candidate.resource,
      }),
    [details?.candidate, resourceData],
  );

  const supplier = useMemo(
    () => new SupplierModal(supplierData),
    [supplierData],
  );

  const notes = useNotes('candidate_notes', 'candidate', id);
  const tabView = useMemo(
    () => ({
      isRating: !!candidate.rating_total && !!candidate.rating_count,
      isSkills: !!candidate.skills?.length || !!candidate.skill_tags?.length,
      isEducation: !!candidate.educations.length,
      isCourses: !!candidate.courses.length,
      isCertifications: !!candidate.certifications.length,
      isLanguages: !!candidate.languages.length,
      isNotes: notes && notes?.isNotes,
      isHistory: !!candidate?.resource?.employment_history?.length,
    }),
    [candidate, notes],
  );

  const primaryTabs = getPrimaryTabs(tabView);

  if (isLoading || isLoadingResource) {
    return <AppLoader />;
  }

  return (
    <ResourceProvider>
      <ResourcePageContainer
        tabs={primaryTabs}
        resource={candidate}
        jobTitle={details?.requisition?.job_title}
        isNotes={tabView.isNotes}
        handleShowNotes={notes?.handleShowNotes}
        supplier={isSupplier ? candidate?.supplier : supplierData}
        isCandidate>
        <MainContent
          candidate={candidate}
          requisition={details?.requisition}
          supplier={supplier}
          tabView={tabView}
          isShowNotes={notes?.isShowNotes}
          notesTotal={notes?.total}
          notesResponse={notes?.response}
        />
      </ResourcePageContainer>
    </ResourceProvider>
  );
};

export default ViewCandidate;
