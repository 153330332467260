import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {useAccountType} from 'app/hooks/useAccountType';
import {useRole} from 'app/hooks/useRole';
import React from 'react';

import MainContentClient from './MainContentClient';
import MainContentResource from './MainContentResource';
import MainContentSupplier from './MainContentSupplier';

const Dashboard = () => {
  const {isSupplier, isClient} = useAccountType();
  const {isResource} = useRole();

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          category='Dashboard'
          title={`${
            isResource ? 'Resource' : isSupplier ? 'Supplier' : 'Client'
          } Dashboard`}
        />
      }>
      {isResource && <MainContentResource />}
      {isSupplier && <MainContentSupplier />}
      {isClient && <MainContentClient />}
    </AppPageContainer>
  );
};

export default Dashboard;
