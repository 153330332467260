import ClientHelper from 'app/utils/ClientHelper';
import RequisitionHelper from 'app/utils/RequisitionHelper';

export default {
  job_title: {
    label: 'Job Title',
    filter: (value) => {
      return {
        query: {
          job_title: value,
        },
        label: value,
      };
    },
  },
  type: {
    label: 'Job Type',
    options: RequisitionHelper.workTypes(),
  },
  openings: {
    label: '# of Openings',
    options: RequisitionHelper.filterNumOfOpenings(),
    hasQuery: true,
  },
  skill_tags: {
    label: 'Skills',
    filter: (value) => {
      const labels = value.map((item) => item.label);
      const tagIds = value.map((item) => item.value);
      return {
        query: {
          skill_tags: tagIds,
        },
        label: labels,
      };
    },
  },
  job_country: {
    label: 'Job Location',
    options: RequisitionHelper.countries(),
  },
  start_date: {
    label: 'Job Start Date',
    filter(value) {
      return {
        query: {
          'start_date[gt]': value.unix(),
        },
        format: value.format('MM/DD/YYYY'),
      };
    },
  },
  job_status: {
    label: 'Job Status',
    options: RequisitionHelper.jobStatus(),
  },
  status: {
    label: 'Requisition Status',
    options: RequisitionHelper.requisitionStatus(),
  },
  job_duration: {
    label: 'Job Duration',
    options: RequisitionHelper.filterJobDuration(),
    hasQuery: true,
  },
  billing_rate_max: {
    label: 'Job Max Billing Rate',
    options: RequisitionHelper.filterMaxBillingRate(),
    hasQuery: true,
  },
  created_at: {
    label: 'Requisition Age',
    options: RequisitionHelper.filterJobRequisitionAge(),
    hasQuery: true,
  },
  priority: {
    label: 'Requisition Priority',
    options: RequisitionHelper.jobPriorities(),
  },
  client_status: {
    label: 'Client Status',
    options: ClientHelper.getEngagementStatus(),
  },
  client_country: {
    label: 'Client Based In',
    options: ClientHelper.getCountries(),
  },
  client_active_suppliers: {
    label: '# of Active Suppliers',
    options: ClientHelper.getActiveSuppliers('client_active_suppliers'),
    hasQuery: true,
  },
  client_engaged_resources: {
    label: '# of Deployed Resources',
    options: ClientHelper.getDeployedResouces('client_engaged_resources'),
    hasQuery: true,
  },
  client_relationship_status: {
    label: 'Client Relationship Status',
    options: ClientHelper.getRelationshipStatus(),
  },
  client_rating_total: {
    label: 'Client Performance Rating',
    options: ClientHelper.getPerformanceRating('client_rating_total'),
    hasQuery: true,
  },
  client_open_requisitions: {
    label: '# of Open Requisitions',
    options: ClientHelper.getOpeningRequisitions('client_open_requisitions'),
    hasQuery: true,
  },
  client_company_size: {
    label: 'Company Size',
    options: ClientHelper.getEmployeeSize(),
  },
};
