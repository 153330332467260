import './../styles/content.less';

import React from 'react';
import {useRoutes} from 'react-router-dom';

import Cookie from './Sections/Cookie';
import CustomerAgreement from './Sections/CustomerAgreement';
import Global from './Sections/GlobalData';
import Main from './Sections/Main';
import PrivacyCenter from './Sections/PrivacyCenter';
import PrivacyPolicy from './Sections/PrivacyPolicy';
import Standard from './Sections/StandardEngagementTerms';
import SupplierAgreement from './Sections/SupplierAgreement';
import TermsOfUse from './Sections/TermsOfUse';

const TermsContent = () => {
  return useRoutes([
    {path: '/', element: <Main />},
    {
      path: '/customer-agreement',
      element: <CustomerAgreement />,
    },
    {path: '/supplier-agreement', element: <SupplierAgreement />},
    {path: '/standard-engagement-terms', element: <Standard />},
    {path: '/terms-of-use', element: <TermsOfUse />},
    {path: '/privacy-policy', element: <PrivacyPolicy />},
    {path: '/global-data-processing-agreement', element: <Global />},
    {path: '/privacy-center', element: <PrivacyCenter />},
    {path: '/cookie-policy', element: <Cookie />},
  ]);
};

export default TermsContent;
