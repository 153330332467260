import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {
  useGetRequisition,
  useGetRequisitions,
} from 'app/services/requisitionService';
import {setSubmissionRequisition} from 'app/store/features/submission/inquirySlice';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

const SelectRequisition = () => {
  const dispatch = useDispatch();
  const {requisitionId} = useParams();

  const {currentStep, nextStep} = useStep();
  const {requisition, isEditMode} = useSelector(({submission}) => submission);

  const {data, isLoading} = useGetRequisitions(
    {
      bookmarked: true,
      submission_creation: true,
      status: 'open',
    },
    {
      enabled: !isEditMode && !requisitionId,
    },
  );

  const {data: req, isLoading: reqIsLoading} = useGetRequisition(
    requisitionId,
    {},
  );

  useEffect(() => {
    if (req) {
      const {id, job_title} = req;
      dispatch(
        setSubmissionRequisition({
          id,
          job_title,
        }),
      );
    }
  }, [dispatch, req]);

  const [requisitions] = getItemsData('requisitions', data);

  const options = useMemo(
    () => (req ? [req] : requisitions),
    [req, requisitions],
  );

  const onFormSubmit = (formData) => {
    const item = options.find((item) => item.id === formData?.requisition);
    const {id, job_title} = item;

    dispatch(
      setSubmissionRequisition({
        id,
        job_title,
      }),
    );

    if (nextStep) nextStep();
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (requisition.id) {
      form.setFieldValue('requisition', requisition.id);
    }
  }, [form, requisition.id]);

  if (isLoading || reqIsLoading) {
    return <AppLoader />;
  }

  return currentStep > 1 ? (
    <AppBorderBox
      className='d-inline-flex px-6 mt-3'
      style={{backgroundColor: '#FFF'}}>
      <Typography.Title level={4} className={'mb-0'}>
        {requisition.job_title}
      </Typography.Title>
    </AppBorderBox>
  ) : (
    <AppCard
      title='Select Requisition'
      headStyle={{border: 0}}
      bodyStyle={{paddingTop: 0}}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFormSubmit}
        autoComplete='off'
        initialValues={{requisition: requisition.id}}>
        <Form.Item
          name='requisition'
          label='Requisition'
          rules={[
            {
              required: true,
              message: getRequiredMessage('Requisition'),
            },
          ]}>
          <AppSelect
            showSearch
            loading={isLoading || reqIsLoading}
            placeholder='Select Requisition'
            style={{minWidth: '330px', width: 'auto', maxWidth: '100%'}}>
            {options.map((req) => (
              <Select.Option key={req.id} value={req.id}>
                {`${req.job_title} ${req.hid}`}
              </Select.Option>
            ))}
          </AppSelect>
        </Form.Item>

        <Button type='primary' htmlType='submit'>
          Continue
        </Button>
      </Form>
    </AppCard>
  );
};

export default React.memo(SelectRequisition);
