import AppGridContainer from '@wieldy/components/AppGridContainer';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {Menu, Popover} from 'antd';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';

const AppLabelSkill = ({tooltip, item}) => {
  const ref = useRef();
  const [distance, setDistance] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = (e) => {
    const parentRect = e.target.parentElement?.getBoundingClientRect();
    const childRect = e.target?.getBoundingClientRect();
    const distanceRight = parentRect?.right - childRect?.right;

    setDistance(distanceRight);
    setVisible(distanceRight < 5);
  };
  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleVisibleChange = () => {
    if (distance < 5) {
      setVisible(true);
    }
  };

  return (
    <Popover
      visible={visible}
      content={<Menu items={tooltip} />}
      placement='top'
      onVisibleChange={handleVisibleChange}>
      <AppGridContainer
        className={'p-0 m-0'}
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <AppTag
          shape={'square'}
          className={
            'border-none mr-1 mb-1 ant-tag-hidden-square pointer-none'
          }>
          {item}
        </AppTag>
      </AppGridContainer>
    </Popover>
  );
};

AppLabelSkill.propTypes = {
  item: PropTypes.string,
  tooltip: PropTypes.object,
};
export default AppLabelSkill;
