import {useMutation} from 'app/hooks/useReactQuery';
import axios from 'axios';

/** Upload file to aws signed url */
export const useUploadImageToAws = () => {
  return useMutation(async ({uploadUrl, file}) => {
    const response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return response;
  });
};
