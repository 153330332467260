import {notification} from 'antd';
import {resetRequestHeader, setRequestHeader} from 'app/config/axios';
import {COUNTRIES} from 'app/constants';
import {
  useCreateSupplier,
  useSentSupplierInvite,
} from 'app/services/supplierService';
import Steps from 'app/shared/Steps';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {getStepItems} from './steps';

const MainContent = () => {
  const navigate = useNavigate();
  const [supplierAccount, setClientAccount] = useState(null);
  const mutationCreateSupplier = useCreateSupplier();
  const mutationInvite = useSentSupplierInvite();

  const setState = useCallback((payload) => {
    setClientAccount((prevState) => {
      return {
        ...prevState,
        ...payload,
      };
    });
  }, []);

  const handleOnFinish = () => {
    const {
      account_manager,
      account_owner,
      billing_address,
      primary_address,
      company_info,
      marketplace_fees,
    } = supplierAccount;
    mutationCreateSupplier.mutate(
      {
        name: company_info.name,
        service_focus: company_info.service_focus,
        company_size: company_info.company_size,
        status: company_info.status,
        website: company_info.website,
        linkedin: company_info.linkedin,
        diversity_status: company_info.diversity_status,
        industry_focuses: company_info.industry_focuses,
        about: company_info.about,
        account_manager: account_manager.id,
        country: primary_address.country,
        us_state:
          COUNTRIES[primary_address.country] === COUNTRIES.united_states
            ? primary_address.state
            : null,
        city: primary_address.city,
        primary_address,
        billing_address: {
          ...billing_address,
          phone_number: billing_address?.phone_number?.slice(1),
        },
        ...(marketplace_fees.length && {msp_fee: marketplace_fees}),
      },
      {
        onSuccess: async (res) => {
          await setRequestHeader('sourcer-supplier', res.data.id);

          await mutationInvite.mutate(
            {
              account_owner,
              phone_number: account_owner.phone_number
                ? account_owner.phone_number.slice(1)
                : null,
            },
            {
              onError: (error) => {
                notification.error({message: error.message});
              },
              onSettled: () => {
                navigate('/accounts/suppliers');
                resetRequestHeader('sourcer-supplier');
              },
            },
          );
        },
      },
    );
  };

  return (
    <Steps
      items={getStepItems(handleOnFinish)}
      current={0}
      bindProps={{state: supplierAccount, setState}}
    />
  );
};

export default MainContent;
