import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import InformationForm from 'app/shared/requisitions/RequisitionSteps/CompensationCriteria/InformationForm';
import InformationView from 'app/shared/requisitions/RequisitionSteps/CompensationCriteria/InformationView';
import PropTypes from 'prop-types';
import React from 'react';

const CompensationCriteria = ({requisitionModal}) => {
  const {currentStep, nextStep, setStepData, stepData} = useStep();
  return (
    <React.Fragment>
      <Typography.Paragraph>
        Specify compensation, candidate criteria and required background checks
      </Typography.Paragraph>

      {currentStep > 4 && stepData ? (
        <InformationView requisitionModal={requisitionModal} />
      ) : (
        currentStep === 4 && (
          <InformationForm
            initialValues={{
              billing_rate_max: stepData?.billing_rate_max,
              billing_rate_period: stepData?.billing_rate_period ?? undefined,
              expenses_eligible: stepData?.expenses_eligible,
              flsa_eligible: stepData?.flsa_eligible,
              candidate_country: stepData?.candidate_country ?? [],
              candidate_visa_status: stepData?.candidate_visa_status ?? [],
              supplier_country: stepData?.supplier_country ?? [],
              candidate_min_experience: stepData?.candidate_min_experience,
              candidate_min_education: stepData?.candidate_min_education,
              candidate_required_checks:
                stepData?.candidate_required_checks ?? [],
            }}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

CompensationCriteria.propTypes = {
  requisitionModal: PropTypes.object,
};
export default React.memo(CompensationCriteria);
