import {useWindowSize} from '@wieldy/hooks/useWindowSize';
import {Button, notification} from 'antd';
import {useDownloadPolicyFiles} from 'app/services/downloadPolicyFiles';
import React, {useCallback} from 'react';

import {PolicyFilesName, PolicyFilesPath} from '../../constants/policyNames';
import {downloadBlob} from '../utils/downloadBlob';

const CustomerAgreement = () => {
  const {width} = useWindowSize();
  const data = useDownloadPolicyFiles();

  const handleDownload = useCallback(() => {
    data.mutate(
      {
        name: PolicyFilesPath[PolicyFilesName.CUSTOMER_AGREEMENT],
      },
      {
        onSuccess: (response) => {
          downloadBlob(
            response.file,
            PolicyFilesPath[PolicyFilesName.CUSTOMER_AGREEMENT],
          );
        },
        onError: (error) => {
          notification.error({message: error?.message});
        },
      },
    );
  }, [data]);

  return (
    <div id='customer-agreement'>
      <article className='post'>
        <div className='post-wrapper'>
          <div className='post-header'>
            <div className='d-flex flex-row flex-between'>
              <h2 className='post-title text-center mb-0'>
                Customer Agreement
              </h2>
              {width > 768 && (
                <Button
                  type='primary'
                  shape='round'
                  className='post-header-button items-center d-none lg:flex'
                  loading={data.isLoading}
                  onClick={handleDownload}>
                  Download
                </Button>
              )}
            </div>
            <ul className='post-meta'>
              <li>Effective April 22, 2024</li>
            </ul>
            {width < 769 && (
              <Button
                type='primary'
                shape='round'
                className='post-header-button items-center d-none lg:flex w-full'
                loading={data.isLoading}
                onClick={handleDownload}>
                Download
              </Button>
            )}
          </div>
          <div className='post-content'>
            <p>
              This Sourcer Customer Agreement (“Agreement”) is made by and
              between Sourcer and Customer (each of them individually a “Party”
              and collectively the “Parties”) as of the date Customer’s
              representative entered into the Agreement (“Agreement Effective
              Date”).
            </p>

            <h4>1. Definitions.</h4>

            <p>
              The following terms shall have the meaning indicated below (all
              terms defined in this section or in any other provision of this
              Agreement (as defined below) in the singular are to have the same
              meanings when used in the plural and vice versa):
            </p>

            <p>
              “Agreement” means this Sourcer Customer Agreement, together with
              any exhibits, schedules or attachments hereto, as such may be
              amended, modified or supplemented from time to time, pursuant to
              which Sourcer agrees to provide vendor management services to
              Customer.
            </p>

            <p>
              “Billing Period” shall be weekly and shall extend from Monday
              through Sunday unless otherwise defined in the exhibits, schedules
              or attachments.
            </p>

            <p>
              “Change of Control” means: (a) any sale, conveyance or other
              disposition of all or substantially all of the assets of Customer
              or, (b) any merger or consolidation of Customer with or into any
              other corporation, limited liability company or other entity
              resulting in the equity holders of the Customer as they exist
              immediately before the transaction no longer owning fifty percent
              (50%) or more of the voting power of the surviving corporation,
              limited liability company or other entity.
            </p>

            <p>
              “Confidential Information” means any information, in whatever form
              or media, of a Disclosing Party that is provided or disclosed to
              the Receiving Party including orally, in writing, or in other
              form, and whether identified as confidential or proprietary at the
              time of disclosure or which should be understood to be
              confidential by the nature of the information or the circumstances
              of the disclosure. Confidential Information will include the
              following categories of information without limitation: products,
              network configurations, network architecture, financial and
              operational information, and other matters relating to the
              operation of the Parties’ business, including information relating
              to actual or potential customers and customer lists, customer
              usage or requirements, business and customer usage forecasts and
              projections, accounting, finance or tax information, pricing
              information, business plans, strategies, technology,
              documentation, methodologies, general know-how, technical
              information, software, equipment, details of deliverables or
              services rendered under this Agreement or applicable Engagement,
              and any amendments thereto, any information exchanged between the
              Parties pursuant to a nondisclosure agreement, and all information
              and materials relating to third party vendors, systems
              integrators, or consultants.
            </p>

            <p>
              Confidential Information will not include any information which:
            </p>
            <ul
              style={{
                listStyle: 'none',
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                i. the Disclosing Party confirms in writing is free of such
                restrictions;
              </li>
              <li>
                ii. at the time of disclosure was already known or becomes
                lawfully known (independently of disclosure by the Disclosing
                Party) to the Receiving Party from a source other than one
                having an obligation of confidentiality to the Disclosing Party;
              </li>
              <li>
                iii. becomes publicly known or otherwise ceases to be secret or
                confidential, except through a breach of this Agreement or
                applicable Engagement; or
              </li>
              <li>
                iv. is independently developed by the Receiving Party without
                use of or reference to the Confidential Information.
              </li>
            </ul>

            <p>
              “Customer” means any entity or organization, including but not
              limited to its divisions, departments, institutions, subsidiaries,
              affiliates, partners, suppliers, customers, successors, and
              assigns, that enters into a Customer Agreement with Sourcer or
              otherwise agrees to be governed by its terms. Additional Customers
              may be added by Sourcer as per the Marketplace.
            </p>

            <p>
              “Disclosing Party” means the Party disclosing Confidential
              Information.
            </p>

            <p>
              “Employee” means any person determined to be in the service of
              another under any contract of hire, express or implied, oral or
              written, where the employer has the power or right to control and
              direct the employee in the material details of how the work is to
              be performed.
            </p>

            <p>
              “Engagement” means that engagement record created following the
              successful onboarding of any Staff following an accepted and
              approved Submission that includes but is not limited to candidate
              information, scope or statements of work, pricing, and duration.
              Customer and Sourcer, as Customer’s agent, reserve the right to
              amend, supplement, suspend, or terminate an Engagement at any
              time. In no event shall Sourcer be construed as responsible,
              directly or indirectly, for the payment or performance of the
              Supplier’s obligations under the Engagement, or any Invoice, or
              for the matters contained in any Requisition or Submission.
              Engagements are limited to a single Staff person at a time.
            </p>

            <p>
              “Expense Reports” are online forms submitted by Suppliers via the
              Marketplace detailing pre-approved expenses on a per Staff person
              basis.
            </p>

            <p>
              “Intellectual Property” means: (i) patents, patent disclosures and
              inventions (whether patentable or not), ideas, discoveries,
              developments, and improvements; (ii) trademarks, service marks,
              trade dress, trade names, logos, corporate names and domain names,
              together with all of the goodwill associated therewith; (iii)
              authorship rights, copyrights and copyrightable works (including
              computer programs), mask works, and rights in data and databases;
              (iv) trade secrets, general know-how and other confidential
              information; and (v) all other intellectual property rights, in
              each case whether registered or unregistered and including all
              applications for, and renewals or extensions of, such rights, and
              all similar or equivalent rights or forms of protection provided
              by applicable law in any jurisdiction throughout the world.
            </p>

            <p>
              “Intellectual Property Rights” means any and all rights comprising
              or relating to Intellectual Property.
            </p>

            <p>
              “Invoice” means a time and expense invoice, prepared by Sourcer
              based on Customer approved Timecards and Expense Reports. Sourcer
              thereafter submits the completed Invoice to Customer, through the
              Marketplace for Customer review, approval, and payment.
            </p>

            <p>
              “Invoicing Date” means the date that the Invoice is issued to
              Customer and is generally scheduled for, unless otherwise
              specified in Sourcer’s written policies and procedures, or posted
              online within the Sourcer Marketplace, the second Monday following
              the end of the last Billing Period that falls within the Invoicing
              Period as defined below.
            </p>

            <p>
              “Invoicing Period” shall be either: (i) weekly (Monday through
              Sunday), or (ii) monthly (from the first day of the month through
              the last day of the month), unless otherwise specified in
              Sourcer’s written policies and procedures, or posted online within
              the Sourcer Marketplace.
            </p>

            <p>
              “Losses” means claims, demands, liabilities, losses, costs or
              expenses, including reasonable attorneys&apos; fees of any nature
              whatsoever (whether for personal injury, property damage or
              otherwise) arising under any theory, including breach of contract
              or warranty, tort (including negligence), statutory liability, or
              strict liability.
            </p>

            <p>
              “Marketplace” means the online marketplace, including a vendor
              management solution for managing the end-to-end process (Procure
              to Pay) of staffing and other services enabled through the use of
              web-based systems.
            </p>

            <p>
              “Means of Direct Contact” means any information that would allow
              another person to contact Customer directly, including, without
              limitation, phone number, email address, physical address, a link
              to a contact form or form requesting contact information, any link
              to an applicant management system or means to submit a proposal or
              application outside of the Marketplace, or any information that
              would enable a Supplier to contact Customer on social media or
              other website or platform or application that includes a
              communications tool (including without limitations Skype, Slack,
              WeChat, or Facebook). Information is a Means of Direct Contact if
              it would enable a Supplier to identify any of the information
              above through other sources, such as going to a website that
              included an email address or identifying Customer through social
              media.
            </p>

            <p>
              “Personally Identifiable Information” means any and all
              information provided by Customer or collected by Suppliers for
              Customer (a) that identifies, or when used in combination with
              other information provided by Customer or processed by Suppliers
              on behalf of the Customer identifies, an individual, or (b) from
              which identification or contact information of an individual
              person can be derived. Personally Identifiable Information can be
              in any media or format, including computerized or electronic
              records as well as paper-based files. Personally Identifiable
              Information includes, without limitation, a person’s name, home
              and work contact information, email address, social security
              number, social insurance number, or other government-issued
              identifier, and all information about the individual’s
              relationship with Customer (such as compensation and benefits
              information, education, training and professional qualification
              data, job information, health and disability data, products and
              services purchased data, products and services usage data, etc.)
              Additionally, to the extent any other information (such as, but
              not necessarily limited to, biometric information) is associated
              or combined with Personally Identifiable Information, then such
              information also will be considered Personally Identifiable
              Information. This Section is in addition to any other agreements
              that may exist between the parties regarding the confidentiality,
              security, handling, storage or use of Personally Identifiable
              Information.
            </p>

            <p>
              “Receiving Party” means the Party receiving Confidential
              Information.
            </p>

            <p>
              “Requisition” means that document or request distributed by
              Sourcer on behalf of Customer through the Marketplace that
              contains specifications and requirements pertaining to a need for
              services to be procured from a qualified Supplier.
            </p>

            <p>
              “Sourcer” means Sourcer, Inc. a Delaware corporation, its
              successors and assigns.
            </p>

            <p>
              “Staff/Key Persons” means employees and, as the context requires,
              permitted subcontractors of Suppliers who are engaged to perform
              services for Customer.
            </p>

            <p>
              “Submission” means the form sent by Suppliers to Customer via the
              Marketplace in response to either: (i) an inquiry by the Customer
              directly to Supplier(s), or (ii) a Requisition provided by the
              Customer through the Marketplace; and includes (without
              limitation) pricing, candidate skills, qualifications, and
              credentials.
            </p>

            <p>
              “Supplier” (means, at any time, a vendor under contract with
              Sourcer who is eligible (as determined by Sourcer in its sole
              discretion) to receive Requisitions, submit Submissions and/or
              provide services to a Customer, who has duly completed all
              requirements, and is not disqualified from providing services to
              Customer. Supplier is not a party to this Agreement.{' '}
            </p>

            <p>
              “Supplier Agreement” means the Agreement by and between Supplier
              and Sourcer pursuant to which Sourcer agrees to provide access to
              the Marketplace and administrative support services to Supplier.
            </p>

            <p>
              “Timecards” are online forms submitted by Suppliers via the
              Marketplace detailing the hours worked on a per Staff person
              basis.
            </p>

            <h4>2. Nature of Agreement.</h4>

            <p>
              This Agreement details the terms pursuant to which Customer
              accesses and uses the Marketplace and those services that
              Marketplace provides to Customer.
            </p>

            <h4>3. Obligations of Customer.</h4>

            <p>
              Customer acknowledges and agrees that a violation of this Section
              3 is a material breach of this Agreement and access to the
              Marketplace may be permanently suspended for such violations.
            </p>

            <p>
              a) Marketplace Usage: In order for Customer to be able to submit
              Requisitions and respond to Submissions Customer agrees that
              during the term of this Agreement, it shall maintain eligibility
              (based on policies established by Sourcer in its sole discretion),
              maintain accurate information in the Marketplace (including: (i)
              Customer entity name, billing contact, and primary address; and
              (ii) maintaining the accuracy of any Requisitions), and adhere to
              the other policies and procedures established by Sourcer from time
              to time, as such requirements and policies may be amended from
              time to time. Sourcer reserves the absolute right and discretion
              to establish and, as it sees fit, amend or supplement eligibility
              requirements, qualifications, policies and procedures applicable
              to Customer. Such an amendment or supplement shall be effective
              following a minimum ten (10) business days’ notice period: (a) for
              an existing Engagement, if the amendment or supplement is a
              system-wide change adopted by Sourcer for all customers, and (b)
              in all other cases, for new Engagements or renewals of existing
              Engagements at the time the new Engagement or renewal is executed.
              Customer may exercise its option for termination for convenience
              if it disapproves of the amendment or supplement.
            </p>

            <p>
              b) Requisition / Engagement Accuracy: Customer may submit one or
              more Requisitions through the Marketplace. Customer shall use its
              best efforts to verify that information provided in the
              Requisition is complete, accurate and up-to-date. Pricing
              information contained within each Engagement is considered binding
              and shall not be changed through the end of the Engagement
              duration or Engagement extensions or renewals, except by mutual
              agreement of Customer and Supplier and updated within the
              Marketplace by Sourcer.
            </p>

            <p>
              c) Review: Customer shall review Timecards and Expense Reports for
              services rendered during each Billing Period within seven (7) days
              of receipt. Sourcer shall authorize Customer to access and use the
              Marketplace for the purpose of reviewing such Timecards and
              Expense Reports. If Customer fails to accept or reject (such
              rejection shall not be unreasonable) Timecards within thirty (30)
              days after the end of the Billing Period that the work is
              performed or Expense Reports within thirty (30) days after the end
              of the Billing Period that the expense is incurred, then Customer
              shall be deemed to have accepted such Timecards or Expense
              Reports.
            </p>

            <p>
              d) Worker Classification: Customer is solely responsible for and
              assumes all liability for engaging with Suppliers as independent
              contractors and engaging them accordingly. Customer agrees that it
              will abide by all international, federal, state, or local wage and
              hour laws, including properly classifying Staff provided to
              Customer and providing supervision as necessary or required.
              Customer also agrees that it will comply with all applicable
              immigration, labor and employment laws and regulations, including
              laws prohibiting discrimination in employment.
            </p>

            <p>
              e) Workplace Policy Enforcement: Customer is solely responsible
              for enforcing Customer workplace policies at Customer‘s site(s).
            </p>

            <p>
              f) Communication: Customer agrees to use the communication
              services available on the Marketplace to communicate with Sourcer
              and Suppliers prior to entering into an Engagement. Customer
              agrees that prior to entering into an Engagement, Customer (a)
              will use Marketplace as the sole manner to communicate with
              Suppliers; (b) will not provide Customer’s Means of Direct Contact
              to any Suppliers or another person that Customer identified or
              were identified by through the Marketplace; (c) will not use Means
              of Direct Contact of another person to attempt to or to
              communicate with, solicit, contact, or find the contact
              information of a Supplier outside of Sourcer; (d) will not ask
              for, provide, or attempt to identify through public means the
              contact information of a Supplier; and (e) will not include any
              Means of Direct Contact or means by which Customer contact
              information could be discovered in any profile, proposal, job
              posting, invitation, or pre-Engagement communication through the
              Marketplace’s communications services (including in each case in
              any attached file), except as otherwise provided on the
              Marketplace.
            </p>

            <h4>4. Fees and Invoicing.</h4>

            <p>
              Customer acknowledges and agrees that a violation of this Section
              4 is a material breach of this Agreement and access to the
              Marketplace may be permanently suspended for such violations.
            </p>

            <p>a) The Marketplace operates exclusively in USD.</p>

            <p>
              b) The fees and expenses for services rendered by Suppliers to
              Customer shall be those agreed to by Suppliers and Customer in
              each Engagement. Customer acknowledges that (i) Sourcer shall
              deliver, on the Invoicing Date, an aggregate invoice for the fees
              and expenses evidenced by Timecards and Expense Reports approved
              by such Customer during the applicable Invoicing Period for all
              services provided by Supplier(s), (ii) Customer shall pay to
              Sourcer, and not to Supplier(s) the full amount of each invoice,
              and (iii) Sourcer shall remit payment to Supplier after receipt of
              full payment from Customer.
            </p>

            <p>
              c) Customer will not unreasonably withhold payment of undisputed
              amounts under any Invoice submitted by Sourcer and will promptly
              notify Sourcer and Supplier if Customer disputes item(s) contained
              in an Invoice. Payment of an Invoice shall not preclude Customer
              from making claims or objections to an Invoice up to ten (10)
              business days from the date of invoice, if there are errors or
              issues with the work required to be performed.
            </p>

            <p>
              d) Customer agrees to communicate through the Marketplace, and
              make payments of Invoices owed for work performed by Suppliers,
              exclusively through the Marketplace, during and up to two (2)
              years after the date that Customer stops participating in the
              Marketplace (the “Non-Circumvention Period”). Violations of this
              Section constitute a material breach of this Agreement, may result
              in permanent suspension of Customer’s Account, and Sourcer retains
              the right to pursue a claim for damages.
            </p>

            <p>
              During the Non-Circumvention Period, Customer agrees to use (and
              shall cause its employees, agents, or representatives to use) the
              Marketplace as the exclusive method to make all payments for
              services directly or indirectly with Supplier and not to
              circumvent the Payment Methods offered on the Marketplace. By way
              of example only, Customer agrees that during the Non-Circumvention
              period Customer will not:
            </p>

            <ul
              style={{
                listStylePosition: 'outside',
                marginLeft: '3em',
              }}>
              <li>
                Offer or solicit or accept any offer or solicitation from
                parties identified through the Marketplace to contract, hire, or
                make all payments for services in any manner other than through
                the Marketplace.
              </li>
              <li>
                Refer anyone that Customer identified on the Marketplace to a
                third party who is not an authorized user of the Marketplace for
                purposes of making or receiving payments other than through the
                Marketplace.
              </li>
            </ul>

            <p>
              e) Customer is solely responsible for the creation, storage, and
              backup of Customer’s business records. Customer agrees that
              Sourcer has no obligation to store, maintain, or provide Customer
              a copy of any content or information that Customer provides,
              except to the extent required by applicable law.
            </p>

            <h4>5. Taxes.</h4>

            <p>
              Sourcer does not collect taxes it is not required to collect, but
              Sourcer may be required by applicable law to collect certain taxes
              or levies, including income tax or VAT (which some jurisdictions
              refer to as GST or local sales taxes). These collection
              requirements and rates may change based on changes to the law in
              Customer’s area.
            </p>

            <h4>6. Representations and Warranties.</h4>

            <p>
              a) Each party hereto represents and warrants to the other party
              that (i) it is either a natural person or a validly existing
              entity duly authorized and licensed to do business in each
              jurisdiction where its services under this Agreement are to be
              performed, (ii) the execution and delivery of this Agreement, and
              the performance of its obligations hereunder, by such party have
              been duly authorized and that the Agreement is a valid and legal
              agreement binding on such party and enforceable in accordance with
              its terms, and (iii) the execution and delivery of this Agreement,
              and the performance of its obligations hereunder, by such party
              does not, and will not, conflict with or result in breach of any
              agreement, indenture or other instrument binding on such party.
            </p>

            <p>
              b) If any Staff placed by Supplier(s) with a Customer are
              determined by Customer or Sourcer to be unacceptable for any
              reason within a period of five (5) business days following the
              date that such Staff first provides services in connection with
              any Engagement, then Customer or Sourcer may terminate the
              services of such Staff and not be obligated to pay any amount to
              Supplier(s) for any services provided by such Staff. Upon such
              termination of Supplier’s Staff, Sourcer, in its sole discretion,
              may provide such Suppliers with a reasonable time period
              (determined by the circumstances existing at the time of the
              termination) to replace the terminated Staff; provided Customer
              does not prohibit or restrict such replacement.
            </p>

            <h4>7. Confidentiality.</h4>

            <h5>a. Treatment of Confidential Information</h5>

            <p>
              The Receiving Party agrees that it (and each of its affiliates,
              and their respective directors, officers, employees, agents, or
              contractors) will not use the Confidential Information of the
              Disclosing Party in any way except for the purpose of performing
              its obligations under the Engagement. The Receiving Party (and
              each of its affiliates, and their respective directors, officers,
              employees, agents, or contractors) will take reasonable
              precautions to protect the confidentiality of the Confidential
              Information and will use the same degree of care to protect the
              Disclosing Party’s Confidential Information that it uses to
              protect its own Confidential Information (but in no event less
              than reasonable care).
            </p>

            <p>
              Notwithstanding the foregoing, the Receiving Party may disclose
              Disclosing Party’s Confidential Information to its affiliates,
              employees, agents, or contractors, who have a need to know such
              information in order to fulfill its obligations under the
              Engagement, provided that all such parties are bound by
              non-disclosure agreements containing confidentiality obligations
              substantially equivalent to those contained herein. The Receiving
              Party will be liable for any act or omission of its affiliates,
              employees, agents, or contractors, as if such were a party to this
              Agreement or applicable Engagement. The Receiving Party (and each
              of its affiliates, employees, agents, or contractors) will not
              reverse engineer, disassemble or decompile any prototypes,
              software or other tangible objects which embody the other Party’s
              Confidential Information.
            </p>

            <p>
              Further, either Party may disclose Confidential Information to the
              extent required by law, by subpoena, judicial or administrative
              order, or otherwise, provided that, if legally permitted to do so,
              the Receiving Party gives the Disclosing Party prior written
              notice of such required disclosure to allow the Disclosing Party a
              reasonable opportunity to object to and to take legal action to
              prevent such disclosure, and will furthermore provide full
              cooperation and assistance in seeking to obtain such protection,
              all at the Disclosing Party’s cost and expense.
            </p>

            <h5>b. Term of Confidentiality Obligations</h5>

            <p>
              The Disclosing Party agrees that the confidentiality obligations
              of this section will apply during the Term and for three (3) years
              following the termination or expiration date of this Agreement or
              applicable Engagement. The terms and conditions, but not the
              existence, of this Agreement or applicable Engagement, in whole or
              part, constitute Confidential Information; provided, that this
              Agreement or applicable Engagement may be disclosed as necessary
              in connection with a financing or change of control transaction.
              Notwithstanding the foregoing, any trade secrets disclosed under
              this Agreement or applicable Engagement will be held in confidence
              by the Receiving Party for: (i) as long as the Confidential
              Information remains Disclosing Party’s trade secret under
              applicable law; or (ii) until Confidential Information falls under
              one of the exceptions to the confidentiality obligations (see
              Definition).
            </p>

            <h5>c. Return or Destruction of Records</h5>

            <p>
              Except as otherwise provided in this Agreement or applicable
              Engagement, promptly upon a request by the Disclosing Party, the
              Receiving Party will destroy or return to the Disclosing Party all
              Confidential Information of the Disclosing Party, and all
              documents or media containing any such Confidential Information
              and any copies or extracts thereof, except that the Receiving
              Party may retain one (1) copy solely for record-keeping purposes.
            </p>

            <h5>d. Unauthorized Disclosure</h5>

            <p>
              If the Receiving Party becomes aware of any unauthorized use or
              disclosure of the Confidential Information of the Disclosing
              Party, the Receiving Party will promptly notify the Disclosing
              Party of all facts known to it concerning such unauthorized use or
              disclosure. Each Party acknowledges and agrees that due to the
              unique nature of the Disclosing Party’s Confidential Information,
              there may be no adequate remedy at law for any breach of its
              confidentiality obligations hereunder, which breach may result in
              irreparable harm to the Disclosing Party, and therefore, that upon
              any such breach or any threat thereof, the Disclosing Party will
              be entitled to seek appropriate equitable relief in addition to
              whatever remedies it might have at law.
            </p>

            <h4>8. Termination of Services.</h4>

            <p>
              a) If Customer or Sourcer, in its sole discretion, determines that
              any individual Supplier Staff are unacceptable for any reason,
              Customer or Sourcer may request that such individual Supplier
              Staff’s services to Customer be terminated and Sourcer will
              terminate such individual Supplier Staff services immediately with
              no further obligation on the part of Customer other than payment
              of any invoice for such individual Supplier Staff’s services
              rendered prior to such termination.
            </p>

            <p>
              b) With regard to any Engagement, a Customer or Sourcer may cancel
              such Engagement for any reason (or no reason) immediately upon
              providing ten (10) business days’ prior written notice to
              Supplier, provided that the Customer shall pay any amounts due and
              payable for services received prior to such termination.
            </p>

            <h4>9. Discipline, Suspension and Termination.</h4>

            <p>
              a) In the event that Sourcer has reason to believe that Customer
              at any time (i) is not in compliance with eligibility
              requirements, qualifications, or other policies or procedures
              established by Sourcer, (ii) has provided false or misleading
              information or documentation to Sourcer or Suppliers, (iii) acts,
              or knowingly causes or permits its Staff to act, in any manner,
              that is in violation of any law or ordinance, or, in violation of
              any rule or policy of Sourcer, or (iv) fails to meet the terms of
              each Engagement, Sourcer may do any of the following in any
              combination: (I) suspend Customer’s access to and use of the
              Marketplace, (II) audit Customer’s compliance status; (III) impose
              sanctions that Customers must meet in order to maintain
              eligibility; (IV) suspend or terminate this Agreement; and (V)
              amend, suspend, reduce, terminate or transfer Engagements then in
              effect.
            </p>

            <p>
              b) Sourcer or Customer may terminate this Agreement, at its
              convenience, at any time on ten (10) business days’ prior written
              notice.
            </p>

            <h4>10. Indemnity.</h4>

            <p>
              a) Customer hereby agrees to indemnify, protect and hold harmless
              Sourcer, and its respective employees, proprietors, partners,
              representatives, shareholders, directors, servants, attorneys,
              predecessors, successors and assigns (hereinafter collectively
              referred to as {`"Indemnitees"`}), from any and all Losses that
              result from: (i) Customer or Customer’s agents’ use of the
              Marketplace, including any payment obligations or default incurred
              through use of the Marketplace; (ii) any work product or content
              related to Customer or Customer’s agents’ use of the Marketplace;
              (iii) any Engagement entered into by Customer or Customer’s
              agents, including, but not limited to, the classification of a
              Supplier and/or Supplier’s Staff as an independent contractor or
              any employment-related claims; (iv) Customer or Customer’s agents’
              failure to comply with the terms of this Agreement; (v) Customer
              or Customer’s agents’ failure to comply with applicable law; (vi)
              negligence, willful misconduct, or fraud by Customer or Customer’s
              agents; (vii) Customer or Customer’s agents’ violation of any
              third-party right, including without limitation any right of
              privacy, publicity rights or Intellectual Property Rights.
            </p>

            <p>
              b) This indemnification applies notwithstanding the fact that
              third parties or any of the Indemnitees may be partially
              responsible for the situation giving rise to the claim. However,
              if any of the Indemnitees or third parties are partially
              responsible for the events giving rise to the claim, Customer’s
              indemnification obligations shall apply only to the extent that
              Customer contributed to the Losses.
            </p>

            <p>
              c) Customer shall, at its expense, be entitled to and shall have
              the duty to participate in the defense of any suit against the
              Indemnitees. No settlement or compromise of any claim, loss or
              damage entered into by Indemnitees shall be binding upon Customer
              unless approved in writing by Customer. No settlement or
              compromise of any claim, loss or damage entered into by Customer
              shall be binding upon Indemnitees unless approved in writing by
              Indemnitees.
            </p>

            <p>
              d) Any Indemnitee shall promptly tender the defense of any claim
              for which Customer shall indemnify Indemnitees promptly, provided
              that the Indemnitee may be represented by counsel of its own
              choosing, at its own expense.
            </p>

            <h4>11. Disclaimer of Warranties</h4>

            <p>
              CUSTOMER’S USE OF THE MARKETPLACE, ITS CONTENT, AND ANY SERVICES
              OBTAINED THROUGH THE MARKETPLACE IS AT CUSTOMER’S RISK. THE
              MARKETPLACE, ITS CONTENT AND ANY SERVICES OBTAINED THROUGH THE
              MARKETPLACE ARE PROVIDED ON AN {`"AS IS"`} AND {`"AS AVAILABLE"`}{' '}
              BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED. NEITHER SOURCER NOR ANY PERSON ASSOCIATED WITH SOURCER
              MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
              AVAILABILITY OF THE MARKETPLACE.
            </p>

            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <h4>12. Limitation of Liability.</h4>

            <p>
              Sourcer is not liable, and Customer agrees not to hold Sourcer
              responsible, for any Losses arising out of or in connection with
              the Agreement, including, but not limited to: (i) Customer’s use
              of or Customer’s inability to use the Marketplace; (ii) delays or
              disruptions in the Marketplace; (iii) viruses or other malicious
              software obtained by accessing, or linking to, the Marketplace;
              (iv) glitches, bugs, errors, or inaccuracies of any kind in the
              Marketplace; (v) damage to Customer’s software or hardware devices
              from the use of the Marketplace; (vi) the content, actions, or
              inactions of third parties’ use of the Marketplace; (vii) a
              suspension or other action taken with respect to Customer Account;
              (viii) Customer reliance on the quality, accuracy, or reliability
              of Requisitions, Profiles, ratings, recommendations, and feedback
              (including their content, order, and display), Composite
              Information, or metrics found on, used on, or made available
              through the Marketplace; and (ix) Customer need to modify
              practices, content, or behavior or Customer loss of or inability
              to do business, as a result of changes to the Terms of Service.
            </p>

            <p>
              Additionally, in no event will Sourcer, our affiliates, our
              licensors, or our third-party service providers be liable for any
              special, consequential, incidental, punitive, exemplary, or
              indirect costs or damages, including, but not limited to,
              litigation costs, installation and removal costs, or loss of data,
              production, profit, or business opportunities. The liability of
              Sourcer, our affiliates, our licensors, and our third-party
              service providers to any User for any claim arising out of or in
              connection with this Agreement or the other Terms of Service will
              not exceed the lesser of: (a) Two Thousand Five Hundred Dollars
              ($2,500) or (b) any fees retained by Sourcer with respect to
              Engagements on which Customer was involved as during the six-month
              period preceding the date of the claim.
            </p>

            <p>
              These limitations will apply to any liability, arising from any
              cause of action whatsoever arising out of or in connection with
              this Agreement whether in contract, tort (including negligence),
              strict liability, or otherwise, even if Sourcer has been advised
              of the possibility of such costs or damages and even if the
              limited remedies provided herein fail of their essential purpose.
              Some jurisdictions do not allow for all of the foregoing
              exclusions and limitations, so to that extent, some or all of
              these limitations and exclusions may not apply to Customer.
            </p>

            <h4>13. Notices.</h4>

            <p>
              All notices, requests, demands, and other communications required
              or permitted under this Agreement shall be in writing and deemed
              delivered when hand-delivered to the receiving person, or when
              mailed, certified mail, return receipt requested, in first class
              U.S. mail, to the addresses provided, or when electronically
              transmitted to the Internet address specified, with hard copy
              mailed within three (3) days thereafter in the manner set forth
              above.
            </p>

            <h4>14. Assignment.</h4>

            <p>
              Customer may not transfer, sublicense or otherwise assign this
              Agreement or any of its rights or obligations hereunder without
              the prior written consent of Sourcer, and any attempted transfer,
              sublicense or assignment by a party without such consent shall be
              null and void. This Agreement shall be binding on and beneficial
              to the parties to this engagement and their respective heirs,
              executors, administrators, legal representatives, successors and
              assigns.
            </p>

            <h4>15. Independent Contractors; Third-Party Beneficiaries.</h4>

            <p>
              In making and performing this Agreement, the parties hereto have
              acted and shall continue to act at all times as independent
              contractors. Except as otherwise provided herein, nothing
              contained in this Agreement or any actions or activity by Sourcer,
              either within Marketplace or in the performance of its obligations
              or services to Customers, shall be construed or implied to create
              the relationship of agent, representative, partner or of employer
              and employees between the parties hereto or between Suppliers or
              any of their staff and Sourcer or Customer. At no time shall
              either party hereto make commitments for or in the name of the
              other party.
            </p>

            <h4>16. Resolution Procedures.</h4>

            <p>
              If a dispute arises from or relates to this Agreement or the
              breach thereof, and if the dispute cannot be settled through
              direct discussions, the parties agree to endeavor first to settle
              the dispute by mediation administered by the American Arbitration
              Association under its Commercial Mediation Procedures before
              resorting to arbitration. The parties further agree that any
              unresolved controversy or claim arising out of or relating to this
              contract, or breach thereof, shall be settled by arbitration
              administered by the American Arbitration Association in accordance
              with its Commercial Arbitration Rules and judgment on the award
              rendered by the arbitrator(s) may be entered in any court having
              jurisdiction thereof.
            </p>

            <p>
              Claims shall be heard by a single arbitrator, unless the claim
              amount exceeds One Hundred Thousand Dollars ($100,000), in which
              case the dispute shall be heard by a panel of three (3)
              arbitrators. Within fifteen (15) days after the commencement of
              arbitration, each party shall select one (1) person to act as
              arbitrator and the two (2) selected shall select a third
              arbitrator within ten days of their appointment. If the
              arbitrators selected by the parties are unable or fail to agree
              upon the third arbitrator, the third arbitrator shall be selected
              by the American Arbitration Association. The place of arbitration
              shall be Dover, Delaware, USA. The arbitration shall be governed
              by the laws of the State of Delaware. If the dispute is less than
              Twenty Five Thousand Dollars ($25,000) there shall be no discovery
              other than the exchange of documents. If the dispute is over
              Twenty Five Thousand Dollars ($25,000), discovery shall consist of
              no more than two (2) depositions of up to four (4) hours each in
              duration.
            </p>

            <p>
              In making determinations regarding the scope of exchange of
              electronic information, the arbitrator(s) and the parties agree to
              be guided by The Sedona Principles, Third Edition: Best Practices,
              Recommendations & Principles for Addressing Electronic Document
              Production. The arbitration will be based on the submission of
              documents and there shall be no in-person or oral hearing. The
              award shall be made within four (4) months of the filing of the
              notice of intention to arbitrate (demand), and the arbitrator(s)
              shall agree to comply with this schedule before accepting
              appointment. However, this time limit may be extended by the
              arbitrator for good cause shown, or by mutual agreement of the
              parties. The arbitrators will have no authority to award punitive
              or other damages not measured by the prevailing party&apos;s
              actual damages, except as may be required by statute. The
              arbitrator(s) shall not award consequential damages in any
              arbitration initiated under this section. Pursuant to the
              Commercial Arbitration Rules, the arbitrators will have the
              authority to allocate the costs of the arbitration process among
              the parties, but will only have the authority to allocate
              attorneys fees if a particular law permits them to do so. The
              award of the arbitrators shall be accompanied by a reasoned
              opinion. Except as may be required by law, neither a party nor an
              arbitrator may disclose the existence, content, or results of any
              arbitration hereunder without the prior written consent of both
              parties. The parties agree that failure or refusal of a party to
              pay its required share of the deposits for arbitrator compensation
              or administrative charges shall constitute a waiver by that party
              to present evidence or cross-examine witnesses. In such event, the
              other party shall be required to present evidence and legal
              argument as the arbitrator(s) may require for the making of an
              award. Such waiver shall not allow for a default judgment against
              the non-paying party in the absence of evidence presented as
              provided for above.
            </p>

            <h4>17. Change of Control.</h4>

            <p>
              In the event that Customer is subject to a Change of Control, it
              shall give notice of the same to Sourcer within five (5) business
              days following such event.
            </p>

            <h4>18. Governing Law.</h4>

            <p>
              This Agreement and any dispute or claim including non-contractual
              disputes or claims arising out of or in connection with it or its
              subject matter or formation shall be governed by and construed in
              accordance with the laws of the State of Delaware, USA, as they
              presently exist or may hereafter be amended, without regard to
              principles of conflict of laws. The United Nations Convention on
              Contracts for the International Sale of Goods shall not apply to
              this Agreement.
            </p>

            <h4>19. Survival.</h4>

            <p>
              The following provisions of this Agreement shall survive any
              termination of this Agreement in accordance with Section 9 above:
              any payment provision relating to any amounts due but not paid at
              the time of such termination, expiration, or extension of services
              as provided in Section 9(b), Sections 6 through 7, and 10 through
              20.
            </p>

            <h4>20. General Provisions.</h4>

            <p>
              This Agreement constitutes the entire agreement between the
              parties relating to the subject matter hereof and supersedes all
              prior agreements, written or oral, between the parties relating to
              the subject matter contained in this Agreement. Section titles
              used in this Agreement are for reference purposes only and shall
              not be deemed to be a part of this Agreement. Time is of the
              essence of this Agreement. Each Engagement shall be considered a
              separate legal obligation existing solely between Supplier(s) and
              Customer and is not to be construed as integrated with this
              Agreement. Subject to (a) the rights reserved by Customer and/or
              Sourcer with regard to Requisitions and Engagements, and (b) the
              right reserved by Sourcer to amend or supplement eligibility
              requirements, qualifications, policies and procedures applicable
              to Customer and Staff with respect to existing or future
              Engagements, Requisitions and Submissions, and except as otherwise
              provided in this Agreement, this Agreement may not be amended
              except by written agreement signed by authorized representatives
              of Sourcer and Customer, and no oral waiver, amendment or
              modification shall be effective under any circumstances. This
              Agreement may be entered into in one or more counterparts, each of
              which shall be an original and all of which shall constitute one
              and the same Agreement. Electronic signatures and documents will
              be accepted as original signatures and valid documents. If any
              term, covenant, or condition of this Agreement shall for any
              reason be held unenforceable by a court of competent jurisdiction,
              the rest of this Agreement shall remain in full force and shall in
              no way be affected or impaired.
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default CustomerAgreement;
