import {Badge} from 'antd';
import React from 'react';
import {MdOutlineMail} from 'react-icons/md';

const Messages = () => {
  return (
    <Badge dot={false} status='warning'>
      <MdOutlineMail className={'cursor-pointer text-xxl'} />
    </Badge>
  );
};

export default React.memo(Messages);
