import './style.less';
import 'react-quill/dist/quill.snow.css';

import {Typography} from 'antd';
import {convertToHtml} from 'app/utils/ConvertToHtml';
import PropTypes from 'prop-types';
import React, {useCallback, useRef, useState} from 'react';
import ReactQuill from 'react-quill';

import {handleGetLength} from '../../../utils/helpers/GetStringLength';

function Editor({
  isUseAntdform,
  isCompanyProfile,
  value,
  initValue,
  readOnly,
  placeholder,
  getEditorValue,
  onChange,
  showCounter,
  maxLength,
}) {
  const [editorValue, setEditorValue] = useState(convertToHtml(initValue));
  const editorRef = useRef(null);
  const [counter, setCounter] = useState(
    editorValue ? handleGetLength(editorValue) : 0,
  );

  const defaultTools = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'bullet'}],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const handleChangeAntd = useCallback(
    (content, delta, source, editor) => {
      const count = editor.getText().replace('\\n', '').length;
      if (source === 'user') {
        editorRef.current.focus();
      }

      if (onChange) onChange(content);

      setCounter(count - 1);
      setEditorValue(content);
    },
    [onChange],
  );
  const handleKeyPress = (event) => {
    if (counter >= maxLength) {
      event.preventDefault();
    }
  };

  const onBlur = useCallback(() => {
    if (getEditorValue) {
      getEditorValue(editorValue);
    }
  }, [editorValue, getEditorValue]);

  const handleChange = useCallback(
    (editor) => {
      editorRef.current?.focus();
      setEditorValue(editor);

      if (getEditorValue) {
        getEditorValue(editor);
      }
    },
    [getEditorValue],
  );

  if (isUseAntdform) {
    return (
      <div
        onBlur={onBlur}
        className={!readOnly ? 'wrapper-editor' : 'wrapper-read'}>
        <ReactQuill
          modules={defaultTools}
          readOnly={readOnly}
          ref={editorRef}
          defaultValue={editorValue}
          onChange={handleChangeAntd}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
        />
        {showCounter && maxLength && (
          <Typography.Paragraph
            style={{
              float: 'right',
              color: '#a7a7a7',
            }}>{`${counter} / ${maxLength}`}</Typography.Paragraph>
        )}
      </div>
    );
  }

  if (isCompanyProfile) {
    return (
      <div className={!readOnly ? 'wrapper-editor' : 'wrapper-read'}>
        <ReactQuill
          modules={defaultTools}
          readOnly={readOnly}
          ref={editorRef}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
        />
        {showCounter && maxLength && (
          <Typography.Paragraph
            style={{
              float: 'right',
              color: '#a7a7a7',
            }}>{`${counter} / ${maxLength}`}</Typography.Paragraph>
        )}
      </div>
    );
  }

  return (
    <div className={!readOnly ? 'wrapper-editor' : 'wrapper-read'}>
      {readOnly ? (
        <div dangerouslySetInnerHTML={{__html: editorValue}} />
      ) : (
        <>
          <ReactQuill
            modules={defaultTools}
            readOnly={readOnly}
            ref={editorRef}
            defaultValue={editorValue}
            onChange={handleChange}
            placeholder={placeholder}
            onKeyPress={handleKeyPress}
          />
          {showCounter && `${counter}/${maxLength}`}
        </>
      )}
    </div>
  );
}

Editor.propTypes = {
  isUseAntdform: PropTypes.bool,
  placeholder: PropTypes.string,
  getEditorValue: PropTypes.func,
  readOnly: PropTypes.bool,
  initValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isCompanyProfile: PropTypes.bool,
  showCounter: PropTypes.bool,
  maxLength: PropTypes.number,
};

Editor.defaultProps = {
  placeholder: 'Description',
  readOnly: false,
  id: 'editor',
};
export default Editor;
