import AppLoader from '@wieldy/components/AppLoader';
import {QUERY_CONSTANTS} from 'app/constants/index.js';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import ResourceModal from 'app/modals/ResourceModal';
import SupplierModal from 'app/modals/SupplierModal';
import {useGetResourceDetail} from 'app/services/resourceService';
import {ResourcePageContainer, ResourceProvider} from 'app/shared/resource';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {getPrimaryTabs} from './constants';
import MainContent from './MainContent';

const ViewResource = () => {
  const {id} = useParams();
  const {isClient} = useAccountType();

  const {isLoading, data} = useGetResourceDetail(
    id,
    {
      include: [
        'engagements',
        'skill_tags',
        'bookmarked',
        'completed_engagements',
      ],
      expand: ['user', isClient ? '' : 'supplier'],
    },
    QUERY_CONSTANTS.SUPPLIER.VIEW,
    isClient,
    true,
  );
  const {resourceData, supplierData} = data || {
    resourceData: null,
    supplierData: null,
  };

  const resourceModal = useMemo(
    () => new ResourceModal(resourceData),
    [resourceData],
  );
  const supplier = useMemo(
    () => new SupplierModal(supplierData),
    [supplierData],
  );

  const notes = useNotes('resource_notes', 'resource', id);

  const tabView = useMemo(
    () => ({
      isRating: !!resourceModal.rating_total && !!resourceModal.rating_count,
      isSkills: !!resourceModal.skills.length,
      isEducation: !!resourceModal.educations.length,
      isCourses: !!resourceModal.courses.length,
      isCertifications: !!resourceModal.certifications.length,
      isLanguages: !!resourceModal.languages.length,
      isHistory: !!resourceModal.employment_history.length,
      isSupplier: supplierData,
      isNotes: notes && notes?.isNotes,
    }),
    [resourceModal, notes, supplierData],
  );

  const primaryTabs = getPrimaryTabs(tabView);
  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <ResourceProvider>
      <ResourcePageContainer
        tabs={primaryTabs}
        supplier={isClient ? supplierData : resourceData?.supplier}
        resource={resourceModal}
        isNotes={tabView.isNotes}
        hideNotesButton={!notes}
        handleShowNotes={notes?.handleShowNotes}>
        <MainContent
          supplier={supplier}
          resource={resourceModal}
          tabView={tabView}
          isShowNotes={notes?.isShowNotes}
          notesTotal={notes?.total}
          notesResponse={notes?.response}
        />
      </ResourcePageContainer>
    </ResourceProvider>
  );
};

export default ViewResource;
